export type DisclosureQuestionType = {
    id: number;
    questionText: string;
    radioButton: string;
    formField: string;
    dispatchType: string;
    showQuestion(state: any): boolean;
    showTextField(state: any): boolean;
    submittable(state: any): boolean;
};

export const DisclosureQuestions = [
    {
        id: 1,
        questionText:
            "Have you ever been arrested for a criminal offense including felonies, misdemeanors and violations?",
        radioButton: "arrestHistory",
        formField: "natureOfOffenseArrest",
        dispatchType: "SET_ARREST",
        showQuestion: (state: any) => {
            return true;
        },
        showTextField: (state: any) => {
            return state.arrestHistory;
        },
        submittable: (state: any) => {
            if (!state.arrestHistory) {
                return true;
            } else {
                if (
                    state.natureOfOffenseArrest === undefined ||
                    !state.natureOfOffenseArrest ||
                    state.natureOfOffenseArrest === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    {
        id: 2,
        questionText:
            "Are there any criminal charges currently pending against you for any felony, misdemeanor, or violation (excluding traffic offenses)?",
        radioButton: "pendingCharge",
        formField: "natureOfOffensePendingCharge",
        dispatchType: "SET_PENDING_CHARGE",
        showQuestion: (state: any) => {
            return true;
        },
        showTextField: (state: any) => {
            return state.pendingCharge;
        },
        submittable: (state: any) => {
            if (!state.pendingCharge) {
                return true;
            } else {
                if (
                    state.natureOfOffensePendingCharge === undefined ||
                    !state.natureOfOffensePendingCharge ||
                    state.natureOfOffensePendingCharge === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    {
        id: 3,
        questionText:
            "Have you ever been convicted of, or have you pleaded guilty to, a criminal offense including felonies, misdemeanors and violations?",
        radioButton: "convictionHistory",
        formField: "natureOfOffenseConviction",
        dispatchType: "SET_CONVICTION",
        showQuestion: (state: any) => {
            return true;
        },
        showTextField: (state: any) => {
            return state.convictionHistory;
        },
        submittable: (state: any) => {
            if (!state.convictionHistory) {
                return true;
            } else {
                if (
                    state.natureOfOffenseConviction === undefined ||
                    !state.natureOfOffenseConviction ||
                    state.natureOfOffenseConviction === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    {
        id: 4,
        questionText:
            "Have you received or been granted any award which we should recognize and consider? ",
        radioButton: "japanAward",
        formField: "natureOfJapanAward",
        dispatchType: "SET_JAPAN_AWARD",
        showQuestion: (state: any) => {
            return state.isJapan;
        },
        showTextField: (state: any) => {
            return state.japanAward;
        },
        submittable: (state: any) => {
            if (!state.japanAward) {
                return true;
            } else {
                if (
                    state.natureOfJapanAward === undefined ||
                    !state.natureOfJapanAward ||
                    state.natureOfJapanAward === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    {
        id: 5,
        questionText:
            "Have you ever been subject to criminal punishment, except for a traffic accident case?",
        radioButton: "japanCriminal",
        formField: "natureOfJapanCriminal",
        dispatchType: "SET_JAPAN_CRIMINAL",
        showQuestion: (state: any) => {
            return state.isJapan;
        },
        showTextField: (state: any) => {
            return state.japanCriminal;
        },
        submittable: (state: any) => {
            if (!state.japanCriminal) {
                return true;
            } else {
                if (
                    state.natureOfJapanCriminal === undefined ||
                    !state.natureOfJapanCriminal ||
                    state.natureOfJapanCriminal === ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
];
