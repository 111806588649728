import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../../OutlinedButtonStyled";
import { moveStatus } from "../../../../api/RosterApi";
import { useAlert } from "../../../../contexts/AlertsContext";
import {
    getJurisdictionAndStatus,
    getPersonnelDisclosureReview,
} from "../../../../api/PersonnelApi";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import DisclosureTab from "./DisclosureTab";
import ReviewTab from "./ReviewTab";

const BodyContent = styled.div`
    text-align: left;
    padding-top: 0.7rem;
    height: 445px;
    overflow-y: scroll;
    color: var(--slate);
    font-size: 12px;
    background-color: var(--cloud);
    & .kvwlRj {
        padding: 0rem !important;
    }
`;

const NameContent = styled.div`
    font-size: 16px;
    color: var(--slate);
`;

const TitleContent = styled.div`
    font-size: 12px;
    color: var(--slate);
`;
const OrganizationContent = styled.div`
    font-size: 12px;
    color: var(--slate);
`;

const StyledIcon = styled.i.attrs(() => ({
    className: "fa fa-check-circle ml-1",
}))`
    color: green;
    font-size: 14px;
`;

type ReviewDisclosureModalBodyProps = {
    personnel: any;
    disclosureComment: string;
    setDisclosureComment: Function;
    reviewComment: string;
    setReviewComment: Function;
    disclosureReviews: any[];
    setDisclosureReviews: Function;
    currentJurisdiction: any;
    currentJurisdictionIndex: number;
    setCurrentJurisdictionIndex: any;
    setCurrentJurisdiction: Function;
    targetJurisdiction: string;
    callback: Function;
    active: string;
};

const ReviewDisclosureModalBody: React.FC<ReviewDisclosureModalBodyProps> = ({
    personnel,
    disclosureComment,
    setDisclosureComment,
    reviewComment,
    setReviewComment,
    setDisclosureReviews,
    currentJurisdiction,
    currentJurisdictionIndex,
    setCurrentJurisdictionIndex,
    setCurrentJurisdiction,
    targetJurisdiction,
    disclosureReviews,
    active,
}) => {
    const DISCLOSURE_TAB = "disclosure";
    const REVIEW_TAB = "reviewConsent";
    const { dispatch } = useGlobalModal();
    const [disclosureCount, setDisclosureCount] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const [activeTab, setActiveTab] = useState(DISCLOSURE_TAB);
    const [jurisdictionInfo, setJurisdictionInfo] = useState(null);
    const showAlert = useAlert();
    const readOnly = active && active.toUpperCase() === "INACTIVE" ? true : false;

    useEffect(() => {
        if (personnel) {
            getJurisdictionAndStatus(personnel.id.toString()).then(response => {
                setJurisdictionInfo(response);
            });
        }
    }, [personnel]);

    useEffect(() => {
        getPersonnelDisclosureReview(personnel.id).then(data => {
            //If property "active" is truthy, this modal is invoked from Profile Activity feed.
            //We will only show the target Jurisdiction.
            if (active) {
                data = data.filter((item: any) => item.jurisdiction === targetJurisdiction);
            } else {
                //Move targetJurisdiction to the top of array so it gets processed first.
                data.forEach((item: any, i: number) => {
                    if (item.jurisdiction === targetJurisdiction) {
                        data.splice(i, 1);
                        data.unshift(item);
                    }
                });
            }

            //data = data.filter((item: any) => item.disclosurePending || item.reviewPending);
            setDisclosureReviews(data);
            if (data.length > 0) {
                setCurrentJurisdiction(data[0]);
                setCurrentJurisdictionIndex(0);
            }
        });
    }, [personnel]);

    useEffect(() => {
        dispatch({
            type: "updateModalProperties",
            headerText: "Jurisdiction: " + currentJurisdiction.jurisdictionText,
        });

        let disclosureCount = 0;
        disclosureCount = currentJurisdiction.disclosures?.filter(
            (disclosure: { answer: any }) => disclosure.answer,
        ).length;

        setDisclosureCount(disclosureCount);

        setDisclosureComment(currentJurisdiction.disclosureComment);

        let reviewCount = 0;
        if (currentJurisdiction.review?.previousNames) {
            reviewCount++;
        }
        if (currentJurisdiction.review?.countriesLived) {
            reviewCount++;
        }
        setReviewCount(reviewCount);
        setReviewComment(currentJurisdiction.reviewComment);

        let targetTab = DISCLOSURE_TAB;

        if (currentJurisdiction.disclosurePending) {
            if (currentJurisdiction.disclosureAccepted && currentJurisdiction.reviewPending) {
                targetTab = REVIEW_TAB;
            } else {
                targetTab = DISCLOSURE_TAB;
            }
            setActiveTab(targetTab);
        } else if (currentJurisdiction.reviewPending) {
            if (currentJurisdiction.reviewAccepted && currentJurisdiction.disclosurePending) {
                targetTab = DISCLOSURE_TAB;
            } else {
                targetTab = REVIEW_TAB;
            }
            setActiveTab(targetTab);
        } else {
            // Do nothing, don't switch Tab
        }
    }, [currentJurisdiction]);

    const acceptDisclosure = () => {
        const statusChangeDTO = {
            country: currentJurisdiction.jurisdiction,
            eventName: "Submit Consent Disclosures",
            comment: disclosureComment,
        };
        moveStatus(personnel.id, statusChangeDTO)
            .then(() => {
                const updatedCurrentJurisdiction = {
                    ...currentJurisdiction,
                    disclosurePending: false,
                    disclosureComment: disclosureComment,
                    disclosureAccepted: true,
                };
                setCurrentJurisdiction(updatedCurrentJurisdiction);

                disclosureReviews[currentJurisdictionIndex] = updatedCurrentJurisdiction;

                // Deep clone disclosureReviews
                const newDisclosureReviews = JSON.parse(JSON.stringify(disclosureReviews));
                setDisclosureReviews(newDisclosureReviews);
            })
            .catch(error => {
                console.log(error);
                showAlert("Accepting disclosure failed", "danger");
            });
    };

    const acceptReviewContent = (personnelNames: any, aliasNameList: any) => {
        personnelNames["aliasList"] = aliasNameList;
        const statusChangeDTO = {
            country: currentJurisdiction.jurisdiction,
            eventName: "Submit Consent Reviews",
            comment: reviewComment,
            additionalInfo: JSON.stringify(personnelNames),
        };
        moveStatus(personnel.id, statusChangeDTO)
            .then(() => {
                console.log("submit review consent succeeded.");
                const updatedCurrentJurisdiction = {
                    ...currentJurisdiction,
                    reviewPending: false,
                    reviewComment: reviewComment,
                    reviewAccepted: true,
                };
                setCurrentJurisdiction(updatedCurrentJurisdiction);

                disclosureReviews[currentJurisdictionIndex] = updatedCurrentJurisdiction;

                // Deep clone disclosureReviews
                const newDisclosureReviews = JSON.parse(JSON.stringify(disclosureReviews));
                setDisclosureReviews(newDisclosureReviews);
            })
            .catch(error => {
                console.log(error);
                showAlert("Accepting consent failed", "danger");
            });
    };

    const handleTabSelect = (key: any) => {
        setActiveTab(key);
    };
    return (
        <BodyContent>
            <Container>
                <Row>
                    <Col md={7}>
                        <NameContent>
                            {personnel.lastName}, {personnel.firstName}
                        </NameContent>
                        <TitleContent>{personnel.title}</TitleContent>
                        <OrganizationContent>
                            {personnel.affiliation.orgAbbreviation} - {personnel.affiliation.name}
                        </OrganizationContent>
                    </Col>
                    <Col md={5}>
                        <Row>
                            <Col md={5}>Living</Col>
                            <Col md={7}>{jurisdictionInfo?.living}</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Working</Col>
                            <Col md={7}>{jurisdictionInfo?.working}</Col>
                        </Row>
                        <Row>
                            <Col md={5}>Headquartered</Col>
                            <Col md={7}>{jurisdictionInfo?.headquartered}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col md={12}>
                        <Tabs
                            id="disclosureReviewTabs"
                            activeKey={activeTab}
                            onSelect={handleTabSelect}
                        >
                            {currentJurisdiction.disclosures?.length > 0 && (
                                <Tab
                                    eventKey={DISCLOSURE_TAB}
                                    title={
                                        <span>
                                            {disclosureCount} Disclosure
                                            {!currentJurisdiction.disclosurePending ? (
                                                <StyledIcon />
                                            ) : null}
                                        </span>
                                    }
                                >
                                    <DisclosureTab
                                        currentJurisdiction={currentJurisdiction}
                                        disclosureComment={disclosureComment}
                                        setDisclosureComment={setDisclosureComment}
                                        acceptDisclosure={acceptDisclosure}
                                        readOnly={readOnly}
                                    />
                                </Tab>
                            )}
                            {currentJurisdiction.review && (
                                <Tab
                                    eventKey={REVIEW_TAB}
                                    title={
                                        <span>
                                            {reviewCount} Review Consent
                                            {!currentJurisdiction.reviewPending ? (
                                                <StyledIcon />
                                            ) : null}
                                        </span>
                                    }
                                >
                                    <ReviewTab
                                        personnel={personnel}
                                        currentJurisdiction={currentJurisdiction}
                                        reviewComment={reviewComment}
                                        setReviewComment={setReviewComment}
                                        acceptReviewContent={acceptReviewContent}
                                        readOnly={readOnly}
                                    />
                                </Tab>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </BodyContent>
    );
};

const ReviewDisclosureModalFooter: React.FC<ReviewDisclosureModalBodyProps> = ({
    personnel,
    setDisclosureComment,
    setReviewComment,
    disclosureReviews,
    setDisclosureReviews,
    currentJurisdiction,
    setCurrentJurisdictionIndex,
    currentJurisdictionIndex,
    setCurrentJurisdiction,
    targetJurisdiction,
    callback,
    active,
}) => {
    const { closeModal, dispatch } = useGlobalModal();
    const showAlert = useAlert();
    const readOnly = active && active.toUpperCase() === "INACTIVE" ? true : false;
    const reviewedButNotSubmitted = personnel.roster.statuses.filter(
        (s: any) =>
            s.country === currentJurisdiction.jurisdiction &&
            s.active === "ACTIVE" &&
            s.status.status === "Reviewed But Not Submitted",
    );
    const isReviewedButNotSubmitted = reviewedButNotSubmitted.length > 0;

    const submitBGCCallbackHandler = (
        personnelId: number,
        jurisdiction: string,
        eventName: string,
        successMsg: string,
    ) => {
        const statusChangeDTO = {
            country: jurisdiction,
            eventName: eventName,
            comment: "",
        };
        moveStatus(personnelId, statusChangeDTO)
            .then(() => {
                showAlert(successMsg);
                if (callback && typeof callback === "function") {
                    callback();
                }
            })
            .catch(error => {
                console.log(error);
                showAlert("Failed to submit", "danger");
            });
    };

    const cancelCallbackHandler = () => {
        dispatch({
            type: "openReviewDisclosureModal",
            personnel: personnel,
            targetJurisdiction: targetJurisdiction,
            active: active,
        });
    };
    const submitBGC = (country: string) => {
        dispatch({
            type: "openSubmitToBgcModal",
            jurisdictionStatuses: personnel.roster?.statuses.filter((status: any) => {
                const jurisdiction = disclosureReviews.find(
                    (dr: any) => dr.jurisdiction === status.country,
                );
                return (
                    status.active === "ACTIVE" &&
                    jurisdiction &&
                    jurisdiction.disclosurePending === false &&
                    jurisdiction.reviewPending === false
                );
            }),
            personnelId: personnel.id,
            onSubmitToBgcCallback: submitBGCCallbackHandler,
            onCancelCallback: cancelCallbackHandler,
            selectedSideMenuOpts: ["Disclosures/Review Consent", "Reviewed But Not Submitted"],
        });
    };

    const displayNextJurisdictionButton = (disclosureReviews: any[], readOnly: boolean) => {
        if (readOnly) {
            return false;
        }
        const nextIndex = (currentJurisdictionIndex + 1) % disclosureReviews.length;

        return (
            disclosureReviews[nextIndex].disclosurePending ||
            disclosureReviews[nextIndex].reviewPending
        );
    };

    const nextJurisdiction = (disclosureReviews: any[]) => {
        disclosureReviews[currentJurisdictionIndex] = currentJurisdiction;
        const nextIndex = (currentJurisdictionIndex + 1) % disclosureReviews.length;
        setCurrentJurisdiction(disclosureReviews[nextIndex]);
        setCurrentJurisdictionIndex(nextIndex);

        // Deep clone disclosureReviews
        const newDisclosureReviews = JSON.parse(JSON.stringify(disclosureReviews));
        setDisclosureReviews(newDisclosureReviews);
    };

    return (
        <div className="d-flex">
            {(!readOnly || (readOnly && isReviewedButNotSubmitted)) && (
                <PrimaryButtonStyled
                    width={142}
                    height={26}
                    disabled={
                        currentJurisdiction.disclosurePending || currentJurisdiction.reviewPending
                    }
                    onClick={event => {
                        event.stopPropagation();
                        submitBGC(currentJurisdiction.jurisdiction);
                    }}
                >
                    Submit to BGC
                </PrimaryButtonStyled>
            )}

            <div className="pl-2">
                {disclosureReviews.length > 1 &&
                    displayNextJurisdictionButton(disclosureReviews, readOnly) && (
                        <OutlinedButtonStyled
                            width={142}
                            height={26}
                            disabled={disclosureReviews.length < 2}
                            onClick={event => {
                                event.stopPropagation();
                                nextJurisdiction(disclosureReviews);
                            }}
                        >
                            Next Jurisdiction
                        </OutlinedButtonStyled>
                    )}
            </div>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        setDisclosureComment("");
                        setReviewComment("");
                        closeModal();
                    }}
                >
                    Close
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ReviewDisclosureModalBody.displayName = "BodyContent";
ReviewDisclosureModalFooter.displayName = "FooterContent";

export { ReviewDisclosureModalBody, ReviewDisclosureModalFooter };
