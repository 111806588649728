import React from "react";
import { BestRadio } from "best-common-react";
import styled from "styled-components";
import { useQuestionnaire } from "../../../contexts/QuestionnaireContext";
import { QuestionDef } from "../QuestionDefs";
import { QuestionnaireTextDef } from "../QuestionDefs";

type DropDownQuestionProps = {
    questionDef: QuestionDef;
};

const RadioLabel = styled.label`
    margin-right: 30px;
    width: 24px;
    height: 21px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black);
`;

const RadioYesNoContent = styled.div`
    margin: 20px 0px 0px 0px;
    width: 350px;
`;

const RadioYesNoQuestion: React.FC<DropDownQuestionProps> = ({ questionDef }) => {
    const { questionnaire, dispatchQuestionnaire } = useQuestionnaire();

    return (
        questionDef.show(questionnaire) && (
            <RadioYesNoContent>
                <label className={questionDef.required ? "required" : ""}>
                    {questionnaire.language.value && questionnaire.language.value === "spanish"
                        ? questionDef.displayTextEs
                        : questionDef.displayTextEn}
                </label>
                <div>
                    <BestRadio
                        id={questionDef.id + "yes"}
                        onChange={() =>
                            dispatchQuestionnaire({
                                type: questionDef.type,
                                id: questionDef.id,
                                value: questionDef.id + "yes",
                            })
                        }
                        checked={questionnaire[questionDef.id] === questionDef.id + "yes"}
                    />
                    <RadioLabel htmlFor={questionDef.id + "yes"}>
                        {questionnaire.language.value && questionnaire.language.value === "spanish"
                            ? QuestionnaireTextDef.yes.es
                            : QuestionnaireTextDef.yes.en}
                    </RadioLabel>

                    <BestRadio
                        id={questionDef.id + "no"}
                        onChange={() =>
                            dispatchQuestionnaire({
                                type: questionDef.type,
                                id: questionDef.id,
                                value: questionDef.id + "no",
                            })
                        }
                        checked={questionnaire[questionDef.id] === questionDef.id + "no"}
                    />
                    <RadioLabel htmlFor={questionDef.id + "no"}>
                        {questionnaire.language.value && questionnaire.language.value === "spanish"
                            ? QuestionnaireTextDef.no.es
                            : QuestionnaireTextDef.no.en}
                    </RadioLabel>
                </div>
            </RadioYesNoContent>
        )
    );
};

export default RadioYesNoQuestion;
