import React, { Dispatch, SetStateAction, createContext, useState, useContext } from "react";

type AdminMenuOptionType = {
    id: number;
    amount: number;
    text: string;
};

type AdminSideMenuContext = {
    menuOptions: AdminMenuOptionType[];
    setMenuOptions: Dispatch<SetStateAction<AdminMenuOptionType[]>>;
    selectedMenuOptions: AdminMenuOptionType[];
    setSelectedMenuOptions: Dispatch<SetStateAction<AdminMenuOptionType[]>>;
    affiliationFilters: any[];
    setAffiliationFilters: Dispatch<SetStateAction<Array<any>>>;
    activeCount: number;
    setActiveCount: Dispatch<SetStateAction<number>>;
    inactiveCount: number;
    setInactiveCount: Dispatch<SetStateAction<number>>;
    refreshSideMenu: Function;
    refreshMenu: boolean;
    previousMenuOptions: AdminMenuOptionType[];
    setPreviousMenuOptions: Dispatch<SetStateAction<AdminMenuOptionType[]>>;
    previousAffiliationFilters: any[];
    setPreviousAffiliationFilters: Dispatch<SetStateAction<any[]>>;
    searchText: any[] | string;
    setSearchText: Dispatch<SetStateAction<[] | string>>;
    inactiveSelected: boolean;
    setInactiveSelected: Dispatch<SetStateAction<boolean>>;
    orgMenuOptions: AdminMenuOptionType[];
    setOrgMenuOptions: Dispatch<SetStateAction<AdminMenuOptionType[]>>;
};

const AdminSideMenuContext = createContext<AdminSideMenuContext>({
    menuOptions: null,
    setMenuOptions: null,
    selectedMenuOptions: null,
    setSelectedMenuOptions: null,
    affiliationFilters: null,
    setAffiliationFilters: null,
    activeCount: 0,
    setActiveCount: null,
    inactiveCount: 0,
    setInactiveCount: null,
    refreshSideMenu: null,
    refreshMenu: null,
    previousMenuOptions: null,
    setPreviousMenuOptions: null,
    previousAffiliationFilters: null,
    setPreviousAffiliationFilters: null,
    searchText: null,
    setSearchText: null,
    inactiveSelected: null,
    setInactiveSelected: null,
    orgMenuOptions: null,
    setOrgMenuOptions: null,
});

const activeOptions = [
    {
        id: 1,
        text: "Pending BOC",
        amount: 0,
    },
    {
        id: 2,
        text: "Consent Pending",
        amount: 0,
    },
    {
        id: 73,
        text: "Consent Expired",
        amount: 0,
    },
    {
        id: 3,
        text: "DT Consent Pending",
        amount: 0,
    },
    {
        id: 4,
        text: "DT Consent Complete",
        amount: 0,
    },
    {
        id: 5,
        text: "Disclosures/Review Consent",
        amount: 0,
    },
    {
        id: 6,
        text: "Reviewed But Not Submitted",
        amount: 0,
    },
    {
        id: 7,
        text: "In Progress",
        amount: 0,
    },
    {
        id: 8,
        text: "Findings",
        amount: 0,
    },
    {
        id: 9,
        text: "Errors",
        amount: 0,
    },
    {
        id: 10,
        text: "Exempt",
        amount: 0,
    },
    {
        id: 11,
        text: "International",
        amount: 0,
    },
    {
        id: 12,
        text: "Complete",
        amount: 0,
    },
    {
        id: 13,
        text: "Rollover",
        amount: 0,
    },
];

const activeOrgMenuOptions = [
    {
        id: 1,
        text: "Not Certified",
        amount: 0,
    },
    {
        id: 2,
        text: "Certified",
        amount: 0,
    },
];

const AdminSideMenuProvider: React.FC = ({ children }) => {
    const [menuOptions, setMenuOptions] = useState<AdminMenuOptionType[]>(activeOptions);
    const [selectedMenuOptions, setSelectedMenuOptions] = useState<AdminMenuOptionType[]>([
        activeOptions[0],
    ]);
    const [previousMenuOptions, setPreviousMenuOptions] = useState<AdminMenuOptionType[]>([]);
    const [activeCount, setActiveCount] = useState<number>(0);
    const [inactiveCount, setInactiveCount] = useState<number>(0);
    const [refreshMenu, setRefreshMenu] = useState<boolean>(false);
    const [affiliationFilters, setAffiliationFilters] = useState([]);
    const [previousAffiliationFilters, setPreviousAffiliationFilters] = useState<Array<any>>([]);
    const [searchText, setSearchText] = useState<Array<any> | string>([]);
    const [inactiveSelected, setInactiveSelected] = useState(false);

    const [orgMenuOptions, setOrgMenuOptions] = useState<AdminMenuOptionType[]>(
        activeOrgMenuOptions,
    );

    const refreshSideMenu = () => {
        setRefreshMenu(true);
        setSelectedMenuOptions([...selectedMenuOptions]);
        setRefreshMenu(false);
    };

    return (
        <AdminSideMenuContext.Provider
            value={{
                refreshMenu,
                menuOptions,
                setMenuOptions,
                selectedMenuOptions,
                setSelectedMenuOptions,
                affiliationFilters,
                setAffiliationFilters,
                activeCount,
                inactiveCount,
                setActiveCount,
                setInactiveCount,
                refreshSideMenu,
                previousMenuOptions,
                setPreviousMenuOptions,
                previousAffiliationFilters,
                setPreviousAffiliationFilters,
                searchText,
                setSearchText,
                inactiveSelected,
                setInactiveSelected,
                orgMenuOptions,
                setOrgMenuOptions,
            }}
        >
            {children}
        </AdminSideMenuContext.Provider>
    );
};

const useAdminSideMenu = (): AdminSideMenuContext => {
    const adminSideMenuContext = useContext<AdminSideMenuContext>(AdminSideMenuContext);
    if (adminSideMenuContext === undefined) {
        throw new Error(`useAdminSideMenu must be used within a AdminSideMenuProvider`);
    }
    return adminSideMenuContext;
};

export { AdminSideMenuContext, AdminSideMenuProvider, useAdminSideMenu, AdminMenuOptionType };
