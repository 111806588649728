import React, { useRef, useState, useEffect, useCallback } from "react";
import { Overlay } from "react-bootstrap";
import styled from "styled-components";
import { Icon } from "best-common-react";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { useAlert } from "../../../contexts/AlertsContext";
import { useAdminSideMenu } from "../../../contexts/AdminSideMenuContext";
import { acceptRoster, exemptRoster, moveStatus } from "../../../api/RosterApi";
import { AcceptPersonnelDtoType } from "../../../api/PersonnelApi";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getMenuOptionsByPersonnel } from "../../../api/PersonnelEventApi";
import { useProfileDisplay } from "../../../contexts/ProfileDisplayContext";

const ActionMenuContainer = styled.div.attrs(() => ({}))`
    text-align: center;
    width: 25px;

    & i {
        display: unset;
    }
`;

const ActionMenu = styled.div.attrs(() => ({}))`
    ${props => ({
        ...props.style,
    })};
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 5px;
    box-shadow: 0 4px 6px 0 rgba(119, 135, 154, 0.3);
    border: solid 1px var(--fog);
    background-color: var(--cloud);
    z-index: 10;
`;

const ActionButton = styled.div.attrs(() => ({
    className: "pl-1 pr-1",
}))`
    height: 24px;
    border-radius: 2px;
    border: solid 1px var(--blue);
    background-color: var(--white);
    font-family: Helvetica;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: var(--blue);
    padding-top: 2px;
    margin-bottom: 3px;
    cursor: pointer;
`;

const PrimaryActionButton = styled(ActionButton).attrs(() => ({}))`
    &&& {
        background-color: var(--blue);
        color: var(--white);
    }
`;

type HoverIconProps = {
    disabled?: boolean;
};

const HoverIcon = styled(Icon)<HoverIconProps>`
    &&& {
        width: 25px
        height: 16px;
        color: ${props => (!props.disabled ? "var(--blue)" : "var(--fog)")};
        &:hover {
            cursor: ${props => (!props.disabled ? "pointer" : "default")};
        }
    }
`;

interface MenuFormatterProps extends RouteComponentProps {
    row: any;
}

const MenuFormatter: React.FC<MenuFormatterProps> = ({ history, row }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const { dispatch } = useGlobalModal();
    const showAlert = useAlert();
    const { selectedMenuOptions } = useAdminSideMenu();
    const [actionMenuOptions, setActionMenuOptions] = useState([]);
    const { setDisplayProfile, setPersonnelId } = useProfileDisplay();

    const getMenuOptionsForPersonnel = useCallback(() => {
        if (!row?.isSubRow) {
            const personnelMenuOptionsRequestDTO = {
                personnelId: row?.id,
            };
            getMenuOptionsByPersonnel(personnelMenuOptionsRequestDTO)
                .then(response => {
                    if (response) {
                        setShow(!show);
                        const sortedMenuOptions = response.sort((a: any, b: any) => {
                            if (a.primary && !b.primary) {
                                return -1;
                            } else if (!a.primary && b.primary) {
                                return 1;
                            } else if (!a.primary && !b.primary) {
                                return a.actionName.localeCompare(b.actionName);
                            }
                        });
                        setActionMenuOptions(sortedMenuOptions);
                    } else {
                        setShow(false);
                    }
                })
                .catch(() => {
                    showAlert(
                        "Error retrieving menu options for personnel, Please contact BIGS Systems",
                        "danger",
                    );
                    setShow(false);
                });
        }
    }, [selectedMenuOptions, row]);

    const acceptPersonnelCallback = (
        id: number,
        bgChecks: { criminal: boolean; credit: boolean; education: boolean },
        drugTest: boolean,
        rosterId: number,
    ) => {
        const acceptPersonnelDTO: AcceptPersonnelDtoType = {
            personnelId: id,
            bgCheckCriminal: bgChecks.criminal,
            bgCheckCredit: bgChecks.credit,
            bgCheckEducation: bgChecks.education,
            drugTesting: drugTest,
        };
        acceptRoster(rosterId, acceptPersonnelDTO)
            .then(() => {
                row.locked = true;
                showAlert(`Accepted`, `success`);
            })
            .catch(() => {
                showAlert(`Failed to accept Personnel, Please contact BIGS Systems`, `danger`);
            });
    };

    const acceptClickHandler = () => {
        dispatch({
            type: "openAcceptPersonnelModal",
            personnelInfo: row,
            acceptPersonnelCallback: acceptPersonnelCallback,
        });
    };

    const exemptPersonnelCallback = async (
        rosterId: number,
        exemptComment: string,
        setExemptComment: Function,
        drugTestingState: boolean,
        setDrugTestingState: Function,
    ) => {
        const exemptPersonnelDTO = {
            comment: exemptComment,
            drugTesting: drugTestingState,
        };
        try {
            await exemptRoster(rosterId, exemptPersonnelDTO);
            row.locked = true;
            setExemptComment("");
            setDrugTestingState(null);
            showAlert("Exempt", "success");
        } catch (e) {
            console.log("Exempt operation failed");
            setExemptComment("");
            setDrugTestingState(null);
            showAlert("Exempt operation failed", "danger");
        }
    };

    const exemptClickHandler = () => {
        dispatch({
            type: "openExemptPersonnelModal",
            personnelInfo: row,
            exemptPersonnelCallback: exemptPersonnelCallback,
        });
    };

    const addCommentHandler = () => {
        dispatch({
            type: "openAddCommentModal",
            personnelId: row?.id,
        });
    };

    const submitToBgcHandler = (actionFunc: Function) => {
        dispatch({
            type: "openSubmitToBgcModal",
            personnelId: row?.id,
            onSubmitToBgcCallback: actionFunc,
        });
    };

    const resendDtConsentFormHandler = (actionFunc: Function) => {
        dispatch({
            type: "openResendDtConsentFormModal",
            resendClickHandler: actionFunc,
            personnelId: row?.id,
        });
    };

    const resendBgcConsentFormHandler = (actionFunc: Function) => {
        dispatch({
            type: "openResendBgcConsentFormModal",
            onResendBgcCallback: actionFunc,
            personnelId: row?.id,
        });
    };

    const uploadBgcConsentFormHandler = (actionFunc: Function) => {
        dispatch({
            type: "openUploadBgcConsentFormModal",
            personnel: row,
        });
    };

    const reviewFindingsHandler = (actionFunc: Function) => {
        dispatch({
            type: "openReviewFindingsModal",
            personnel: row,
        });
    };

    const markCompleteHandler = (actionFunc: Function) => {
        dispatch({
            type: "openMarkCompleteModal",
            personnel: row,
        });
    };

    const viewEditProfileHandler = () => {
        setPersonnelId(row.id.toString());
        setDisplayProfile(true);
        setShow(false);
    };

    const reviewDisclosureHandler = (actionFunc: Function) => {
        dispatch({
            type: "openReviewDisclosureModal",
            personnel: row,
            targetJurisdiction: "usPuerto",
        });
    };

    const correctErrorsHandler = () => {
        dispatch({
            type: "openReviewErrorsModal",
            personnelFormStatusInfo: { formId: 1, personnelId: row.id, invalidSSN: "", newSSN: "" },
        });
    };

    const uploadDtConsentFormHandler = () => {
        dispatch({
            type: "openUploadDtConsentFormModal",
            personnelId: row?.id,
        });
    };

    const resetToPendingBocHandler = (
        personnelId: number,
        actionFunc: Function,
        actionName: string,
    ) => {
        dispatch({
            type: "openAreYouSureModal",
            yesCallback: () => {
                actionFunc(personnelId, "", actionName);
            },
            content: "This action is permanent and cannot be undone.",
        });
    };

    const resetAndResendHandler = (
        personnelId: number,
        actionFunc: Function,
        actionName: string,
    ) => {
        dispatch({
            type: "openAreYouSureModal",
            yesCallback: () => {
                actionFunc(personnelId, "", actionName);
            },
            content: "This action is permanent and cannot be undone.",
        });
    };

    const genericStatusChangeActionFun = (
        personnelId: number,
        country: string,
        status: string,
        successMessage?: string,
    ) => {
        const statusChangeDTO = { country: country, eventName: status };
        moveStatus(personnelId, statusChangeDTO)
            .then(() => {
                showAlert(successMessage ? successMessage : "Success");
            })
            .catch(() => {
                showAlert("Error moving personnel to status: " + status, "danger");
            });
    };

    const genericStatusChangeHandler = (actionFunc: Function, actionName: string) => {
        if (!actionFunc) {
            actionFunc = genericStatusChangeActionFun;
        }
        switch (actionName) {
            case "Accept":
                acceptClickHandler();
                break;
            case "Exempt":
                exemptClickHandler();
                break;
            case "Add Comment and Files":
                addCommentHandler();
                break;
            case "Submit to BGC":
                submitToBgcHandler(actionFunc);
                break;
            case "Resend DT Consent Form":
                resendDtConsentFormHandler(actionFunc);
                break;
            case "Resend BGC Consent Form":
                resendBgcConsentFormHandler(actionFunc);
                break;
            case "Upload BGC Consent Form":
                uploadBgcConsentFormHandler(actionFunc);
                break;
            case "Review Findings":
                reviewFindingsHandler(actionFunc);
                break;
            case "Review Disclosures":
                reviewDisclosureHandler(actionFunc);
                break;
            case "View/Edit Profile":
                viewEditProfileHandler();
                break;
            case "Correct Errors":
                correctErrorsHandler();
                break;
            case "Upload DT Consent Form":
                uploadDtConsentFormHandler();
                break;
            case "Resend BGC Questionnaire":
                actionFunc(row?.id, "country", actionName, "Sent");
                break;
            case "Mark Complete":
                markCompleteHandler(actionFunc);
                break;
            case "Reset to Pending BOC":
                resetToPendingBocHandler(row?.id, actionFunc, actionName);
                break;
            case "Reset and Resend Consent Forms":
                resetAndResendHandler(row?.id, actionFunc, actionName);
                break;
            default:
                actionFunc(row?.id, "country", actionName);
        }
    };

    return (
        <div>
            {!row?.isSubRow ? (
                <ActionMenuContainer>
                    <div
                        ref={target}
                        onClick={() => {
                            if (!row?.locked && !show) {
                                getMenuOptionsForPersonnel();
                            } else if (show) {
                                setShow(false);
                            }
                        }}
                    >
                        <HoverIcon
                            disabled={row?.locked}
                            iconName={show ? "fa-times-circle" : "fa-ellipsis-v"}
                        />
                    </div>

                    <Overlay
                        target={target.current}
                        show={show}
                        placement="bottom"
                        onHide={() => setShow(false)}
                        rootClose={true}
                        rootCloseEvent="click"
                        flip={true}
                    >
                        {props => (
                            <ActionMenu {...props}>
                                {actionMenuOptions.map((actionMenuOption: any) => {
                                    if (actionMenuOption?.primary) {
                                        return (
                                            <PrimaryActionButton
                                                key={actionMenuOption.actionName.replace(/\s/g, "")}
                                                onClick={() => {
                                                    genericStatusChangeHandler(
                                                        actionMenuOption.actionFunc,
                                                        actionMenuOption.actionName,
                                                    );
                                                }}
                                            >
                                                {actionMenuOption.actionName}
                                            </PrimaryActionButton>
                                        );
                                    } else {
                                        return (
                                            <ActionButton
                                                key={actionMenuOption.actionName.replace(/\s/g, "")}
                                                onClick={() => {
                                                    genericStatusChangeHandler(
                                                        actionMenuOption.actionFunc,
                                                        actionMenuOption.actionName,
                                                    );
                                                }}
                                            >
                                                {actionMenuOption.actionName}
                                            </ActionButton>
                                        );
                                    }
                                })}
                            </ActionMenu>
                        )}
                    </Overlay>
                </ActionMenuContainer>
            ) : null}
        </div>
    );
};

export default withRouter(MenuFormatter);
