import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/affiliate`;

export type AffiliationContactDtoType = {
    orgId: number;
    teamId: number;
    name: string;
    contact: string;
    phone: string;
    email: string;
};

export const getAffiliationsByOrgId = (orgId: number): Promise<any> =>
    axios.get(`${API_URL}/${orgId}`).then(response => response.data);

export const getAllAffiliations = (): Promise<any> =>
    axios.get(`${API_URL}/`).then(response => response.data);

export const getAllOrgs = (type: string): Promise<any> =>
    axios.get(`${API_URL}/getAllOrgs?type=${type}`).then(response => response.data);

export const getAllActiveUmpires = (): Promise<any> =>
    axios.get(`${API_URL}/getAllActiveUmpires`).then(response => response.data);

export const getAllEvents = (): Promise<any> =>
    axios.get(`${API_URL}/getAllEvents`).then(response => response.data);

export const getAllOrgsForOrgsPage = (): Promise<any> =>
    axios.get(`${API_URL}/viewRosterCertStatus`).then(response => response.data);

export const getOrgCertificationCount = (): Promise<any> =>
    axios.get(`${API_URL}/getOrgCertificationCount`).then(response => response.data);

export const updateAffiliateContact = (
    affiliationContactDTO: AffiliationContactDtoType,
): Promise<any> =>
    axios
        .post(`${API_URL}/updateAffiliateContact`, affiliationContactDTO)
        .then(response => response.data);
