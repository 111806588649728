import React from "react";
import styled from "styled-components";
import { useQuestionnaire } from "../../../contexts/QuestionnaireContext";
import { QuestionDef } from "../QuestionDefs";
import { BigsSelect } from "../../elements/BigsBcrWrapper";

type DropDownQuestionProps = {
    questionDef: QuestionDef;
};

const DropDownContent = styled.div`
    margin: 20px 0px 0px 0px;
    width: 350px;
`;

const DropdownQuestion: React.FC<DropDownQuestionProps> = ({ questionDef }) => {
    const { questionnaire, dispatchQuestionnaire } = useQuestionnaire();
    const selectionChangeHandler = (selected: any) => {
        dispatchQuestionnaire({
            type: questionDef.type,
            id: questionDef.id,
            selected: selected,
        });
    };

    return (
        questionDef.show(questionnaire) && (
            <DropDownContent>
                <label className={questionDef.required ? "required" : ""}>
                    {questionnaire.language.value && questionnaire.language.value === "spanish"
                        ? questionDef.displayTextEs
                        : questionDef.displayTextEn}
                </label>
                <BigsSelect
                    onChange={selectionChangeHandler}
                    options={questionDef.options}
                    value={questionnaire[questionDef.id]}
                    isDisabled={questionnaire.isPreExisting}
                />
            </DropDownContent>
        )
    );
};

export default DropdownQuestion;
