import { SetStateAction, Dispatch, useEffect } from "react";
import { getDrugTestExemptStatusByPersonnelIdList } from "../api/ConsentFormApi";
import { useAlert } from "../contexts/AlertsContext";

export const useBulkDTexemptCheck = (
    personnelList: Array<any>,
    setPersonnelList: Dispatch<SetStateAction<Array<any>>>,
) => {
    const showAlert = useAlert();
    useEffect(() => {
        if (personnelList && personnelList.length > 0) {
            getDrugTestExemptStatusByPersonnelIdList(personnelList.map(personnel => personnel.id))
                .then(response => {
                    if (response) {
                        response.forEach((dtExemptStatus: any) => {
                            const index = personnelList.findIndex(
                                personnel => personnel.id === dtExemptStatus.personnelId,
                            );
                            if (index >= 0) {
                                if (personnelList[index]) {
                                    personnelList[index].dtExemptState =
                                        dtExemptStatus.orgExempt || dtExemptStatus.affiliateExempt;
                                }
                            }
                        });
                        setPersonnelList([...personnelList]);
                    }
                })
                .catch(() => {
                    showAlert(
                        "Error retrieving DT exempt status for selected personnel, Please contact BIGS Systems.",
                        "danger",
                    );
                });
        }
    }, []);
};
