export const NONE = "NONE";
export const ASC = "ASC";
export const DESC = "DESC";

const isNumber = (value: any) => typeof value == "number";

const toLower = (str: any) => {
    if (str) {
        if (isNumber(str)) {
            return str;
        } else {
            return str.toString().toLowerCase();
        }
    } else {
        return "";
    }
};

export const compare = (val1: any, val2: any, direction = 1, secondarySort: Function = () => 0) => {
    if (isNumber(val1) && isNumber(val2)) {
        return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
    }
    if (isNumber(val1)) {
        return direction;
    } else if (isNumber(val2)) {
        return -direction;
    } else {
        return val1 < val2 ? -direction : val1 > val2 ? direction : secondarySort(val1, val2);
    }
};

export const sort = (list: any[], field: string, direction = 1) => {
    return list.sort((a, b) =>
        a[field] < b[field] ? -direction : a[field] > b[field] ? direction : 0,
    );
};

export const sortWithFunction = (
    list: any[] = [],
    field: string,
    direction = 1,
    manipulationFunc = (a: any) => a,
    secondarySort = () => 0,
) =>
    list
        .map(a => a)
        .sort((a, b) => {
            const val1 = manipulationFunc(a[field]);
            const val2 = manipulationFunc(b[field]);
            return compare(val1, val2, direction, secondarySort);
        });

export const sortLabel = (a: any, b: any) => {
    const label1 = toLower(a.label);
    const label2 = toLower(b.label);
    return compare(label1, label2);
};

export const tableSort = (
    values: any[],
    sortFilters: { col: string; dir: string },
    defaultColumn?: string,
    secondarySort?: { (): number; (): number; (): number },
) => {
    if (sortFilters.dir === NONE) {
        return sortWithFunction(values, defaultColumn, 1, toLower, secondarySort);
    } else if (sortFilters.dir === ASC) {
        return sortWithFunction(values, sortFilters.col, 1, toLower, secondarySort);
    } else {
        return sortWithFunction(values, sortFilters.col, -1, toLower, secondarySort);
    }
};

export const sortPersonnelByOrg = (
    values: any[],
    sortDirection: string,
    secondarySort?: { (): number; (): number; (): number },
) => {
    if (sortDirection === NONE) {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.name;
                const val2 = b.name;
                return compare(val1, val2, 1, secondarySort);
            });
    } else if (sortDirection === ASC) {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.affiliation.orgAbbreviation;
                const val2 = b.affiliation.orgAbbreviation;
                return compare(val1, val2, 1, secondarySort);
            });
    } else {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.affiliation.orgAbbreviation;
                const val2 = b.affiliation.orgAbbreviation;
                return compare(val1, val2, -1, secondarySort);
            });
    }
};

export const sortPersonnelByOrgName = (
    values: any[],
    sortDirection: string,
    secondarySort?: { (): number; (): number; (): number },
) => {
    if (sortDirection === NONE) {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.name;
                const val2 = b.name;
                return compare(val1, val2, 1, secondarySort);
            });
    } else if (sortDirection === ASC) {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.affiliation.name;
                const val2 = b.affiliation.name;
                return compare(val1, val2, 1, secondarySort);
            });
    } else {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.affiliation.name;
                const val2 = b.affiliation.name;
                return compare(val1, val2, -1, secondarySort);
            });
    }
};

export const sortByScore = (
    values: any[],
    sortDirection: string,
    secondarySort?: { (): number; (): number; (): number },
) => {
    if (sortDirection === NONE) {
        return values
            .map(a => a)
            .sort((a, b) => {
                const val1 = a.name;
                const val2 = b.name;
                return compare(val1, val2, 1, secondarySort);
            });
    } else if (sortDirection === ASC) {
        return values
            .map(a => a)
            .sort((a, b) => {
                let val1 = "";
                let val2 = "";
                const passFailScore1 = a?.roster?.statuses.find(
                    (s: any) =>
                        s.status.status.includes("Fail") || s.status.status.includes("Pass"),
                );
                const passFailScore2 = b?.roster?.statuses.find(
                    (s: any) =>
                        s.status.status.includes("Fail") || s.status.status.includes("Pass"),
                );

                if (passFailScore1) {
                    if (passFailScore1.status?.status === "BGC score is Fail") {
                        val1 = "Fail";
                    } else {
                        val1 = "Pass";
                    }
                } else {
                    val1 = "Pending";
                }

                if (passFailScore2) {
                    if (passFailScore2.status?.status === "BGC score is Fail") {
                        val2 = "Fail";
                    } else {
                        val2 = "Pass";
                    }
                } else {
                    val2 = "Pending";
                }
                return compare(val1, val2, 1, secondarySort);
            });
    } else {
        return values
            .map(a => a)
            .sort((a, b) => {
                let val1 = "";
                let val2 = "";
                const passFailScore1 = a?.roster?.statuses.find(
                    (s: any) =>
                        s.status.status.includes("Fail") || s.status.status.includes("Pass"),
                );
                const passFailScore2 = b?.roster?.statuses.find(
                    (s: any) =>
                        s.status.status.includes("Fail") || s.status.status.includes("Pass"),
                );

                if (passFailScore1) {
                    if (passFailScore1.status?.status === "BGC score is Fail") {
                        val1 = "Fail";
                    } else {
                        val1 = "Pass";
                    }
                } else {
                    val1 = "Pending";
                }

                if (passFailScore2) {
                    if (passFailScore2.status?.status === "BGC score is Fail") {
                        val2 = "Fail";
                    } else {
                        val2 = "Pass";
                    }
                } else {
                    val2 = "Pending";
                }
                return compare(val1, val2, -1, secondarySort);
            });
    }
};

// TODO -- Sort by status once table is collapsable
export const sortPersonnelByStatus = (
    values: any[],
    sortDirection: string,
    secondarySort?: { (): number; (): number; (): number },
) => {
    return values;
};
