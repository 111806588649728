import React, { createContext, useContext, useReducer } from "react";
import QuestionsDefs, { DropDownOptions } from "../modules/questionnaire/QuestionDefs";
import { usStatesOptions } from "../modules/questionnaire/dropdownOptionDefs/UsStateOptions";
import FormUtil from "../util/FormUtil";

export interface QuestionnaireType {
    language: DropDownOptions;
    over18: string;
    birthday: string;
    countryLiveIn: DropDownOptions;
    otherCountryLiveIn: string;
    stateLiveIn: DropDownOptions;
    liveInPortland: string;
    liveInPhiladelphia: string;
    liveInSf: string;
    countryWorkIn: DropDownOptions;
    otherCountryWorkIn: string;
    stateWorkIn: DropDownOptions;
    workInPortland: string;
    workInPhiladelphia: string;
    workInSf: string;
    submittable: boolean;
    shortQuestionnaireSubmittable: boolean;
    shortQuestionnaire: boolean;
    isPreExisting?: boolean;
    submittedDrugTestState?: boolean;
}

interface QuestionnaireContextType {
    questionnaire: QuestionnaireType;
    dispatchQuestionnaire: React.Dispatch<any>;
    getJurisdictionOptionsFromQuestionnaire(): Array<{ label: string; value: string }>;
}

const questionnaireInitState = {
    language: { value: "", label: "" },
    over18: "",
    birthday: "",
    countryLiveIn: { value: "", label: "" },
    otherCountryLiveIn: "",
    stateLiveIn: { value: "", label: "" },
    liveInPortland: "",
    liveInPhiladelphia: "",
    liveInSf: "",
    countryWorkIn: { value: "", label: "" },
    otherCountryWorkIn: "",
    stateWorkIn: { value: "", label: "" },
    workInPortland: "",
    workInPhiladelphia: "",
    workInSf: "",
    submittable: false,
    shortQuestionnaireSubmittable: false,
    shortQuestionnaire: false,
    isPreExisting: false,
    submittedDrugTestState: false,
};

const QuestionnaireContext = createContext<QuestionnaireContextType>({
    questionnaire: null,
    dispatchQuestionnaire: null,
    getJurisdictionOptionsFromQuestionnaire: null,
});

// Each question has an "order" property which represents the order of the questions.
// This function resets the state values of all questions following the passed in question
const resetDependentQuestionState = (currentQuestion: string, state: QuestionnaireType) => {
    const currentQuestionOrder = QuestionsDefs.find(q => q.id === currentQuestion).order;
    const dependentQuestions = QuestionsDefs.filter(q => q.order > currentQuestionOrder);
    dependentQuestions.forEach(q => {
        if (q.type === "DropDown") {
            state = { ...state, [q.id]: { value: "", label: "" } };
        } else {
            state = { ...state, [q.id]: "" };
        }
    });
    return state;
};

// Check if a question contains a valid answer.
const answered = (stateName: string, state: QuestionnaireType) => {
    const question = QuestionsDefs.find(q => q.id === stateName);

    if (question.type === "DropDown") {
        return !!state[stateName].value;
    } else {
        return !!state[stateName];
    }
};

// Function used by "submittable" to determine if Questionnaire is in submittable state.
const countryWorkInSubmittable = (state: QuestionnaireType) => {
    if (answered("countryWorkIn", state)) {
        if (state.countryWorkIn.value !== "usPuerto") {
            if (state.countryWorkIn.value !== "other") {
                return true;
            } else {
                if (state.otherCountryWorkIn) {
                    return true;
                }
            }
        } else {
            if (answered("stateWorkIn", state)) {
                if (state.stateWorkIn.value === "OR") {
                    if (answered("workInPortland", state)) {
                        return true;
                    }
                } else if (state.stateWorkIn.value === "PA") {
                    if (answered("workInPhiladelphia", state)) {
                        return true;
                    }
                } else if (state.stateWorkIn.value === "CA") {
                    if (answered("workInSf", state)) {
                        return true;
                    }
                } else {
                    return true;
                }
            }
        }
    }
    return false;
};

// Check if Questionnaire is in submittable state.
const submittable = (state: QuestionnaireType) => {
    if (answered("language", state)) {
        if (answered("over18", state)) {
            if (state.over18 === "over18no") {
                if (answered("birthday", state)) {
                    return true;
                }
            } else if (state.over18 === "over18yes") {
                if (answered("countryLiveIn", state)) {
                    if (state.countryLiveIn.value !== "usPuerto") {
                        return countryWorkInSubmittable(state);
                    } else {
                        if (answered("stateLiveIn", state)) {
                            if (state.stateLiveIn.value === "OR") {
                                if (answered("liveInPortland", state)) {
                                    return countryWorkInSubmittable(state);
                                }
                            } else if (state.stateLiveIn.value === "PA") {
                                if (answered("liveInPhiladelphia", state)) {
                                    return countryWorkInSubmittable(state);
                                }
                            } else if (state.stateLiveIn.value === "CA") {
                                if (answered("liveInSf", state)) {
                                    return countryWorkInSubmittable(state);
                                }
                            } else {
                                return countryWorkInSubmittable(state);
                            }
                        }
                    }
                }
            }
        }
    }
    return false;
};

const checkCountryWorkIn = (state: QuestionnaireType) => {
    if (answered("countryWorkIn", state)) {
        if (state.countryWorkIn.value === "usPuerto") {
            if (answered("stateWorkIn", state)) {
                return true;
            }
        } else if (state.countryWorkIn.value === "other") {
            if (answered("otherCountryWorkIn", state)) {
                return true;
            }
        } else {
            return true;
        }
    }
};

// Check if short questionnaire in modal is submittable
const shortQuestionnaireSubmittable = (state: QuestionnaireType) => {
    if (answered("countryLiveIn", state)) {
        if (state.countryLiveIn.value === "usPuerto") {
            if (answered("stateLiveIn", state)) {
                return checkCountryWorkIn(state);
            }
        } else if (state.countryLiveIn.value === "other" && answered("otherCountryLiveIn", state)) {
            return checkCountryWorkIn(state);
        } else {
            return checkCountryWorkIn(state);
        }
    }
    return false;
};

const setShortQuestionnaireResult = (questionnaireResult: any) => {
    if (questionnaireResult.countryLiveIn === "usPuerto") {
        questionnaireResult.countryLiveIn = {
            label: "United States",
            value: questionnaireResult.countryLiveIn,
        };
        questionnaireResult.stateLiveIn = usStatesOptions.find(
            opt => opt.value === questionnaireResult.stateLiveIn,
        );
    } else if (questionnaireResult.countryLiveIn !== "other") {
        questionnaireResult.countryLiveIn = {
            label: FormUtil.capitalize(questionnaireResult.countryLiveIn),
            value: questionnaireResult.countryLiveIn,
        };
    } else {
        questionnaireResult.countryLiveIn = {
            label: "Other",
            value: "other",
        };
        questionnaireResult.otherCountryLiveIn = questionnaireResult.otherCountryLiveIn;
    }

    if (questionnaireResult.countryWorkIn === "usPuerto") {
        questionnaireResult.countryWorkIn = {
            label: "United States",
            value: questionnaireResult.countryWorkIn,
        };
        questionnaireResult.stateWorkIn = usStatesOptions.find(
            opt => opt.value === questionnaireResult.stateWorkIn,
        );
    } else if (questionnaireResult.countryWorkIn !== "other") {
        questionnaireResult.countryWorkIn = {
            label: FormUtil.capitalize(questionnaireResult.countryWorkIn),
            value: questionnaireResult.countryWorkIn,
        };
    } else {
        questionnaireResult.countryWorkIn = {
            label: "Other",
            value: "other",
        };
        questionnaireResult.otherCountryWorkIn = questionnaireResult.otherCountryWorkIn;
    }

    // All loaded questionnaire results should display in English
    questionnaireResult.language = { value: "English", label: "English" };
    questionnaireResult.isPreExisting = true;

    return questionnaireResult;
};

const questionnaireReducer = (state: QuestionnaireType, action: any) => {
    if (action.type === "setShortQuestionnaire") {
        return { ...state, shortQuestionnaire: true };
    } else if (action.type === "resetState") {
        return { ...questionnaireInitState };
    } else if (action.type === "setShortQuestionnaireResult") {
        state = { ...setShortQuestionnaireResult(action.questionnaireResult) };
        return {
            ...state,
            shortQuestionnaireSubmittable: shortQuestionnaireSubmittable(state),
        };
    } else if (action.type === "setQuestionnairePreExisting") {
        return {
            ...state,
            isPreExisting: true,
        };
    } else if (action.type === "setSubmittedDrugTestState") {
        return {
            ...state,
            submittedDrugTestState: action.submittedDrugTestState,
        };
    }

    state = resetDependentQuestionState(action.id, state);

    switch (action.type) {
        case "DropDown":
            state[action.id] = action.selected;
            if (state.shortQuestionnaire) {
                return {
                    ...state,
                    shortQuestionnaireSubmittable: shortQuestionnaireSubmittable(state),
                };
            } else {
                return { ...state, submittable: submittable(state) };
            }

        case "RadioYesNo":
        case "Text":
            state[action.id] = action.value;
            if (state.shortQuestionnaire) {
                return {
                    ...state,
                    shortQuestionnaireSubmittable: shortQuestionnaireSubmittable(state),
                };
            } else {
                return { ...state, submittable: submittable(state) };
            }

        case "Date":
            state[action.id] = action.date ? action.date.toISOString() : "";
            if (state.shortQuestionnaire) {
                return {
                    ...state,
                    shortQuestionnaireSubmittable: shortQuestionnaireSubmittable(state),
                };
            } else {
                return { ...state, submittable: submittable(state) };
            }
        default:
            return state;
    }
};

const QuestionnaireContextProvider: React.FC = ({ children }) => {
    const [questionnaire, dispatchQuestionnaire] = useReducer(
        questionnaireReducer,
        questionnaireInitState,
    );

    const getJurisdictionOptionsFromQuestionnaire = (): Array<{ label: string; value: string }> => {
        const jurisdictions = new Array<{ label: string; value: string }>();
        if (questionnaire?.shortQuestionnaireSubmittable) {
            if (questionnaire.countryLiveIn.value !== "other") {
                jurisdictions.push(questionnaire.countryLiveIn);
            } else {
                jurisdictions.push({
                    label: questionnaire.otherCountryLiveIn,
                    value: questionnaire.otherCountryLiveIn,
                });
            }

            if (questionnaire.countryWorkIn.value !== "other") {
                jurisdictions.push(questionnaire.countryWorkIn);
            } else {
                jurisdictions.push({
                    label: questionnaire.otherCountryWorkIn,
                    value: questionnaire.otherCountryWorkIn,
                });
            }
        }

        return jurisdictions;
    };

    return (
        <QuestionnaireContext.Provider
            value={{
                questionnaire,
                dispatchQuestionnaire,
                getJurisdictionOptionsFromQuestionnaire,
            }}
        >
            {children}
        </QuestionnaireContext.Provider>
    );
};

const useQuestionnaire = (): QuestionnaireContextType => {
    const context = useContext<QuestionnaireContextType>(QuestionnaireContext);
    if (context === undefined) {
        throw new Error(`useQuestionnaire must be used within a LoadingContextProvider`);
    }

    return context;
};

export { QuestionnaireContextProvider, useQuestionnaire };
