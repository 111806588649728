import Moment from "moment";

export const DateUtil = {
    formatDate: (date: string, format = "MM/DD/YYYY") => {
        const m = Moment.isMoment(date) ? date : Moment(date);

        if (format.includes("z")) {
            return Moment(date)
                .tz(Moment.tz.guess())
                .format(format);
        }

        return m.format(format);
    },
};
