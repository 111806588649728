import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/export`;

export const exportAdminPersonnel = (
    personnelSearch: any,
    sortDir: string,
    sortCol: string,
): Promise<any> =>
    axios
        .post(`${API_URL}/adminpersonnel?sortDir=${sortDir}&sortCol=${sortCol}`, personnelSearch, {
            responseType: "blob",
        })
        .then(response => response);

export const exportClubPersonnel = (
    clubPersonnelSearch: any,
    sortDir: string,
    sortCol: string,
): Promise<any> =>
    axios
        .post(
            `${API_URL}/clubpersonnel?sortDir=${sortDir}&sortCol=${sortCol}`,
            clubPersonnelSearch,
            {
                responseType: "blob",
            },
        )
        .then(response => response);

export const exportBackgroundCheckPersonnel = (
    personnelSearch: any,
    sortDir: string,
    sortCol: string,
): Promise<any> =>
    axios
        .post(
            `${API_URL}/backgroundcheckpersonnel?sortDir=${sortDir}&sortCol=${sortCol}`,
            personnelSearch,
            {
                responseType: "blob",
            },
        )
        .then(response => response);

export const exportDrugTestingPersonnel = (
    personnelSearch: any,
    sortDir: string,
    sortCol: string,
): Promise<any> =>
    axios
        .post(
            `${API_URL}/drugtestpersonnel?sortDir=${sortDir}&sortCol=${sortCol}`,
            personnelSearch,
            {
                responseType: "blob",
            },
        )
        .then(response => response);
