import React from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { exportBackgroundCheckPersonnel } from "../../../api/ExportApi";
import { exportDrugTestingPersonnel } from "../../../api/ExportApi";
import FileUtil from "../../../util/FileUtil";
import { PersonnelTypes } from "../../../constants/PersonnelTypes";

const BodyContent = styled.div`
    text-align: left;
    margin-bottom: 10px;
    padding: 0.5rem;
`;

type ExportModalBodyProps = {};

const ExportModalBodyContent: React.FC<ExportModalBodyProps> = ({}) => {
    const {
        state: {
            selectedMenuOptions,
            affiliationFilters,
            searchText,
            inactiveSelected,
            sortFilters,
        },
    } = useGlobalModal();

    const exportBackgroundCheck = () => {
        const personnelSearch = {
            searchText: searchText && searchText.length !== 0 ? searchText : null,
            orgIds: affiliationFilters ? affiliationFilters.map((opt: any) => opt.orgId) : null,
            personnelType: PersonnelTypes.CLUB,
            statuses: inactiveSelected ? [-1] : selectedMenuOptions.map((opt: any) => opt.id),
        };

        let sortFilterCol = sortFilters.col;
        if (sortFilterCol === "affiliateNameLevel") {
            sortFilterCol = "affiliation.name";
        }
        if (sortFilterCol === "org") {
            sortFilterCol = "affiliation.orgAbbreviation";
        }
        exportBackgroundCheckPersonnel(personnelSearch, sortFilters.dir, sortFilterCol).then(
            response => {
                FileUtil.downloadFile(response, "xlsx");
            },
        );
    };

    const exportDrugTesting = () => {
        const personnelSearch = {
            searchText: searchText && searchText.length !== 0 ? searchText : null,
            orgIds: affiliationFilters ? affiliationFilters.map((opt: any) => opt.orgId) : null,
            personnelType: PersonnelTypes.CLUB,
            statuses: inactiveSelected ? [-1] : selectedMenuOptions.map((opt: any) => opt.id),
        };

        let sortFilterCol = sortFilters.col;
        if (sortFilterCol === "affiliateNameLevel") {
            sortFilterCol = "affiliation.name";
        }
        if (sortFilterCol === "org") {
            sortFilterCol = "affiliation.orgAbbreviation";
        }
        exportDrugTestingPersonnel(personnelSearch, sortFilters.dir, sortFilterCol).then(
            response => {
                FileUtil.downloadFile(response, "xlsx");
            },
        );
    };

    return (
        <div className="d-flex">
            <BodyContent>
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={exportBackgroundCheck}
                >
                    Background Check
                </OutlinedButtonStyled>
            </BodyContent>
            <BodyContent>
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={exportDrugTesting}
                >
                    Drug Testing
                </OutlinedButtonStyled>
            </BodyContent>
        </div>
    );
};

const ExportModalFooterContent = () => {
    const {
        state: { yesCallback },
        closeModal,
    } = useGlobalModal();

    return (
        <div className="d-flex">
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    OK
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ExportModalBodyContent.displayName = "BodyContent";
ExportModalFooterContent.displayName = "FooterContent";

export { ExportModalBodyContent, ExportModalFooterContent };
