import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/questionnaire`;

export const isValidUrlId = (encryptedPersonnelId: any): Promise<any> =>
    axios.post(`${API_URL}/isValid/`, encryptedPersonnelId).then(response => response.data);
export const submitQuestionnaire = (encryptedPersonnelId: string, answers: any): Promise<any> =>
    axios
        .post(`${API_URL}/submit?id=${encryptedPersonnelId}`, answers)
        .then(response => response.data);
export const submitShortQuestionnaire = (
    personnelId: number,
    shortQuestionnaireDTO: any,
): Promise<any> =>
    axios
        .post(`${API_URL}/submitShortQuestionnaire/${personnelId}`, shortQuestionnaireDTO)
        .then(response => response.data);

export const getCompletedQuestionnaireResult = (personnelId: number): Promise<any> =>
    axios
        .get(`${API_URL}/getCompletedQuestionnaireResult/${personnelId}`)
        .then(response => response.data);
