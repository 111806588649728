import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RequiredLabel } from "../../FormElements";
import { TextArea } from "best-common-react";
import styled from "styled-components";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";
import { BigsInput, BigsSelect } from "../../BigsBcrWrapper";
import { getPersonnelAliasNameList, addAdditionalJurisdiction } from "../../../../api/PersonnelApi";
import { PersonnelTypes } from "../../../../constants/PersonnelTypes";
import { countryOptions } from "../../../questionnaire/dropdownOptionDefs/CountryOptions";
import { useAlert } from "../../../../contexts/AlertsContext";
import { sendAdditionalConsentForm } from "../../../../api/ConsentFormApi";

type ReviewTabProps = {
    personnel: any;
    currentJurisdiction: any;
    reviewComment: string;
    setReviewComment: Function;
    acceptReviewContent: Function;
    readOnly: boolean;
};

const Review = styled.div.attrs(() => ({
    className: "",
}))`
    & .col-md-7 {
        padding-right: 0px;
    }

    & .btn {
        font-size: unset;
    }
`;
const ReviewContent = styled.div.attrs(() => ({
    className: "",
}))`
    background-color: white;
    border: 1px solid var(--fog);
    height: 220px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.5rem;
`;

const CommentContent = styled.div.attrs(() => ({
    className: "",
}))`
    background-color: white;
    border: 1px solid var(--fog);
    height: 220px;
    padding: 0.5rem;
`;
const EditPersonnelNameSection = styled.div.attrs(() => ({
    className: "",
}))`
    margin: 5px 0 0px 0;
`;
const AdditionalNameSection = styled.div.attrs(() => ({
    className: "",
}))`
    margin: 5px 0 5px 0;
`;

const NameLabel = styled.div.attrs(() => ({
    className: "",
}))`
    color: var(--blue);
    cursor: pointer;
    margin-left: 16px;
`;

const DeleteIcon = styled.i.attrs(() => ({
    className: "fa fa-trash",
}))`
    color: var(--blue);
    font-size: 18px;
    line-height: 1.8;
`;

const DisabledDeleteIcon = styled.i.attrs(() => ({
    className: "fa fa-trash",
}))`
    color: var(--grey);
    font-size: 18px;
    line-height: 1.8;
`;

const suffixOptions = [
    {
        value: "Jr.",
        label: "Jr.",
    },
    {
        value: "Sr.",
        label: "Sr.",
    },
    {
        value: "I",
        label: "I",
    },
    {
        value: "II",
        label: "II",
    },
    {
        value: "III",
        label: "III",
    },
    {
        value: "IV",
        label: "IV",
    },
];

const ReviewTab: React.FC<ReviewTabProps> = ({
    personnel,
    currentJurisdiction,
    reviewComment,
    setReviewComment,
    acceptReviewContent,
    readOnly,
}) => {
    const showAlert = useAlert();
    const [aliasNameList, setAliasNameList] = useState([]);
    const [personnelNames, setPersonnelNames] = useState([]);
    const [aliasModified, setAliasModified] = useState(false);
    const [additionalJurisdiction, setAdditionalJurisdiction] = useState<
        Array<any> | { label: string; value: string }
    >([]);
    const [addAdditionalJurisidictionVisible, setAdditionalJurisdictionVisible] = useState(false);
    const [sendConsentFormDisabled, setSendConsentFormDisabled] = useState(true);
    const [otherJurisdiction, setOtherJurisdiction] = useState(null);
    const [otherJurisdictionVisible, setOtherJurisdictionVisible] = useState(false);

    const addAliasHandler = () => {
        if (!currentJurisdiction.reviewPending) {
            return;
        }
        const emptyRow = aliasNameList.filter(
            a => !a.firstName.trim() && !a.middleName.trim() && !a.lastName.trim(),
        );

        let newAliasNameList;
        // No need to add new row if there is empty row available.
        if (emptyRow.length > 0) {
            newAliasNameList = JSON.parse(JSON.stringify(aliasNameList));
        } else {
            newAliasNameList = [...aliasNameList];
            newAliasNameList.push({
                firstName: "",
                middleName: "",
                lastName: "",
            });
        }

        setAliasNameList(newAliasNameList);
        setAliasModified(true);
    };

    const addAdditionalJurisdictionHandler = () => {
        setAdditionalJurisdictionVisible(true);
    };

    useEffect(() => {
        getPersonnelAliasNameList(personnel.id).then(response => {
            setAliasNameList(response);
            if (personnel.personnelType.type === PersonnelTypes.VMS && response.length === 0) {
                //If this is volunteer, add an empty row for alias by default
                addAliasHandler();
            }
        });
        setPersonnelNames([]);
    }, [currentJurisdiction]);
    const commentHandler = (event: any) => {
        setReviewComment(event.currentTarget.value);
    };
    const acceptReviewHandler = (event: any) => {
        let aliasList = null;
        if (aliasModified) {
            aliasList = aliasNameList.filter(a => {
                a.lastName = a.lastName.trim();
                a.middleName = a.middleName.trim();
                a.firstName = a.firstName.trim();
                return a.firstName || a.middleName || a.lastName;
            });
        }

        let personnelName;
        if (personnelNames.length > 0) {
            personnelName = personnelNames[0];
            personnelName.editName = true;
            personnelName.firstName = personnelName.firstName.trim();
            personnelName.lastName = personnelName.lastName.trim();
            personnelName.middleName = personnelName.middleName.trim();
            personnelName.nickName = personnelName.nickName.trim();
            personnelName.suffix = personnelName.suffix.value;
        } else {
            personnelName = { editName: false };
        }
        acceptReviewContent(personnelName, aliasList);
        setAliasModified(false);
    };
    const aliasNameChangeHandler = (index: number, fieldName: string, value: string) => {
        const newAliasNameList = [...aliasNameList];
        newAliasNameList[index][fieldName] = value;
        setAliasNameList(newAliasNameList);
        setAliasModified(true);
    };
    const personnelNameChangeHandler = (index: number, fieldName: string, value: string) => {
        const newPersonnelNames = [...personnelNames];
        newPersonnelNames[index][fieldName] = value;
        setPersonnelNames(newPersonnelNames);
    };
    const suffixChangeHandler = (index: number, selected: any) => {
        const newPersonnelNames = [...personnelNames];
        newPersonnelNames[index]["suffix"] = selected;
        setPersonnelNames(newPersonnelNames);
    };

    const addPersonnelNamesHandler = () => {
        if (!currentJurisdiction.reviewPending) {
            return;
        }
        if (personnelNames.length >= 1) {
            return;
        }

        const newPersonnelNames = [...personnelNames];
        newPersonnelNames.push({
            firstName: "",
            middleName: "",
            lastName: "",
            nickName: "",
            suffix: { label: "", value: "" },
        });
        setPersonnelNames(newPersonnelNames);
    };

    const removePersonnelNameRow = (index: number) => {
        const newPersonnelNames = [...personnelNames];
        newPersonnelNames.splice(index, 1);
        setPersonnelNames(newPersonnelNames);
    };

    const removeAliasRow = (index: number) => {
        const newAliasNameList = [...aliasNameList];
        newAliasNameList.splice(index, 1);
        setAliasNameList(newAliasNameList);
    };

    const additionalJurisdictionOnChange = (jurisdictionOpt: any) => {
        setAdditionalJurisdiction(jurisdictionOpt);
        // if other enable text field
        if (jurisdictionOpt.value === "other") {
            setOtherJurisdictionVisible(true);
            setSendConsentFormDisabled(true);
        } else {
            // else enable sent consent form button
            setOtherJurisdictionVisible(false);
            setSendConsentFormDisabled(false);
            setOtherJurisdiction(null);
        }
    };

    const addOtherJurisdiction = () => {
        addAdditionalJurisdiction(personnel.id, otherJurisdiction)
            .then(response => {
                showAlert(otherJurisdiction + " added", "success");
                setAdditionalJurisdiction([]);
                setOtherJurisdiction(null);
                setOtherJurisdictionVisible(false);
                setAdditionalJurisdictionVisible(false);
            })
            .catch(() => {
                showAlert("Failed to add " + otherJurisdiction, "danger");
            });
    };

    const sendAdditionalConsentFormFn = () => {
        sendAdditionalConsentForm(
            personnel.id,
            (additionalJurisdiction as {
                label: string;
                value: string;
            })?.value,
        )
            .then(() => {
                showAlert("Sent", "success");
                setAdditionalJurisdiction([]);
                setAdditionalJurisdictionVisible(false);
                setSendConsentFormDisabled(true);
            })
            .catch(() => {
                showAlert("Failed to send additional consent form.", "danger");
            });
    };

    let itemNum = 1;
    return (
        <Review>
            <Row>
                <Col md={7}>
                    <ReviewContent>
                        <div>
                            {currentJurisdiction.review?.previousNames && (
                                <div className="mb-2">
                                    <Row>
                                        <Col md={1}>{itemNum++}.)</Col>
                                        <Col md={11}>Other Names Known Under: </Col>
                                    </Row>
                                    {currentJurisdiction.review?.previousNames.map(
                                        (name: any, index: number) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <Row key={index} className="font-weight-bold text-dark">
                                                <Col md={1} />
                                                <Col md={11}>
                                                    {name.firstName} {name.middleName}{" "}
                                                    {name.lastName}
                                                </Col>
                                            </Row>
                                        ),
                                    )}
                                </div>
                            )}
                            {currentJurisdiction.review?.countriesLived && (
                                <div>
                                    <Row>
                                        <Col md={1}>{itemNum++}.)</Col>
                                        <Col md={11}>
                                            Have you lived in any countries other than the one you
                                            currently reside in?
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1} />
                                        <Col md={11} className="font-weight-bold text-dark">
                                            {currentJurisdiction.review.countriesLived
                                                ? "Yes"
                                                : "No"}
                                        </Col>
                                    </Row>
                                    {currentJurisdiction.review?.countriesLived.map(
                                        (countryLived: any, index: number) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div key={index} className="mt-2">
                                                <Row>
                                                    <Col md={1} />
                                                    <Col md={4}> Country</Col>
                                                    <Col
                                                        md={7}
                                                        className="font-weight-bold text-dark"
                                                    >
                                                        {countryLived.country}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={1} />
                                                    <Col md={4}> Where</Col>
                                                    <Col
                                                        md={7}
                                                        className="font-weight-bold text-dark"
                                                    >
                                                        {countryLived.state}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={1} />
                                                    <Col md={4}> Date of Residence</Col>
                                                    <Col
                                                        md={7}
                                                        className="font-weight-bold text-dark"
                                                    >
                                                        {countryLived.from} - {countryLived.to}
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}
                        </div>
                    </ReviewContent>
                </Col>
                <Col md={5} className="noPaddingLeft">
                    <CommentContent>
                        <RequiredLabel
                            text="Review Comments"
                            required={!currentJurisdiction.reviewPending || readOnly ? false : true}
                        />
                        <div>
                            <TextArea
                                placeholder="enter comments..."
                                value={reviewComment}
                                rows={6}
                                disabled={!currentJurisdiction.reviewPending || readOnly}
                                onChange={commentHandler}
                                style={{
                                    resize: "none",
                                    fontSize: "14px",
                                    color: "var(--black)",
                                    fontWeight: "300",
                                    borderColor: "var(--fog)",
                                }}
                            />
                        </div>
                        <div className="mt-2">
                            {currentJurisdiction.reviewPending && (
                                <PrimaryButtonStyled
                                    width={298}
                                    height={26}
                                    disabled={
                                        !reviewComment ||
                                        readOnly ||
                                        (personnelNames.length > 0 &&
                                            (!personnelNames[0].lastName ||
                                                !personnelNames[0].firstName))
                                    }
                                    onClick={acceptReviewHandler}
                                >
                                    Accept Consent
                                </PrimaryButtonStyled>
                            )}
                        </div>
                    </CommentContent>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <EditPersonnelNameSection>
                        {personnel.personnelType.type === PersonnelTypes.CLUB && !readOnly && (
                            <Row>
                                <NameLabel onClick={addPersonnelNamesHandler}>
                                    edit personnel name
                                </NameLabel>
                            </Row>
                        )}

                        {!readOnly &&
                            personnelNames &&
                            personnelNames.map((personnelName: any, index: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={index}>
                                    <Row className="no-gutters my-1">
                                        <Col md={4} className="mr-2">
                                            <BigsInput
                                                placeholder="first name (required)"
                                                onChange={event => {
                                                    personnelNameChangeHandler(
                                                        index,
                                                        "firstName",
                                                        event.target.value,
                                                    );
                                                }}
                                                value={personnelName.firstName}
                                            />
                                        </Col>
                                        <Col md={3} className="mr-2">
                                            <BigsInput
                                                placeholder="middle"
                                                onChange={event => {
                                                    personnelNameChangeHandler(
                                                        index,
                                                        "middleName",
                                                        event.target.value,
                                                    );
                                                }}
                                                value={personnelName.middleName}
                                            />
                                        </Col>
                                        <Col md={4} className="mr-2">
                                            <BigsInput
                                                placeholder="last name (required)"
                                                onChange={event => {
                                                    personnelNameChangeHandler(
                                                        index,
                                                        "lastName",
                                                        event.target.value,
                                                    );
                                                }}
                                                value={personnelName.lastName}
                                            />
                                        </Col>
                                        <Col>
                                            <DeleteIcon
                                                onClick={() => removePersonnelNameRow(index)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="no-gutters my-1">
                                        <Col md={4} className="mr-2">
                                            <BigsSelect
                                                placeholder="Suffix"
                                                onChange={selected => {
                                                    suffixChangeHandler(index, selected);
                                                }}
                                                options={suffixOptions}
                                                value={personnelName.suffix}
                                            />
                                        </Col>
                                        <Col md={7}>
                                            <BigsInput
                                                placeholder="nickname"
                                                onChange={event => {
                                                    personnelNameChangeHandler(
                                                        index,
                                                        "nickName",
                                                        event.target.value,
                                                    );
                                                }}
                                                value={personnelName.nickName}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                    </EditPersonnelNameSection>
                    <AdditionalNameSection>
                        {!readOnly && (
                            <Row>
                                <NameLabel onClick={addAliasHandler}>
                                    add additional search name
                                </NameLabel>
                            </Row>
                        )}

                        {!readOnly &&
                            aliasNameList &&
                            aliasNameList.map((name: any, index: number) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Row key={index} className="no-gutters my-1">
                                    <Col md={4} className="mr-2">
                                        <BigsInput
                                            disabled={!currentJurisdiction.reviewPending}
                                            placeholder="first name"
                                            onChange={event => {
                                                aliasNameChangeHandler(
                                                    index,
                                                    "firstName",
                                                    event.target.value,
                                                );
                                            }}
                                            value={name.firstName}
                                        />
                                    </Col>
                                    <Col md={3} className="mr-2">
                                        <BigsInput
                                            disabled={!currentJurisdiction.reviewPending}
                                            placeholder="middle"
                                            onChange={event => {
                                                aliasNameChangeHandler(
                                                    index,
                                                    "middleName",
                                                    event.target.value,
                                                );
                                            }}
                                            value={name.middleName}
                                        />
                                    </Col>
                                    <Col md={4} className="mr-2">
                                        <BigsInput
                                            disabled={!currentJurisdiction.reviewPending}
                                            placeholder="last name"
                                            onChange={event => {
                                                aliasNameChangeHandler(
                                                    index,
                                                    "lastName",
                                                    event.target.value,
                                                );
                                            }}
                                            value={name.lastName}
                                        />
                                    </Col>
                                    <Col>
                                        {!currentJurisdiction.reviewPending ? (
                                            <DisabledDeleteIcon />
                                        ) : (
                                            <DeleteIcon onClick={() => removeAliasRow(index)} />
                                        )}
                                    </Col>
                                </Row>
                            ))}
                    </AdditionalNameSection>
                    <div>
                        {!readOnly && (
                            <Row>
                                <NameLabel onClick={addAdditionalJurisdictionHandler}>
                                    add additional jurisdiction
                                </NameLabel>
                            </Row>
                        )}
                        {!readOnly && addAdditionalJurisidictionVisible && (
                            <Row className="no-gutters my-1">
                                <Col md={4} className="mr-2">
                                    <BigsSelect
                                        value={additionalJurisdiction}
                                        isDisabled={false}
                                        onChange={(jurisdictionOpt: any) => {
                                            additionalJurisdictionOnChange(jurisdictionOpt);
                                        }}
                                        options={countryOptions}
                                        placeholder="select a jurisdiction"
                                        portal={document.body}
                                    />
                                </Col>
                                <Col md={3} className="mr-2">
                                    {/* Send Consent Form button or text field for other */}
                                    {otherJurisdictionVisible ? (
                                        <BigsInput
                                            placeholder="what country?"
                                            onChange={event => {
                                                setOtherJurisdiction(event.target.value);
                                            }}
                                            value={otherJurisdiction}
                                            height={36}
                                        />
                                    ) : (
                                        <div style={{ marginTop: "6px" }}>
                                            <PrimaryButtonStyled
                                                width={150}
                                                height={26}
                                                disabled={
                                                    !(additionalJurisdiction as {
                                                        label: string;
                                                        value: string;
                                                    })?.value
                                                }
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    sendAdditionalConsentFormFn();
                                                }}
                                            >
                                                Send Consent Form
                                            </PrimaryButtonStyled>
                                        </div>
                                    )}
                                </Col>
                                <Col md={4} className="mr-2">
                                    {/* Add jurisdiction button if other country is filled out */}
                                    {otherJurisdictionVisible ? (
                                        <div style={{ marginTop: "3px" }}>
                                            <PrimaryButtonStyled
                                                width={127}
                                                height={26}
                                                disabled={!otherJurisdiction}
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    addOtherJurisdiction();
                                                }}
                                            >
                                                Add Jurisdiction
                                            </PrimaryButtonStyled>
                                        </div>
                                    ) : null}
                                </Col>
                                <Col />
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        </Review>
    );
};

export default ReviewTab;
