import React, { createContext, useContext, useReducer } from "react";

interface RosterSettings {
    id: number;
    certifyDate: string;
    displayCountdown: boolean;
    infoIconText: string;
    bgCheckProceduresText: string;
    loadedSettings: boolean;
}

interface NotificationSettings {
    id: number;
}

interface OtherSettings {
    id: number;
    questionnaireUrlExpireDays: string;
}

interface UmpireSettings {
    id: number;
    certifyDate: string;
    displayCountdown: boolean;
    displaySubmitButton: boolean;
}

interface SettingsContextType {
    rosterSettings: RosterSettings;
    dispatchRoster: React.Dispatch<any>;
    notificationSettings: NotificationSettings;
    dispatchNotification: React.Dispatch<any>;
    otherSettings: OtherSettings;
    dispatchOther: React.Dispatch<any>;
    umpireSettings: UmpireSettings;
    dispatchUmpire: React.Dispatch<any>;
}

const SettingsContext = createContext<SettingsContextType>({
    rosterSettings: null,
    dispatchRoster: null,
    notificationSettings: null,
    dispatchNotification: null,
    otherSettings: null,
    dispatchOther: null,
    umpireSettings: null,
    dispatchUmpire: null,
});

const rosterInitialState = {
    certifyDate: "",
    id: 0,
    displayCountdown: false,
    infoIconText: "",
    bgCheckProceduresText: "",
    loadedSettings: false,
};

const notificationInitialState = {
    id: 0,
};

const otherInitialState = {
    id: 0,
    questionnaireUrlExpireDays: "30",
};

const umpireInitialState = {
    id: 0,
    certifyDate: "",
    displayCountdown: false,
    displaySubmitButton: false,
};

const rosterReducer = (state: RosterSettings, action: any) => {
    switch (action.type) {
        case "SET_ROSTER_SETTINGS":
            return action.settings;
        case "UPDATE_CERTIFY_DATE":
            return { ...state, certifyDate: action.dateTime ? action.dateTime.toISOString() : "" };
        case "UPDATE_DISPLAY_COUNTDOWN":
            return { ...state, displayCountdown: action.checked };
        case "SET_INFO_ICON_TEXT":
            return { ...state, infoIconText: action.infoIconText };
        case "SET_BG_CHECK_PROCEDURES_TEXT":
            return { ...state, bgCheckProceduresText: action.bgCheckProceduresText };
        default:
            return state;
    }
};

const umpireReducer = (state: UmpireSettings, action: any) => {
    switch (action.type) {
        case "SET_UMPIRE_SETTINGS":
            return action.settings;
        case "UPDATE_CERTIFY_DATE":
            return { ...state, certifyDate: action.dateTime ? action.dateTime.toISOString() : "" };
        case "UPDATE_DISPLAY_COUNTDOWN":
            return { ...state, displayCountdown: action.checked };
        case "UPDATE_SUBMIT_BUTTON":
            return { ...state, displaySubmitButton: action.checked };
        default:
            return state;
    }
};

const notificationReducer = (state: NotificationSettings, action: any) => {
    switch (action.type) {
        default:
            return state;
    }
};

const otherReducer = (state: OtherSettings, action: any) => {
    switch (action.type) {
        case "SET_OTHER_SETTINGS":
            return action.settings;
        case "UPDATE_QUESTIONNAIRE_URL_EXPIRE_DAYS":
            return { ...state, questionnaireUrlExpireDays: action.days };
        default:
            return state;
    }
};

const SettingsContextProvider: React.FC = ({ children }) => {
    const [rosterSettings, dispatchRoster] = useReducer(rosterReducer, rosterInitialState);
    const [notificationSettings, dispatchNotification] = useReducer(
        notificationReducer,
        notificationInitialState,
    );
    const [otherSettings, dispatchOther] = useReducer(otherReducer, otherInitialState);
    const [umpireSettings, dispatchUmpire] = useReducer(umpireReducer, umpireInitialState);

    return (
        <SettingsContext.Provider
            value={{
                rosterSettings,
                dispatchRoster,
                notificationSettings,
                dispatchNotification,
                otherSettings,
                dispatchOther,
                umpireSettings,
                dispatchUmpire,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

const useSettings = (): SettingsContextType => {
    const context = useContext<SettingsContextType>(SettingsContext);
    if (context === undefined) {
        throw new Error(`useSetting must be used within a SettingsContextProvider`);
    }

    return context;
};

export { SettingsContextProvider, useSettings };
