import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { AdminPersonnel } from "./AdminPersonnelComponent";
import { BestTable } from "best-common-react";
import MenuFormatter from "./components/MenuFormatter";
import styled from "styled-components";
import AdminRosterTableNameFormatterWithStatus from "./components/NameFormatterWithStatus";
import { DateUtil } from "../../util/DateUtil";
import { useWindowDimensions } from "../../customHooks/useWindowDimensions";
import loadingGif from "../../assets/loading.gif";
import { useSelectedPersonnel } from "../../contexts/SelectedPersonnelContext";
import { useAdminSideMenu } from "../../contexts/AdminSideMenuContext";
import { useGlobalModal } from "../../contexts/GlobalModalContext";
import BigsLoadingBlocker from "../common/BigsLoadingBlocker";
import { useLoading } from "../../contexts/LoadingContext";
import { BigsBestTable } from "../elements/BigsBestTable";
import ReactOverflowTooltip from "react-overflow-tooltip";

type AdminPersonnelTableProps = {
    dataToDisplay: AdminPersonnel[];
    setShowAdminBulkOperations(arg1: boolean): void;
    expandCallback(index: number, value: any, expanded: boolean): void;
    expandAllCallback(personnel: any): void;
    sortFilters: { col: string; dir: string };
    setSortFilters: Dispatch<SetStateAction<{ col: string; dir: string }>>;
    setShowAdminBulkCompleteOperation(arg1: boolean): void;
    setShowAdminBulkMoveToInProgressOperation(arg1: boolean): void;
};

type FormatterProps = {
    row: AdminPersonnel;
};

const RowTextStyle = styled.div.attrs(() => ({}))`
    font-size: 14px;
`;

const SubRowTextStyle = styled.div.attrs(() => ({}))`
    font-size: 12px;
`;

const ClickableSubRowTextStyle = styled.div.attrs(() => ({}))`
    font-size: 12px;
    color: var(--blue);
    cursor: pointer;
`;

const LoadingGif = styled.div.attrs(() => ({
    style: {
        backgroundImage: `url(${loadingGif})`,
    },
}))`
    width: 35px;
    height: 35px;
    background-size: cover;
    background-position: center;
`;

export const TitleFormatter: React.FC<FormatterProps> = ({ row }) => {
    if (row.isSubRow) {
        if (
            row.status?.toLowerCase().includes("send") ||
            row.status?.toLowerCase().includes("sent")
        ) {
            const date = (
                <SubRowTextStyle>
                    {DateUtil.formatDate(row.createdDate, "MM/DD/YYYY")}
                </SubRowTextStyle>
            );
            const expiration = row.expirationDate ? row.expirationDate : null;
            return (
                <ReactOverflowTooltip title={date + " " + expiration}>
                    <div className="d-flex">
                        {date}
                        <div className="ml-1" style={{ color: "red" }}>
                            {expiration}
                        </div>
                    </div>
                </ReactOverflowTooltip>
            );
        } else {
            return null;
        }
    } else {
        return (
            <>
                <ReactOverflowTooltip title={row.title}>
                    <RowTextStyle>{row.title}</RowTextStyle>
                </ReactOverflowTooltip>
            </>
        );
    }
};

export const OrgFormatter: React.FC<FormatterProps> = ({ row }) => {
    if (!row.isSubRow) {
        return (
            <ReactOverflowTooltip title={row.affiliation?.orgAbbreviation}>
                <RowTextStyle>{row.affiliation?.orgAbbreviation}</RowTextStyle>
            </ReactOverflowTooltip>
        );
    } else {
        return null;
    }
};

export const TierFormatter: React.FC<FormatterProps> = ({ row }) => {
    if (!row.isSubRow) {
        return <RowTextStyle>{row.tier}</RowTextStyle>;
    } else {
        return null;
    }
};

export const EmailFormatter: React.FC<FormatterProps> = ({ row }) => {
    return (
        <ReactOverflowTooltip title={row.email || ""}>
            <RowTextStyle>{row.email}</RowTextStyle>
        </ReactOverflowTooltip>
    );
};

const AdminRosterTableColumns = [
    {
        key: "",
        name: "",
        width: 40,
        formatter: MenuFormatter,
    },
    {
        key: "lastName",
        name: "Name",
        sortable: true,
        formatter: AdminRosterTableNameFormatterWithStatus,
        width: 225,
    },
    {
        key: "title",
        name: "Title",
        sortable: true,
        formatter: TitleFormatter,
        width: 200,
    },
    {
        key: "org",
        name: "Club",
        sortable: true,
        formatter: OrgFormatter,
        width: 75,
    },
    {
        key: "affiliateNameLevel",
        name: "Affiliate",
        sortable: true,
        width: 250,
    },
    {
        key: "nickName",
        name: "Nickname",
        sortable: true,
        width: 150,
    },
    {
        key: "email",
        name: "Email",
        sortable: true,
        formatter: EmailFormatter,
        width: 300,
    },
];

const AdminPersonnelTable: React.FC<AdminPersonnelTableProps> = ({
    dataToDisplay,
    setShowAdminBulkOperations,
    expandCallback,
    expandAllCallback,
    sortFilters,
    setSortFilters,
    setShowAdminBulkCompleteOperation,
    setShowAdminBulkMoveToInProgressOperation,
}) => {
    const [data, setData] = useState<AdminPersonnel[]>([...dataToDisplay]);
    const { height } = useWindowDimensions();
    const { setSelectedPersonnel, setInitialSelectedPersonnel } = useSelectedPersonnel();
    const { selectedMenuOptions } = useAdminSideMenu();
    const { isLoading } = useLoading();
    const [expandable, setExpandable] = useState(true);

    // This is used to "unselect" personnel that are locked to prevent duplicate accepts/exempts
    const deselectLockedPersonnel = (dataToFormat: AdminPersonnel[]): AdminPersonnel[] => {
        dataToFormat.forEach(personnel => {
            if (personnel && personnel.locked) {
                personnel.isSelected = false;
            }
        });

        return dataToFormat;
    };

    const isSelected = (personnel: AdminPersonnel) => personnel.isSelected;

    const showBulkOperations = () => {
        const selectedMenuStatuses = selectedMenuOptions.map(opt => opt.text);
        if (selectedMenuOptions.length === 1 && selectedMenuStatuses.includes("Pending BOC")) {
            setShowAdminBulkOperations(data.some(isSelected));
        } else if (selectedMenuOptions.length === 1 && selectedMenuStatuses.includes("Complete")) {
            setShowAdminBulkCompleteOperation(data.some(isSelected));
        } else if (selectedMenuOptions.length === 1 && selectedMenuStatuses.includes("Rollover")) {
            setShowAdminBulkMoveToInProgressOperation(data.some(isSelected));
        } else {
            setShowAdminBulkOperations(false);
        }
    };

    useEffect(() => {
        setData([...deselectLockedPersonnel(dataToDisplay)]);

        if (dataToDisplay.length === 0) {
            setInitialSelectedPersonnel([]);
            setSelectedPersonnel([]);
        }
    }, [dataToDisplay]);

    useEffect(() => {
        showBulkOperations();
    }, [data]);

    useEffect(() => {
        if (
            selectedMenuOptions.length === 1 &&
            selectedMenuOptions.find(opt => opt.text === "Pending BOC")
        ) {
            setExpandable(false);
        } else {
            setExpandable(true);
        }
    }, [selectedMenuOptions]);

    const rowsSelectedToggleHandler = (rows: any[]) => {
        rows.forEach(row => {
            const personnel = data.find(personnel => personnel.id === row.id);
            personnel.isSelected = !personnel.isSelected;
        });
        showBulkOperations();
        setData([...data]);
        setInitialSelectedPersonnel([...data.filter(row => row.isSelected)]);
        setSelectedPersonnel([...data.filter(row => row.isSelected)]);
    };

    const rowStylesGetter = (index: number, value: any) => {
        if (value.locked || value.disabled) {
            return {
                backgroundColor: "var(--snow)",
                color: "var(--blue-grey)",
            };
        }
    };

    const subRowStyle = (index: number, value: any, subRowIndex: number, subRowValue: any) => {
        return {
            backgroundColor: "var(--snow)",
            color: "#666666",
            fontSize: "12px",
            height: "26px",
            marginLeft: "2px",
        };
    };

    return (
        <>
            <>
                <>
                    {isLoading ? (
                        <BigsLoadingBlocker />
                    ) : (
                        <>
                            {!data || data.length === 0 ? (
                                <>
                                    <div
                                        style={{
                                            fontFamily: "Helvetica",
                                            fontStyle: "oblique",
                                            color: "#92a5ba",
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        No personnel to display
                                    </div>
                                </>
                            ) : (
                                <>
                                    <span
                                        style={{
                                            fontFamily: "Helvetica",
                                            fontStyle: "oblique",
                                            color: "#92a5ba",
                                            fontSize: "0.875rem",
                                        }}
                                    >
                                        {data.length} personnel
                                    </span>
                                </>
                            )}
                        </>
                    )}
                </>
                <>
                    {data && data.length !== 0 ? (
                        <BigsBestTable>
                            <BestTable
                                data={data}
                                columns={AdminRosterTableColumns}
                                headerHeight={30}
                                sortColumn={sortFilters.col !== "" ? sortFilters.col : null}
                                sortDirection={sortFilters.dir !== "" ? sortFilters.dir : null}
                                sortFunction={(col: string, dir: string) => {
                                    if (dir === "NONE") {
                                        dir = "ASC";
                                        col = sortFilters.col;
                                    }
                                    setSortFilters({ col, dir });
                                }}
                                maxTableHeight={height - 220}
                                rowHeight={40}
                                rowStylesGetter={rowStylesGetter}
                                subRowStylesGetter={subRowStyle}
                                rowSelection={{
                                    showCheckbox: true,
                                    onRowsSelected: (rows: any) => {
                                        rowsSelectedToggleHandler(rows);
                                    },
                                    onRowsDeselected: (rows: any) => {
                                        rowsSelectedToggleHandler(rows);
                                    },
                                    selectBy: {
                                        isSelectedKey: "isSelected",
                                    },
                                }}
                                rowDisabledKey="locked"
                                rowExpansion={{
                                    expandAllCallback: expandAllCallback,
                                    expandCallback: expandCallback,
                                    expandable: expandable,
                                    expandedKey: "expanded",
                                }}
                            />
                        </BigsBestTable>
                    ) : null}
                </>
            </>
        </>
    );
};

export default AdminPersonnelTable;
