import React from "react";
import { DatePickerInput } from "best-common-react";
import styled from "styled-components";
import { useQuestionnaire } from "../../../contexts/QuestionnaireContext";
import { QuestionDef } from "../QuestionDefs";
import Moment from "moment";

type DateInputQuestionProps = {
    questionDef: QuestionDef;
};

const DateInputContent = styled.div`
    margin: 20px 0px 0px 0px;
    width: 350px;
`;

const DateSelect = styled.div`
    min-width: 300px;
    & div.input-group-text {
        justify-content: center;
    }
    
    & div.react-datepicker {
        display: flex;
`;

const DateInputQuestion: React.FC<DateInputQuestionProps> = ({ questionDef }) => {
    const { questionnaire, dispatchQuestionnaire } = useQuestionnaire();
    const dateChangeHandler = (selectedDate: any) => {
        dispatchQuestionnaire({ type: questionDef.type, id: questionDef.id, date: selectedDate });
    };
    return (
        questionDef.show(questionnaire) && (
            <DateInputContent>
                <label className={questionDef.required ? "required" : ""}>
                    {questionnaire.language.value && questionnaire.language.value === "spanish"
                        ? questionDef.displayTextEs
                        : questionDef.displayTextEn}
                </label>
                <DateSelect>
                    <DatePickerInput
                        onChange={dateChangeHandler}
                        value={questionnaire[questionDef.id]}
                        showTimeSelect={false}
                        popperPlacement="bottom-start"
                        minDate={Moment(new Date("1/1/1930").valueOf())}
                        maxDate={Moment(new Date().valueOf())}
                    />
                </DateSelect>
            </DateInputContent>
        )
    );
};

export default DateInputQuestion;
