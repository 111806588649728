import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import styled from "styled-components";
import { BigsCheckBox } from "../FormElements";
import { getPersonnelBgcJurisdictionsByCompletedStatus } from "../../../api/PersonnelApi";
import FormUtil from "../../../util/FormUtil";

const StyledBody = styled.div.attrs(() => ({
    className: "mt-4 ml-4",
}))`
    height: 260px;
`;

type JurisdictionCheckType = {
    jurisdiction: string;
    checked: boolean;
};

type ResendBgcConsentFormBody = {
    bgcJurisdictions: Array<JurisdictionCheckType>;
    setBgcJurisdictions: Dispatch<SetStateAction<Array<JurisdictionCheckType>>>;
    personnelId: number;
};

const ResendBgcConsentFormBody: React.FC<ResendBgcConsentFormBody> = ({
    bgcJurisdictions,
    setBgcJurisdictions,
    personnelId,
}) => {
    const checkOnChangeHandler = (
        jurisdictionCheck: {
            jurisdiction: string;
            checked: boolean;
        },
        index: number,
    ) => {
        const newJursChecks = [...bgcJurisdictions];
        newJursChecks[index].checked = !newJursChecks[index].checked;
        setBgcJurisdictions([...newJursChecks]);
    };

    useEffect(() => {
        if (personnelId > -1) {
            getPersonnelBgcJurisdictionsByCompletedStatus(personnelId, false).then(
                (apiBgcJurisdictions: any) => {
                    const jurisdictions: { jurisdiction: any; checked: boolean }[] = [];
                    apiBgcJurisdictions?.forEach((bgcJurisdiction: any) => {
                        jurisdictions.push({
                            jurisdiction:
                                bgcJurisdiction?.jurisdiction === "usPuerto"
                                    ? "United States"
                                    : bgcJurisdiction?.jurisdiction,
                            checked: false,
                        });
                        setBgcJurisdictions([...jurisdictions]);
                    });
                },
            );
        }

        return () => {
            setBgcJurisdictions([]);
        };
    }, [personnelId]);

    return (
        <StyledBody>
            {bgcJurisdictions?.map((bgcJurisdiction, index) => {
                return (
                    <BigsCheckBox
                        id={bgcJurisdiction.jurisdiction + "checkbox"}
                        label={FormUtil.capitalize(bgcJurisdiction.jurisdiction)}
                        checked={bgcJurisdiction.checked}
                        onChange={() => {
                            checkOnChangeHandler(bgcJurisdiction, index);
                        }}
                    />
                );
            })}
        </StyledBody>
    );
};

type ResendBgcConsentFormFooter = {
    bgcJurisdictions: Array<JurisdictionCheckType>;
    personnelId: number;
    onResendBgcCallback(
        personnelId: number,
        jurisdiction: string,
        eventName: string,
        successMessage: string,
    ): void;
};

const ResendBgcConsentFormFooter: React.FC<ResendBgcConsentFormFooter> = ({
    bgcJurisdictions,
    onResendBgcCallback,
    personnelId,
}) => {
    const [resendDisabled, setResendDisabled] = useState<boolean>(true);
    const { closeModal } = useGlobalModal();

    useEffect(() => {
        let disabled = true;
        bgcJurisdictions?.forEach(bgcJurisdiction => {
            if (bgcJurisdiction.checked) {
                disabled = false;
            }
        });
        setResendDisabled(disabled);
    }, [bgcJurisdictions]);

    const onResendClicked = () => {
        const checkedJurisdictions = bgcJurisdictions?.filter(
            bgcJurisdiction => bgcJurisdiction.checked,
        );
        checkedJurisdictions.forEach(bgcJurisdiction => {
            onResendBgcCallback(
                personnelId,
                bgcJurisdiction?.jurisdiction === "United States"
                    ? "usPuerto"
                    : bgcJurisdiction.jurisdiction,
                "Resend BGC Consent Form",
                "Sent",
            );
        });
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                width={85}
                height={26}
                disabled={resendDisabled}
                onClick={event => {
                    event.stopPropagation();
                    onResendClicked();
                    closeModal();
                }}
            >
                Resend
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={80}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ResendBgcConsentFormBody.displayName = "BodyContent";
ResendBgcConsentFormFooter.displayName = "FooterContent";

export { ResendBgcConsentFormBody, ResendBgcConsentFormFooter };
