import React from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { useAlert } from "../../../contexts/AlertsContext";

const BodyContent = styled.div`
    text-align: left;
    margin: 1rem;
    padding: 0.5rem;
    height: 220px;
    color: var(--slate);
`;

type ResendDtConsentFormModalBodyProps = {
    personnelId: number;
    resendClickHandler: Function;
};

const ResendDtConsentFormModalBody: React.FC<ResendDtConsentFormModalBodyProps> = ({
    personnelId,
    resendClickHandler,
}) => {
    return <BodyContent>Are you sure you want to resend the drug test consent form?</BodyContent>;
};

const ResendDtConsentFormModalFooter: React.FC<ResendDtConsentFormModalBodyProps> = ({
    personnelId,
    resendClickHandler,
}) => {
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();

    const resendDtConsentForm = () => {
        resendClickHandler(personnelId, "country", "Resend DT Consent Form", "Sent");
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                width={142}
                height={26}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    resendDtConsentForm();
                    closeModal();
                }}
            >
                Resend
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ResendDtConsentFormModalBody.displayName = "BodyContent";
ResendDtConsentFormModalFooter.displayName = "FooterContent";

export { ResendDtConsentFormModalBody, ResendDtConsentFormModalFooter };
