import React from "react";
import { AlertHolder } from "best-common-react";
import styled from "styled-components";
import { useAlertContext } from "../../contexts/AlertsContext";

const BigsAlertWrapper = styled.div`
    > div {
        > div {
            display: table;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            width: fit-content;
        }
    }
`;

const BigsAlerts = () => {
    const { alerts, removeAlert } = useAlertContext();

    return (
        <BigsAlertWrapper id="bigs-alerts">
            <AlertHolder
                fullWidth={false}
                alerts={alerts}
                onRemove={(id: number) => {
                    if (id) {
                        removeAlert(id);
                    }
                }}
            />
        </BigsAlertWrapper>
    );
};

export default BigsAlerts;
