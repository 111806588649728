import React from "react";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import styled from "styled-components";
import { useAlert } from "../../../contexts/AlertsContext";
import { updatePersonnelStatus } from "../../../api/PersonnelApi";

type RemovePersonnelBodyProps = {
    personnel: any;
    callback: Function;
};

const BodyContent = styled.div.attrs(() => ({
    className: "mt-4 ml-4",
}))`
    text-align: left;
    margin-bottom: 200px;
`;

const TopTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 16px;
        color: var(--slate);
        line-height: 24px;
        text-style: Helvetica;
    }
`;

const BottomTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 12px;
        color: var(--slate);
        text-style: Helvetica-Light;
        font-weight: 300;
    }
`;

const RemovePersonnelBody: React.FC<RemovePersonnelBodyProps> = ({ personnel }) => {
    return (
        <BodyContent>
            <TopTextStyle>
                Are you sure you want to remove {personnel.lastName}, {personnel.firstName}?
            </TopTextStyle>
            <BottomTextStyle>
                The personnel will be moved to Inactive. Personnel can be added back to a Roster
                from Inactive.
            </BottomTextStyle>
        </BodyContent>
    );
};

type RemovePersonnelFooterProps = {
    personnel: {
        id: number;
        status: string;
        affiliation: any;
    };
    closeModal: Function;
    callback: Function;
};

const RemovePersonnelFooter: React.FC<RemovePersonnelFooterProps> = ({
    personnel,
    closeModal,
    callback,
}) => {
    const showAlert = useAlert();
    const removePersonnelHandler = () => {
        updatePersonnelStatus(personnel.id, "INACTIVE", personnel.affiliation.teamId)
            .then(() => {
                if (callback && typeof callback === "function") {
                    callback();
                }
                showAlert("Removed");
            })
            .catch(() => {
                showAlert("Error removing personnel from roster", "danger");
            });
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                height={26}
                width={180}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                    removePersonnelHandler();
                }}
            >
                Remove From Roster
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={80}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

RemovePersonnelBody.displayName = "BodyContent";
RemovePersonnelFooter.displayName = "FooterContent";

export { RemovePersonnelBody, RemovePersonnelFooter };
