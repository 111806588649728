const FormUtil = {
    // Used to determine if something is defined. Useful for checking if required fields have been entered
    isDefined: (obj: { hasOwnProperty: (arg0: string) => any; label: any }) => {
        if (obj === null || obj === undefined) {
            return false;
        } else if (obj.hasOwnProperty("value") && obj.hasOwnProperty("label")) {
            // Object is a dropdown item, so we want to check if it has a value entered
            // We do a truthy/falsy comparison because when a dropdown is "Blank"
            // There is still an object selected, usually
            // { value: 0, label: "" }
            // So value and label will both be falsy
            // We care about not having a blank label though, because the ids (value) are generally unknown
            return !!obj.label;
        } else {
            // We have a regular object
            return !!obj;
        }
    },

    isAnyDefined: (objs: any[]) => {
        if (objs === null || objs === undefined) {
            return false;
        } else {
            return objs.some(FormUtil.isDefined);
        }
    },

    isAllDefined: (objs: any[]) => {
        if (objs === null || objs === undefined) {
            return false;
        } else {
            return objs.every(FormUtil.isDefined);
        }
    },

    areAllPropertiesDefined: (obj: { [x: string]: any }) => {
        let result = true;
        if (obj !== null || obj !== undefined) {
            Object.keys(obj).forEach(prop => {
                if (!FormUtil.isDefined(obj[prop])) {
                    result = false;
                }
            });
        } else {
            result = false;
        }

        return result;
    },

    isValidEmail: (email: string) => {
        // eslint-disable-next-line no-useless-escape
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    },

    validateObject: (objToValidate: { [x: string]: any }, objKeysList: any[]) => {
        let result = true;
        if (objToValidate) {
            objKeysList.forEach(key => {
                if (!FormUtil.isDefined(objToValidate[key])) {
                    result = false;
                }
            });
        } else {
            result = false;
        }

        return result;
    },

    compareLikeObjects: (obj1: any, obj2: any, keysList: any[]) => {
        let result = true;
        keysList.forEach(key => {
            if (obj1[key] !== obj2[key]) {
                result = false;
            }
        });
        return result;
    },

    validateFormObject: (objToValidate: { [x: string]: any }, objKeysList: any[]) => {
        let result = true;
        if (objToValidate) {
            objKeysList.forEach(key => {
                if (!FormUtil.isDefined(objToValidate[key]) || objToValidate[key].trim() === "") {
                    result = false;
                }
            });
        } else {
            result = false;
        }

        return result;
    },

    capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },

    formatJurisdiction(jurisdiction: string) {
        if (jurisdiction === "usPuerto") {
            return "United States";
        }

        if (jurisdiction === "dominicanRepublic") {
            return "Dominican Republic";
        }

        if (jurisdiction === "colombia") {
            return "Colombia";
        }

        if (jurisdiction === "japan") {
            return "Japan";
        }

        if (jurisdiction === "venezuela") {
            return "Venezuela";
        }

        if (jurisdiction === "other") {
            return "Other";
        }

        return FormUtil.capitalize(jurisdiction);
    },

    isValidDropdownField(field: any) {
        if (!field.id && !isNaN(field)) {
            return true;
        }
        if (field.id == 0) {
            return false;
        }
    },
};

export default FormUtil;
