import React, { SetStateAction, Dispatch, useState, useEffect } from "react";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { BigsCheckBox } from "../FormElements";
import styled from "styled-components";
import { getJurisdictionsForSubmitToBGC } from "../../../api/PersonnelApi";
import { useAlert } from "../../../contexts/AlertsContext";
import FormUtil from "../../../util/FormUtil";
import { useLoading } from "../../../contexts/LoadingContext";
import BigsLoadingBlocker from "../../common/BigsLoadingBlocker";

type JurisdictionCheckType = {
    jurisdiction: string;
    checked: boolean;
};

type SubmitToBgcBodyProps = {
    personnelId: number;
    jurisdictionChecks: Array<JurisdictionCheckType>;
    setJurisdictionChecks: Dispatch<SetStateAction<Array<JurisdictionCheckType>>>;
};

const StyledBody = styled.div.attrs(() => ({
    className: "mt-4 ml-4",
}))`
    height: 260px;
`;

const SubmitToBgcBody: React.FC<SubmitToBgcBodyProps> = ({
    personnelId,
    jurisdictionChecks,
    setJurisdictionChecks,
}) => {
    const { isLoading } = useLoading();
    const showAlert = useAlert();
    const checkOnChangeHandler = (
        jurisdictionCheck: {
            jurisdiction: string;
            checked: boolean;
        },
        index: number,
    ) => {
        const newJursChecks = [...jurisdictionChecks];
        newJursChecks[index].checked = !jurisdictionCheck.checked;
        setJurisdictionChecks([...newJursChecks]);
    };

    useEffect(() => {
        getJurisdictionsForSubmitToBGC(personnelId)
            .then(response => {
                if (response.length > 0) {
                    const newJurisdictionChecks: JurisdictionCheckType[] = [];
                    response.forEach((jurisdictionRdyForBgc: string) => {
                        newJurisdictionChecks.push({
                            jurisdiction: jurisdictionRdyForBgc,
                            checked: false,
                        });
                    });
                    setJurisdictionChecks([...newJurisdictionChecks]);
                }
            })
            .catch(() => {
                showAlert(
                    "Error retrieving jurisdictions eligible for Submit to BGC, Please contact BIGS Systems.",
                    "danger",
                );
            });
    }, []);

    return (
        <StyledBody>
            {isLoading ? (
                <BigsLoadingBlocker />
            ) : (
                <>
                    {jurisdictionChecks?.map((jurisdictionCheck, index) => {
                        return (
                            <BigsCheckBox
                                id={jurisdictionCheck.jurisdiction + "checkbox"}
                                label={FormUtil.formatJurisdiction(jurisdictionCheck.jurisdiction)}
                                checked={jurisdictionCheck.checked}
                                onChange={() => {
                                    checkOnChangeHandler(jurisdictionCheck, index);
                                }}
                            />
                        );
                    })}
                </>
            )}
        </StyledBody>
    );
};

type SubmitToBgcFooterProps = {
    jurisdictionChecks: Array<any>;
    personnelId: number;
    onSubmitCallback(
        personnelId: number,
        jurisdiction: string,
        eventName: string,
        successMessage?: string,
    ): void;
    onCancelCallback(): void;
    modalHeaderText: string;
};

const SubmitToBgcFooter: React.FC<SubmitToBgcFooterProps> = ({
    jurisdictionChecks,
    personnelId,
    onSubmitCallback,
    onCancelCallback,
    modalHeaderText,
}) => {
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const { closeModal, dispatch } = useGlobalModal();

    useEffect(() => {
        if (modalHeaderText) {
            dispatch({
                type: "updateModalProperties",
                headerText: modalHeaderText,
            });
        }

        let disabled = true;
        jurisdictionChecks?.forEach(jurisdictionCheck => {
            if (jurisdictionCheck.checked) {
                disabled = false;
            }
        });
        setSubmitDisabled(disabled);
    }, [jurisdictionChecks]);

    const onSubmitHandler = () => {
        const checkedJurisdictions = jurisdictionChecks?.filter(
            jurisdictionCheck => jurisdictionCheck.checked,
        );
        checkedJurisdictions.forEach(checkedJurisdiction => {
            onSubmitCallback(
                personnelId,
                checkedJurisdiction.jurisdiction,
                "Submit to BGC",
                "Submitted",
            );
        });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={82}
                    height={26}
                    disabled={submitDisabled}
                    onClick={event => {
                        onSubmitHandler();
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Submit
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    if (onCancelCallback) {
                        onCancelCallback();
                    } else {
                        closeModal();
                    }
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

SubmitToBgcBody.displayName = "BodyContent";
SubmitToBgcFooter.displayName = "FooterContent";

export { SubmitToBgcBody, SubmitToBgcFooter, JurisdictionCheckType };
