import React, {
    createContext,
    useContext,
    useState,
    SetStateAction,
    Dispatch,
    useEffect,
} from "react";
import { getPersonnelAffiliateCounts } from "../api/PersonnelApi";
import { Personnel } from "../modules/roster/RosterComponent";

type MenuOptionType = {
    id: number;
    amount: number;
    text: string;
};

type AffiliationCountApiResponse = {
    id: number;
    count: number;
    name: string;
    level: string;
};

type SideMenuContext = {
    options: MenuOptionType[];
    setOptions: Dispatch<SetStateAction<MenuOptionType[]>>;
    selectedOption: MenuOptionType;
    setSelectedOption: Dispatch<SetStateAction<MenuOptionType>>;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    highlightedOptions: number[];
    setHighlightedOptions: Dispatch<SetStateAction<number[]>>;
    refreshSideMenuOptions: (orgId: number) => void;
    inactiveSelected: boolean;
    setInactiveSelected: Dispatch<SetStateAction<boolean>>;
    previousOptions: MenuOptionType;
    setPreviousOptions: Dispatch<SetStateAction<MenuOptionType>>;
    previousStatusFilter: any;
    setPreviousStatusFilter: Dispatch<SetStateAction<any>>;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<[] | string>>;
    statusFilter: { label: string; value: string };
    setStatusFilter: Dispatch<SetStateAction<{ label: string; value: string }>>;
};

const SideMenuContext = createContext<SideMenuContext>({
    options: null,
    setOptions: null,
    selectedOption: null,
    setSelectedOption: null,
    disabled: false,
    setDisabled: null,
    highlightedOptions: null,
    setHighlightedOptions: null,
    refreshSideMenuOptions: null,
    inactiveSelected: null,
    setInactiveSelected: null,
    previousOptions: null,
    setPreviousOptions: null,
    previousStatusFilter: null,
    setPreviousStatusFilter: null,
    searchText: null,
    setSearchText: null,
    statusFilter: null,
    setStatusFilter: null,
});

const initialState = {
    options: [{ id: 0, amount: 0, text: "All Personnel" }],
};

const SideMenuProvider: React.FC = ({ children }) => {
    const [selectedOption, setSelectedOption] = useState<MenuOptionType>(initialState.options[0]);
    const [options, setOptions] = useState<MenuOptionType[]>(initialState.options);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [highlightedOptions, setHighlightedOptions] = useState<number[]>([]);
    const [inactiveSelected, setInactiveSelected] = useState(false);
    const [previousOptions, setPreviousOptions] = useState(null);
    const [previousStatusFilter, setPreviousStatusFilter] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [statusFilter, setStatusFilter] = useState({ label: null, value: null });

    const refreshSideMenuOptions = (orgId: number) => {
        getPersonnelAffiliateCounts(orgId)
            .then(response => {
                if (response) {
                    const allActiveCount = response.activeCount;
                    const allInactiveCount = response.inactiveCount;
                    const activeAffiliateList = response.activeAffiliateList;
                    const options = activeAffiliateList.map(
                        (affiliate: AffiliationCountApiResponse) => {
                            return {
                                id: affiliate.id,
                                amount: affiliate.count,
                                text:
                                    affiliate.level === "MLB"
                                        ? affiliate.name + " (Major)"
                                        : affiliate.name + " (" + affiliate.level.trim() + ")",
                            };
                        },
                    );
                    const inactiveOptions = response.inactiveAffiliateList.map(
                        (affiliate: AffiliationCountApiResponse) => {
                            return {
                                id: affiliate.id,
                                amount: affiliate.count,
                                text:
                                    affiliate.level === "MLB"
                                        ? affiliate.name + " (Major)"
                                        : affiliate.name + " (" + affiliate.level.trim() + ")",
                            };
                        },
                    );
                    inactiveOptions.forEach((inactiveOpt: { id: number }) => {
                        if (!options.find((opt: { id: number }) => opt.id === inactiveOpt.id)) {
                            options.push(inactiveOpt);
                        }
                    });
                    options.sort((a: MenuOptionType, b: MenuOptionType) =>
                        a.text.localeCompare(b.text),
                    );
                    options.unshift({
                        id: 0,
                        amount: allActiveCount,
                        text: "All Active Personnel",
                    });
                    options.push({
                        id: -1,
                        amount: allInactiveCount,
                        text: "All Inactive Personnel",
                    });
                    setOptions(options);
                    if (!previousOptions) {
                        setSelectedOption(options[0]);
                    }
                }
            })
            .catch(() => {
                setOptions(initialState.options);
                setSelectedOption(initialState.options[0]);
            });
    };

    useEffect(() => {
        setOptions([]);
    }, []);

    return (
        <SideMenuContext.Provider
            value={{
                options,
                setOptions,
                selectedOption,
                setSelectedOption,
                disabled,
                setDisabled,
                highlightedOptions,
                setHighlightedOptions,
                refreshSideMenuOptions,
                inactiveSelected,
                setInactiveSelected,
                previousOptions,
                setPreviousOptions,
                previousStatusFilter,
                setPreviousStatusFilter,
                searchText,
                setSearchText,
                statusFilter,
                setStatusFilter,
            }}
        >
            {children}
        </SideMenuContext.Provider>
    );
};

const useSideMenu = (): SideMenuContext => {
    const sideMenuContext = useContext<SideMenuContext>(SideMenuContext);
    if (sideMenuContext === undefined) {
        throw new Error(`useSideMenu must be used within a SideMenuProvider`);
    }
    return sideMenuContext;
};

export { SideMenuContext, SideMenuProvider, useSideMenu, MenuOptionType };
