import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { Icon } from "best-common-react";
import { OutlinedButtonStyled } from "./OutlinedButtonStyled";

type DropAreaProps = {
    border: string;
};

const DropArea = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))<DropAreaProps>`
    border-radius: 4px;
    border: ${props => props.border};
    background-color: #ffffff;
    width: 75%;
    height: 36px;
    box-sizing: border-box;
`;

const DropAreaWrapper = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))`
    height: 36px;
    width: 100%;
    margin-bottom: 10px;
    background-color: #ffffff;
`;

type DropAreaTextProps = {
    marginLeft: string;
};

const DropAreaText = styled.span.attrs(() => ({
    className: "",
}))<DropAreaTextProps>`
    font-size: 14px;
    color: var(--blue-grey);
    font-style: italic;
    text-align: left;
    font-weight: 300;
    margin-left: ${props => props.marginLeft};
    margin-right: 9px;
`;

const FileName = styled.div.attrs(() => ({
    className: "mr-1",
}))`
    &&& {
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-size: 14px;
    }
`;

const FileNameHolder = styled.span.attrs(() => ({
    className: "d-flex justify-content-between align-items-center px-2",
}))`
    width: 100%;
`;

type FileBrowserProps = {
    fileTypes?: Array<string>;
    onFileAdd(file: any, fileInfo?: any): void;
    file?: any;
    fileContent?: any;
    fileInfo?: any;
    onFileDelete?(file: any, fileInfo?: any): void;
};

const FileBrowser: React.FC<FileBrowserProps> = ({
    fileTypes,
    onFileAdd,
    file,
    fileContent,
    fileInfo,
    onFileDelete,
}) => {
    const [localFileContent, setLocalFileContent] = useState("");
    const [dropAreaBorder, setDropAreaBorder] = useState("1px dashed var(--blue-grey)");
    const [dropAreaTextMarginLeft, setDropAreaTextMarginLeft] = useState("9px");

    const getFileTypes = () => {
        if (Array.isArray(fileTypes)) {
            return fileTypes.join(", ");
        } else if (typeof fileTypes === "string") {
            return fileTypes;
        } else {
            return null;
        }
    };

    return (
        <div className="dropzone">
            <Dropzone
                accept={getFileTypes()}
                onDrop={(accepted, rejected) => {
                    onFileAdd(accepted, fileInfo);
                }}
            >
                {({ getRootProps, getInputProps, open }) => (
                    <div
                        {...getRootProps()}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <input {...getInputProps()} />
                        <DropAreaWrapper>
                            <DropArea
                                border={dropAreaBorder}
                                onDragOver={() => {
                                    setDropAreaBorder("2px solid #4c8cee");
                                    setDropAreaTextMarginLeft("8px");
                                }}
                                onDragLeave={() => {
                                    setDropAreaBorder("1px dashed var(--blue-grey)");
                                    setDropAreaTextMarginLeft("9px");
                                }}
                            >
                                {file && file.name ? (
                                    <FileNameHolder>
                                        <FileName>{file.name}</FileName>
                                        <Icon
                                            iconName="fa-trash-alt"
                                            onClick={() => onFileDelete(file.id)}
                                            styles={{ size: ".9rem" }}
                                        />
                                    </FileNameHolder>
                                ) : (
                                    <DropAreaText marginLeft={dropAreaTextMarginLeft}>
                                        drop files here or click browse
                                    </DropAreaText>
                                )}
                            </DropArea>
                            <div className="ml-1">
                                <OutlinedButtonStyled
                                    onClick={() => open()}
                                    width={84}
                                    height={26}
                                    disabled={false}
                                >
                                    browse
                                </OutlinedButtonStyled>
                            </div>
                        </DropAreaWrapper>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default FileBrowser;
