import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";
import { AdminPersonnel } from "./AdminPersonnelContext";
import { useAlert } from "./AlertsContext";
import { AcceptPersonnelDtoType } from "../api/PersonnelApi";
import {
    bulkAccept,
    ExemptPersonnelDTO,
    bulkExempt,
    bulkRolloverInProgress,
} from "../api/RosterApi";

type SelectedPersonnelContext = {
    initialSelectedPersonnel: AdminPersonnel[];
    setInitialSelectedPersonnel: Dispatch<SetStateAction<AdminPersonnel[]>>;
    selectedPersonnel: AdminPersonnel[];
    setSelectedPersonnel: Dispatch<SetStateAction<AdminPersonnel[]>>;
    removePersonnel(idToRemove: number): void;
    drugTestOnChange(idToChange: number): void;
    bulkAcceptPersonnel(): void;
    bulkExemptPersonnel(): void;
    bulkRolloverInProgressPersonnel(): void;
    exemptComment: string;
    setExemptComment: Dispatch<SetStateAction<string>>;
};

const SelectedPersonnelContext = createContext<SelectedPersonnelContext>({
    initialSelectedPersonnel: null,
    setInitialSelectedPersonnel: null,
    selectedPersonnel: null,
    setSelectedPersonnel: null,
    removePersonnel: null,
    drugTestOnChange: null,
    bulkAcceptPersonnel: null,
    bulkExemptPersonnel: null,
    exemptComment: null,
    setExemptComment: null,
    bulkRolloverInProgressPersonnel: null,
});

const SelectedPersonnelContextProvider: React.FC = ({ children }) => {
    const [initialSelectedPersonnel, setInitialSelectedPersonnel] = useState([]);
    const [selectedPersonnel, setSelectedPersonnel] = useState([]);
    const [exemptComment, setExemptComment] = useState<string>("");
    const showAlert = useAlert();

    const removePersonnel = (idToRemove: number) => {
        const indexToRemove = selectedPersonnel.findIndex(personnel => personnel.id === idToRemove);
        if (indexToRemove > -1) {
            selectedPersonnel.splice(indexToRemove, 1);
            const newData = [...selectedPersonnel];
            setSelectedPersonnel(newData);
        }
    };

    const drugTestOnChange = (idToChange: number) => {
        selectedPersonnel?.forEach(personnel => {
            if (personnel.id === idToChange) {
                personnel.drugTest = !personnel.drugTest;
            }
        });
        setSelectedPersonnel([...selectedPersonnel]);
    };

    const bulkAcceptPersonnel = () => {
        if (selectedPersonnel?.length > 0) {
            const bulkAcceptPersonnelDTO = { acceptPersonnelList: Array<AcceptPersonnelDtoType>() };
            selectedPersonnel.forEach(personnel => {
                const acceptPersonnelDTO: AcceptPersonnelDtoType = {
                    personnelId: personnel.id,
                    bgCheckCriminal: true,
                    bgCheckCredit: true,
                    bgCheckEducation: true,
                    drugTesting: personnel.drugTest,
                };
                bulkAcceptPersonnelDTO.acceptPersonnelList.push(acceptPersonnelDTO);
            });
            if (bulkAcceptPersonnelDTO.acceptPersonnelList.length > 0) {
                bulkAccept(bulkAcceptPersonnelDTO)
                    .then(response => {
                        showAlert("Accepted", "success");
                    })
                    .catch(() => {
                        showAlert(
                            "Error bulk accepting personnel, Please contact BIGS Systems.",
                            "danger",
                        );
                    });
            }
        }
    };

    const bulkExemptPersonnel = () => {
        if (selectedPersonnel?.length > 0) {
            const bulkExemptPersonnelDTO = { exemptPersonnelList: Array<ExemptPersonnelDTO>() };
            selectedPersonnel.forEach(personnel => {
                const exemptPersonnelDTO: ExemptPersonnelDTO = {
                    personnelId: personnel.id,
                    drugTesting: personnel.drugTest,
                    comment: exemptComment,
                };
                bulkExemptPersonnelDTO.exemptPersonnelList.push(exemptPersonnelDTO);
            });
            if (bulkExemptPersonnelDTO.exemptPersonnelList.length > 0) {
                bulkExempt(bulkExemptPersonnelDTO)
                    .then(response => {
                        showAlert("Exempt", "success");
                    })
                    .catch(() => {
                        showAlert(
                            "Error bulk exempting personnel, Please contact BIGS Systems.",
                            "danger",
                        );
                    });
            }
        }
    };

    const bulkRolloverInProgressPersonnel = () => {
        if (selectedPersonnel?.length > 0) {
            bulkRolloverInProgress({ personnelIds: selectedPersonnel.map(sp => sp.id) })
                .then(() => {
                    showAlert("Success", "success");
                })
                .catch(() => {});
        }
    };

    return (
        <SelectedPersonnelContext.Provider
            value={{
                initialSelectedPersonnel,
                setInitialSelectedPersonnel,
                selectedPersonnel,
                setSelectedPersonnel,
                removePersonnel,
                drugTestOnChange,
                bulkAcceptPersonnel,
                bulkExemptPersonnel,
                exemptComment,
                setExemptComment,
                bulkRolloverInProgressPersonnel,
            }}
        >
            {children}
        </SelectedPersonnelContext.Provider>
    );
};

const useSelectedPersonnel = (): SelectedPersonnelContext => {
    const context = useContext<SelectedPersonnelContext>(SelectedPersonnelContext);
    if (context === undefined) {
        throw new Error(`useAddPersonnel must be used within a SelectedPersonnelContextProvider`);
    }
    return context;
};

export { SelectedPersonnelContextProvider, useSelectedPersonnel };
