import React, { useEffect, useState, useReducer } from "react";
import { useGlobalModal, GlobalModalTypes } from "../../../contexts/GlobalModalContext";
import BigsModal from "./BigsModal";
import { AreYouSureModalBodyContent, AreYouSureModalFooterContent } from "./AreYouSureModal";
import { ExportModalBodyContent, ExportModalFooterContent } from "./ExportModal";
import { InfoModalBodyContent, InfoModalFooterContent } from "./InfoModal";
import { CertifyRosterBodyContent, CertifyRosterFooterContent } from "./CertifyRosterModal";
import { AcceptPersonnelBody, AcceptPersonnelFooter } from "./AcceptPersonnelModal";
import { ExemptPersonnelBody, ExemptPersonnelFooter } from "./ExemptPersonnelModal";
import { AddCommentModalBody, AddCommentModalFooter } from "./AddCommentModal";
import { SubmitToBgcBody, SubmitToBgcFooter, JurisdictionCheckType } from "./SubmitToBgcModal";
import {
    ResendDtConsentFormModalBody,
    ResendDtConsentFormModalFooter,
} from "./ResendDtConsentFormModal";
import { ResendBgcConsentFormBody, ResendBgcConsentFormFooter } from "./ResendBgcConsentFormModal";
import { DeleteCommentFooter, DeleteCommentBody } from "./DeleteCommentModal";
import {
    UploadConsentFormBody,
    UploadConsentFormFooter,
} from "./uploadconsentform/UploadConsentFormModal";
import { ReviewFindingsModalBody, ReviewFindingsModalFooter } from "./ReviewFindingsModal";
import {
    ReviewDisclosureModalBody,
    ReviewDisclosureModalFooter,
} from "./ReviewDisclosure/ReviewDisclosureModal";
import {
    BulkAcceptPersonnelModalBody,
    BulkAcceptPersonnelModalFooter,
} from "./bulkpersonnel/BulkAcceptPersonnelModal";
import {
    BulkExemptPersonnelModalBody,
    BulkExemptPersonnelModalFooter,
} from "./bulkpersonnel/BulkExemptPersonnelModal";
import { ReviewErrorsModalBody, ReviewErrorsModalFooter } from "./ReviewErrorsModal";
import {
    UploadDtConsentFormModalBody,
    UploadDtConsentFormModalFooter,
} from "./uploaddtconsentform/UploadDtConsentFormModal";
import { QuestionnaireContextProvider } from "../../../contexts/QuestionnaireContext";
import { UploadBgcFormContextProvider } from "../../../contexts/UploadBgcFormContext";
import { ViewCommentModalBody, ViewCommentModalFooter } from "./ViewCommentModal";
import { RemovePersonnelBody, RemovePersonnelFooter } from "./RemovePersonnelModal";
import { ReactivatePersonnelBody, ReactivatePersonnelFooter } from "./ReactivatePersonnelModal";
import { MarkCompleteModalBody, MarkCompleteModalFooter } from "./MarkCompleteModal";
import {
    BulkRolloverInProgressModalBody,
    BulkRolloverInProgressModalFooter,
} from "./bulkpersonnel/BulkRolloverInProgressPersonnelModal";
import {
    CreateNewUmpireGuestModalBody,
    CreateNewUmpireGuestModalFooter,
} from "./CreateNewUmpireGuestModal";
import FormUtil from "../../../util/FormUtil";
import {
    AddPastUmpireGuestModalBody,
    AddPastUmpireGuestModalFooter,
} from "./AddPastUmpireGuestModal";

export type FileType = {
    name: string;
    fileBase64String: string | ArrayBuffer;
    id: string;
    jurisdiction?: string;
};

export type PersonnelInfoDtUploadType = {
    firstName: string;
    lastName: string;
    middleName: string;
    file: FileType;
};

export type NewUmpireGuestState = {
    firstName: string;
    lastName: string;
    middleName?: string;
    nickName?: string;
    relationship: string;
    email: string;
    validEmail: boolean;
    international: boolean;
    isValid: boolean;
};

const GlobalModal = () => {
    const {
        state: {
            modalType,
            content,
            isOpen,
            headerText,
            certifyRosterCallback,
            bgCheckProceduresText,
            personnelInfo,
            acceptPersonnelCallback,
            exemptPersonnelCallback,
            personnelId,
            jurisdictionStatuses,
            onSubmitToBgcCallback,
            onCancelCallback,
            modalHeaderText,
            selectedSideMenuOpts,
            resendClickHandler,
            onResendBgcCallback,
            commentId,
            getComments,
            personnel,
            personnelAdvancedSearch,
            adminSelectedMenuOptions,
            adminAffiliationFilters,
            adminSearchText,
            personnelFormStatusInfo,
            targetJurisdiction,
            rosterStatus,
            callback,
            active,
            jurisdiction,
            umpireId,
            currNumOfGuests,
            closeModalCallback,
            createNewUmpireGuestCallback,
        },
        closeModal,
    } = useGlobalModal();

    // Certify Roster Modal
    const [certifyRosterCheckboxVal, setCertifyRosterCheckboxVal] = useState<boolean>(false);

    const [isCorrectingSsn, setIsCorrectingSsn] = useState<boolean>(false);
    const [isCorrectingDob, setIsCorrectingDob] = useState<boolean>(false);

    // Accept Personnel Modal & Upload Consent Form Modal
    const [bgChecks, setBgChecks] = useState({
        criminal: true,
        credit: true,
        education: true,
    });
    const [drugTestingState, setDrugTestingState] = useState(null);
    const [exemptComment, setExemptComment] = useState<string>("");

    // Add Comment Modal
    const [comment, setComment] = useState<Array<any> | string>([]);
    // Add Comment Modal & Upload Consent Form Modal
    const [files, setFiles] = useState<FileType[]>([]);

    const [bgcScore, setBgcScore] = useState<string>("");

    // Submit to BGC Modal
    const [jurisdictionChecks, setJurisdictionChecks] = useState<JurisdictionCheckType[]>([]);

    // Resend BGC modal
    const [bgcJurisdictions, setBgcJurisdictions] = useState<JurisdictionCheckType[]>([]);

    // Review findings comment
    const [findingsComment, setFindingsComment] = useState<string>("");

    // Review Disclosure/Review consent
    const [disclosureReviews, setDisclosureReviews] = useState([]);
    const [currentJurisdiction, setCurrentJurisdiction] = useState({
        disclosures: [],
        review: {
            previousNames: null,
            countriesLived: null,
        },
        jurisdiction: "",
    });
    const [currentJurisdictionIndex, setCurrentJurisdictionIndex] = useState(0);
    const [disclosureComment, setDisclosureComment] = useState("");
    const [reviewComment, setReviewComment] = useState("");
    const [selectedAffiliation, setSelectedAffiliation] = useState({});
    const [markCompleteJurisdiction, setMarkCompleteJurisdiction] = useState("");
    const [markCompleteFindings, setMarkCompleteFindings] = useState(null);
    const [markCompleteFindingsComment, setMarkCompleteFindingsComment] = useState("");

    // Review Errors Modal
    type ReviewErrorsSSNstate = {
        ssnPartOne: string;
        ssnPartTwo: string;
        ssnPartThree: string;
    };
    type ReviewErrorsDobState = {
        dobYear: string;
        dobMonth: string;
        dobDay: string;
    };
    const initialState = {
        ssnPartOne: "",
        ssnPartTwo: "",
        ssnPartThree: "",
    };
    const dobInitialState = {
        dobYear: "",
        dobMonth: "",
        dobDay: "",
    };
    const dobReducer = (state: ReviewErrorsDobState, action: any) => {
        switch (action.type) {
            case "SET_DOB_YEAR":
                return {
                    ...state,
                    dobYear: action.dobYear,
                };
            case "SET_DOB_MONTH":
                return {
                    ...state,
                    dobMonth: action.dobMonth,
                };
            case "SET_DOB_DAY":
                return {
                    ...state,
                    dobDay: action.dobDay,
                };
        }
    };
    const ssnReducer = (state: ReviewErrorsSSNstate, action: any) => {
        switch (action.type) {
            case "SET_SSN_PART_ONE":
                return {
                    ...state,
                    ssnPartOne: action.ssnPartOne,
                };
            case "SET_SSN_PART_TWO":
                return {
                    ...state,
                    ssnPartTwo: action.ssnPartTwo,
                };
            case "SET_SSN_PART_THREE":
                return {
                    ...state,
                    ssnPartThree: action.ssnPartThree,
                };
        }
    };

    const [reviewErrorsSSNstate, reviewErrorsDispatch] = useReducer(ssnReducer, initialState);
    const [reviewErrorsDobState, reviewErrorsDobDispatch] = useReducer(dobReducer, dobInitialState);

    // Upload DT Consent Form Modal
    const [dtUploadFile, setDtUploadFile] = useState(null);

    // Upload BGC Modal (Questionnaire Section)
    const [questionnaireCompletedStatus, setQuestionnaireCompletedStatus] = useState<{
        completed: boolean;
        questionnaireAnswers: {};
    }>({
        completed: false,
        questionnaireAnswers: {},
    });

    const [jurisdictionForUpload, setJurisdictionForUpload] = useState<{
        label: string;
        value: string;
        isCompleted: boolean;
    }>({ label: "", value: "", isCompleted: false });

    const [jurisdictionOptions, setJurisdictionOptions] = useState<{ label: ""; value: "" }[]>([]);
    const [continueClicked, setContinueClicked] = useState(false);

    // New Umpire Guest
    const umpireGuestInitialState: NewUmpireGuestState = {
        firstName: "",
        lastName: "",
        middleName: "",
        nickName: "",
        email: "",
        relationship: "",
        international: false,
        validEmail: false,
        isValid: false,
    };

    const isValidNewUmpireGuest = (umpireGuestState: NewUmpireGuestState): boolean => {
        return FormUtil.validateFormObject(umpireGuestState, [
            "firstName",
            "lastName",
            "email",
            "relationship",
        ]);
    };

    const newUmpireGuestReducer = (state: NewUmpireGuestState, action: any) => {
        if (action.type === "reset") {
            return { ...umpireGuestInitialState };
        }
        return {
            ...state,
            [action.type]: action.value,
            isValid: isValidNewUmpireGuest({ ...state, [action.type]: action.value }),
        };
    };

    const [newUmpireGuestState, newUmpireGuestDispatch] = useReducer(
        newUmpireGuestReducer,
        umpireGuestInitialState,
    );

    // Add past Guests
    type PastUmpireGuest = {
        id: number;
    };
    const [selectedPastUmpireGuests, setSelectedPastUmpireGuests] = useState<PastUmpireGuest[]>([]);

    const generateBodyContent = () => {
        switch (modalType) {
            case GlobalModalTypes.ARE_YOU_SURE_MODAL:
                return <AreYouSureModalBodyContent body={content} />;
            case GlobalModalTypes.EXPORT_MODAL:
                return <ExportModalBodyContent />;
            case GlobalModalTypes.INFO_MODAL:
                return <InfoModalBodyContent body={content} />;
            case GlobalModalTypes.CERTIFY_ROSTER:
                return (
                    <CertifyRosterBodyContent
                        certifyRosterCheckboxVal={certifyRosterCheckboxVal}
                        setCertifyRosterCheckboxVal={setCertifyRosterCheckboxVal}
                        bgCheckProceduresText={bgCheckProceduresText}
                    />
                );
            case GlobalModalTypes.ACCEPT_PERSONNEL:
                return (
                    <AcceptPersonnelBody
                        personnelInfo={personnelInfo}
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                        bgChecks={bgChecks}
                        setBgChecks={setBgChecks}
                    />
                );
            case GlobalModalTypes.EXEMPT_PERSONNEL:
                return (
                    <ExemptPersonnelBody
                        personnelInfo={personnelInfo}
                        exemptComment={exemptComment}
                        setExemptComment={setExemptComment}
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                    />
                );
            case GlobalModalTypes.ADD_COMMENT:
                return (
                    <AddCommentModalBody
                        comment={comment}
                        setComment={setComment}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            case GlobalModalTypes.SUBMIT_TO_BGC:
                return (
                    <SubmitToBgcBody
                        personnelId={personnelId}
                        jurisdictionChecks={jurisdictionChecks}
                        setJurisdictionChecks={setJurisdictionChecks}
                    />
                );
            case GlobalModalTypes.RESEND_DT_CONSENT_FORM:
                return (
                    <ResendDtConsentFormModalBody
                        personnelId={personnelId}
                        resendClickHandler={resendClickHandler}
                    />
                );
            case GlobalModalTypes.RESENT_BGC_CONSENT_FORM:
                return (
                    <ResendBgcConsentFormBody
                        personnelId={personnelId}
                        bgcJurisdictions={bgcJurisdictions}
                        setBgcJurisdictions={setBgcJurisdictions}
                    />
                );
            case GlobalModalTypes.DELETE_COMMENT:
                return <DeleteCommentBody />;
            case GlobalModalTypes.UPLOAD_BGC_CONSENT_FORM:
                return (
                    <UploadConsentFormBody
                        personnel={personnel}
                        questionnaireCompletedStatus={questionnaireCompletedStatus}
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                        jurisdictionForUpload={jurisdictionForUpload}
                        setJurisdictionForUpload={setJurisdictionForUpload}
                        jurisdictionOptions={jurisdictionOptions}
                        setJurisdictionOptions={setJurisdictionOptions}
                        continueClicked={continueClicked}
                        setContinueClicked={setContinueClicked}
                    />
                );
            case GlobalModalTypes.REVIEW_FINDINGS:
                return (
                    <ReviewFindingsModalBody
                        personnel={personnel}
                        active={active}
                        comment={findingsComment}
                        setComment={setFindingsComment}
                        callback={callback}
                        bgcScore={bgcScore}
                        setBgcScore={setBgcScore}
                        jurisdiction={jurisdiction}
                    />
                );
            case GlobalModalTypes.MARK_COMPLETE:
                return (
                    <MarkCompleteModalBody
                        personnel={personnel}
                        markCompleteJurisdiction={markCompleteJurisdiction}
                        setMarkCompleteJurisdiction={setMarkCompleteJurisdiction}
                        markCompleteFindings={markCompleteFindings}
                        setMarkCompleteFindings={setMarkCompleteFindings}
                        markCompleteFindingsComment={markCompleteFindingsComment}
                        setMarkCompleteFindingsComment={setMarkCompleteFindingsComment}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            case GlobalModalTypes.BULK_ACCEPT_MODAL:
                return <BulkAcceptPersonnelModalBody />;
            case GlobalModalTypes.BULK_EXEMPT_MODAL:
                return <BulkExemptPersonnelModalBody />;
            case GlobalModalTypes.REVIEW_DISCLOSURE:
                return (
                    <ReviewDisclosureModalBody
                        personnel={personnel}
                        disclosureComment={disclosureComment}
                        setDisclosureComment={setDisclosureComment}
                        reviewComment={reviewComment}
                        setReviewComment={setReviewComment}
                        disclosureReviews={disclosureReviews}
                        setDisclosureReviews={setDisclosureReviews}
                        currentJurisdictionIndex={currentJurisdictionIndex}
                        setCurrentJurisdictionIndex={setCurrentJurisdictionIndex}
                        currentJurisdiction={currentJurisdiction}
                        setCurrentJurisdiction={setCurrentJurisdiction}
                        targetJurisdiction={targetJurisdiction}
                        callback={callback}
                        active={active}
                    />
                );
            case GlobalModalTypes.REVIEW_ERRORS:
                return (
                    <ReviewErrorsModalBody
                        personnelFormStatusInfo={personnelFormStatusInfo}
                        reviewErrorsSSNstate={reviewErrorsSSNstate}
                        reviewErrorsDispatch={reviewErrorsDispatch}
                        reviewErrorsDobState={reviewErrorsDobState}
                        reviewErrorsDobDispatch={reviewErrorsDobDispatch}
                        setIsCorrectingSsn={setIsCorrectingSsn}
                        setIsCorrectingDob={setIsCorrectingDob}
                    />
                );
            case GlobalModalTypes.UPLOAD_DT_CONSENT_FORM:
                return (
                    <UploadDtConsentFormModalBody
                        dtUploadFile={dtUploadFile}
                        setDtUploadFile={setDtUploadFile}
                    />
                );
            case GlobalModalTypes.VIEW_COMMENT:
                return (
                    <ViewCommentModalBody rosterStatus={rosterStatus} personnelId={personnelId} />
                );
            case GlobalModalTypes.REMOVE_PERSONNEL:
                return <RemovePersonnelBody personnel={personnel} callback={callback} />;
            case GlobalModalTypes.REACTIVATE_PERSONNEL:
                return (
                    <ReactivatePersonnelBody
                        personnel={personnel}
                        selectedAffiliation={selectedAffiliation}
                        setSelectedAffiliation={setSelectedAffiliation}
                        callback={callback}
                    />
                );
            case GlobalModalTypes.BULK_ROLLOVER_INPROGRESS_MODAL:
                return <BulkRolloverInProgressModalBody />;
            case GlobalModalTypes.CREATE_NEW_UMPIRE_GUEST_MODAL:
                return (
                    <CreateNewUmpireGuestModalBody
                        umpireGuestState={newUmpireGuestState}
                        umpireGuestDispatch={newUmpireGuestDispatch}
                    />
                );
            case GlobalModalTypes.ADD_PAST_UMPIRE_GUEST_MODAL:
                return (
                    <AddPastUmpireGuestModalBody
                        selectedPastUmpireGuests={selectedPastUmpireGuests}
                        setSelectedPastUmpireGuests={setSelectedPastUmpireGuests}
                        umpireId={umpireId}
                        currNumOfGuests={currNumOfGuests}
                    />
                );
        }
    };

    const generateFooterContent = () => {
        switch (modalType) {
            case GlobalModalTypes.ARE_YOU_SURE_MODAL:
                return <AreYouSureModalFooterContent />;
            case GlobalModalTypes.EXPORT_MODAL:
                return <ExportModalFooterContent />;
            case GlobalModalTypes.INFO_MODAL:
                return <InfoModalFooterContent />;
            case GlobalModalTypes.CERTIFY_ROSTER:
                return (
                    <CertifyRosterFooterContent
                        certifyRosterCallback={certifyRosterCallback}
                        certifyRosterCheckboxVal={certifyRosterCheckboxVal}
                    />
                );
            case GlobalModalTypes.ACCEPT_PERSONNEL:
                return (
                    <AcceptPersonnelFooter
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                        bgChecks={bgChecks}
                        setBgChecks={setBgChecks}
                        acceptPersonnelCallback={acceptPersonnelCallback}
                        personnelInfo={personnelInfo}
                    />
                );
            case GlobalModalTypes.EXEMPT_PERSONNEL:
                return (
                    <ExemptPersonnelFooter
                        exemptComment={exemptComment}
                        setExemptComment={setExemptComment}
                        exemptPersonnelCallback={exemptPersonnelCallback}
                        personnelInfo={personnelInfo}
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                    />
                );
            case GlobalModalTypes.ADD_COMMENT:
                return (
                    <AddCommentModalFooter
                        comment={comment}
                        setComment={setComment}
                        files={files}
                        setFiles={setFiles}
                        personnelId={personnelId}
                        callback={callback}
                    />
                );
            case GlobalModalTypes.SUBMIT_TO_BGC:
                return (
                    <SubmitToBgcFooter
                        jurisdictionChecks={jurisdictionChecks}
                        personnelId={personnelId}
                        onSubmitCallback={onSubmitToBgcCallback}
                        onCancelCallback={onCancelCallback}
                        modalHeaderText={modalHeaderText}
                    />
                );
            case GlobalModalTypes.RESEND_DT_CONSENT_FORM:
                return (
                    <ResendDtConsentFormModalFooter
                        personnelId={personnelId}
                        resendClickHandler={resendClickHandler}
                    />
                );
            case GlobalModalTypes.RESENT_BGC_CONSENT_FORM:
                return (
                    <ResendBgcConsentFormFooter
                        personnelId={personnelId}
                        onResendBgcCallback={onResendBgcCallback}
                        bgcJurisdictions={bgcJurisdictions}
                    />
                );
            case GlobalModalTypes.DELETE_COMMENT:
                return (
                    <DeleteCommentFooter
                        commentId={commentId}
                        closeModal={closeModal}
                        getComments={getComments}
                    />
                );
            case GlobalModalTypes.UPLOAD_BGC_CONSENT_FORM:
                return (
                    <UploadConsentFormFooter
                        closeModal={closeModal}
                        personnel={personnel}
                        questionnaireCompletedStatus={questionnaireCompletedStatus}
                        setQuestionnaireCompletedStatus={setQuestionnaireCompletedStatus}
                        drugTestingState={drugTestingState}
                        jurisdictionForUpload={jurisdictionForUpload}
                        setJurisdictionForUpload={setJurisdictionForUpload}
                        jurisdictionOptions={jurisdictionOptions}
                        setJurisdictionOptions={setJurisdictionOptions}
                        continueClicked={continueClicked}
                        setContinueClicked={setContinueClicked}
                    />
                );
            case GlobalModalTypes.REVIEW_FINDINGS:
                return (
                    <ReviewFindingsModalFooter
                        personnel={personnel}
                        active={active}
                        comment={findingsComment}
                        setComment={setFindingsComment}
                        callback={callback}
                        bgcScore={bgcScore}
                        setBgcScore={setBgcScore}
                        jurisdiction={jurisdiction}
                    />
                );
            case GlobalModalTypes.MARK_COMPLETE:
                return (
                    <MarkCompleteModalFooter
                        personnel={personnel}
                        markCompleteJurisdiction={markCompleteJurisdiction}
                        setMarkCompleteJurisdiction={setMarkCompleteJurisdiction}
                        markCompleteFindings={markCompleteFindings}
                        setMarkCompleteFindings={setMarkCompleteFindings}
                        markCompleteFindingsComment={markCompleteFindingsComment}
                        setMarkCompleteFindingsComment={setMarkCompleteFindingsComment}
                        files={files}
                        setFiles={setFiles}
                    />
                );
            case GlobalModalTypes.BULK_ACCEPT_MODAL:
                return (
                    <BulkAcceptPersonnelModalFooter
                        personnelAdvancedSearch={personnelAdvancedSearch}
                        adminSelectedMenuOptions={adminSelectedMenuOptions}
                        adminAffiliationFilters={adminAffiliationFilters}
                        adminSearchText={adminSearchText}
                    />
                );
            case GlobalModalTypes.BULK_EXEMPT_MODAL:
                return (
                    <BulkExemptPersonnelModalFooter
                        personnelAdvancedSearch={personnelAdvancedSearch}
                        adminSelectedMenuOptions={adminSelectedMenuOptions}
                        adminAffiliationFilters={adminAffiliationFilters}
                        adminSearchText={adminSearchText}
                    />
                );
            case GlobalModalTypes.REVIEW_DISCLOSURE:
                return (
                    <ReviewDisclosureModalFooter
                        personnel={personnel}
                        disclosureComment={disclosureComment}
                        setDisclosureComment={setDisclosureComment}
                        reviewComment={reviewComment}
                        setReviewComment={setReviewComment}
                        disclosureReviews={disclosureReviews}
                        setDisclosureReviews={setDisclosureReviews}
                        setCurrentJurisdictionIndex={setCurrentJurisdictionIndex}
                        currentJurisdictionIndex={currentJurisdictionIndex}
                        currentJurisdiction={currentJurisdiction}
                        setCurrentJurisdiction={setCurrentJurisdiction}
                        targetJurisdiction={targetJurisdiction}
                        callback={callback}
                        active={active}
                    />
                );
            case GlobalModalTypes.REVIEW_ERRORS:
                return (
                    <ReviewErrorsModalFooter
                        personnelFormStatusInfo={personnelFormStatusInfo}
                        ssnValue={reviewErrorsSSNstate}
                        dobValue={reviewErrorsDobState}
                        setIsCorrectingSsn={setIsCorrectingSsn}
                        setIsCorrectingDob={setIsCorrectingDob}
                        isCorrectingSsn={isCorrectingSsn}
                        isCorrectingDob={isCorrectingDob}
                    />
                );
            case GlobalModalTypes.UPLOAD_DT_CONSENT_FORM:
                return (
                    <UploadDtConsentFormModalFooter
                        personnelId={personnelId}
                        dtUploadFile={dtUploadFile}
                    />
                );
            case GlobalModalTypes.VIEW_COMMENT:
                return (
                    <ViewCommentModalFooter rosterStatus={rosterStatus} personnelId={personnelId} />
                );
            case GlobalModalTypes.REMOVE_PERSONNEL:
                return (
                    <RemovePersonnelFooter
                        personnel={personnel}
                        closeModal={closeModal}
                        callback={callback}
                    />
                );
            case GlobalModalTypes.REACTIVATE_PERSONNEL:
                return (
                    <ReactivatePersonnelFooter
                        personnel={personnel}
                        closeModal={closeModal}
                        callback={callback}
                        selectedAffiliation={selectedAffiliation}
                    />
                );
            case GlobalModalTypes.BULK_ROLLOVER_INPROGRESS_MODAL:
                return (
                    <BulkRolloverInProgressModalFooter
                        personnelAdvancedSearch={personnelAdvancedSearch}
                        adminSelectedMenuOptions={adminSelectedMenuOptions}
                        adminAffiliationFilters={adminAffiliationFilters}
                        adminSearchText={adminSearchText}
                    />
                );
            case GlobalModalTypes.CREATE_NEW_UMPIRE_GUEST_MODAL:
                return (
                    <CreateNewUmpireGuestModalFooter
                        umpireGuestState={newUmpireGuestState}
                        createNewUmpireGuestCallback={createNewUmpireGuestCallback}
                    />
                );
            case GlobalModalTypes.ADD_PAST_UMPIRE_GUEST_MODAL:
                return (
                    <AddPastUmpireGuestModalFooter
                        selectedPastUmpireGuests={selectedPastUmpireGuests}
                        umpireId={umpireId}
                        closeModalCallback={closeModalCallback}
                    />
                );
        }
    };

    useEffect(() => {
        const elem = document.getElementById("modal-backdrop");
        if (isOpen && elem) elem.style.zIndex = "1053";

        // Need to reset values on 'X' clicked
        return () => {
            setCertifyRosterCheckboxVal(false);
            setFiles([]);
            setComment([]);
            setBgChecks({
                criminal: true,
                credit: false,
                education: false,
            });
            setDrugTestingState(false);
            setExemptComment("");
            setJurisdictionChecks([]);
            setDtUploadFile(null);
            setQuestionnaireCompletedStatus({
                completed: false,
                questionnaireAnswers: {},
            });
            setJurisdictionForUpload(null);
            newUmpireGuestDispatch({
                type: "reset",
            });
            setSelectedPastUmpireGuests([]);
        };
    }, [isOpen]);

    const bodyContent = generateBodyContent();
    const footerContent = generateFooterContent();

    return (
        <QuestionnaireContextProvider>
            <UploadBgcFormContextProvider>
                <BigsModal
                    isOpen={isOpen}
                    closeModal={closeModal}
                    headerText={headerText}
                    headerChild={null}
                >
                    {bodyContent}
                    {footerContent}
                </BigsModal>
            </UploadBgcFormContextProvider>
        </QuestionnaireContextProvider>
    );
};

export default GlobalModal;
