import React, { createContext, useContext, Dispatch, SetStateAction, useState } from "react";

type UmpireGuestsMenuOptionType = {
    id: number;
    amount: number;
    text: string;
};

type UmpireGuestsSideMenuContext = {
    menuOptions: UmpireGuestsMenuOptionType[];
    setMenuOptions: Dispatch<SetStateAction<UmpireGuestsMenuOptionType[]>>;
    selectedMenuOptions: UmpireGuestsMenuOptionType[];
    setSelectedMenuOptions: Dispatch<SetStateAction<UmpireGuestsMenuOptionType[]>>;
    activeCount: number;
    setActiveCount: Dispatch<SetStateAction<number>>;
    inactiveCount: number;
    setInactiveCount: Dispatch<SetStateAction<number>>;
    refreshSideMenu: Function;
    refreshMenu: boolean;
    previousMenuOptions: UmpireGuestsMenuOptionType[];
    setPreviousMenuOptions: Dispatch<SetStateAction<UmpireGuestsMenuOptionType[]>>;
    searchText: any[] | string;
    setSearchText: Dispatch<SetStateAction<[] | string>>;
    inactiveSelected: boolean;
    setInactiveSelected: Dispatch<SetStateAction<boolean>>;
    umpireFilter: { label: string; value: string; id: number };
    setUmpireFilter: Dispatch<SetStateAction<{ label: string; value: string; id: number }>>;
    prevUmpireFilter: any;
    setPrevUmpireFilter: any;
    activeSelected: boolean;
    setActiveSelected: Dispatch<SetStateAction<boolean>>;
};

const UmpireGuestsSideMenuContext = createContext<UmpireGuestsSideMenuContext>({
    menuOptions: null,
    setMenuOptions: null,
    selectedMenuOptions: null,
    setSelectedMenuOptions: null,
    activeCount: null,
    setActiveCount: null,
    inactiveCount: null,
    setInactiveCount: null,
    refreshSideMenu: null,
    refreshMenu: null,
    previousMenuOptions: null,
    setPreviousMenuOptions: null,
    searchText: null,
    setSearchText: null,
    inactiveSelected: null,
    setInactiveSelected: null,
    umpireFilter: null,
    setUmpireFilter: null,
    prevUmpireFilter: null,
    setPrevUmpireFilter: null,
    activeSelected: null,
    setActiveSelected: null,
});

const activeOptions = [
    {
        id: 1,
        text: "Pending BOC",
        amount: 0,
    },
    {
        id: 2,
        text: "Consent Pending",
        amount: 0,
    },
    {
        id: 5,
        text: "Disclosures/Review Consent",
        amount: 0,
    },
    {
        id: 6,
        text: "Reviewed But Not Submitted",
        amount: 0,
    },
    {
        id: 7,
        text: "In Progress",
        amount: 0,
    },
    {
        id: 8,
        text: "Findings",
        amount: 0,
    },
    {
        id: 9,
        text: "Errors",
        amount: 0,
    },
    {
        id: 11,
        text: "International",
        amount: 0,
    },
    {
        id: 12,
        text: "Complete",
        amount: 0,
    },
];

const UmpireGuestsSideMenuContextProvider: React.FC = ({ children }) => {
    const [menuOptions, setMenuOptions] = useState<UmpireGuestsMenuOptionType[]>(activeOptions);
    const [selectedMenuOptions, setSelectedMenuOptions] = useState<UmpireGuestsMenuOptionType[]>([
        activeOptions[0],
    ]);
    const [previousMenuOptions, setPreviousMenuOptions] = useState<UmpireGuestsMenuOptionType[]>(
        [],
    );
    const [activeCount, setActiveCount] = useState<number>(0);
    const [inactiveCount, setInactiveCount] = useState<number>(0);
    const [refreshMenu, setRefreshMenu] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<Array<any> | string>([]);
    const [inactiveSelected, setInactiveSelected] = useState(false);
    const [umpireFilter, setUmpireFilter] = useState<{ label: string; value: string; id: number }>({
        label: "All Umpires",
        value: "All Umpires",
        id: 0,
    });
    const [prevUmpireFilter, setPrevUmpireFilter] = useState(null);
    const [activeSelected, setActiveSelected] = useState<boolean>(false);

    const refreshSideMenu = () => {
        setRefreshMenu(true);
        setSelectedMenuOptions([...selectedMenuOptions]);
        setRefreshMenu(false);
    };

    return (
        <UmpireGuestsSideMenuContext.Provider
            value={{
                menuOptions,
                setMenuOptions,
                selectedMenuOptions,
                setSelectedMenuOptions,
                previousMenuOptions,
                setPreviousMenuOptions,
                activeCount,
                setActiveCount,
                inactiveCount,
                setInactiveCount,
                refreshMenu,
                searchText,
                setSearchText,
                inactiveSelected,
                setInactiveSelected,
                refreshSideMenu,
                umpireFilter,
                setUmpireFilter,
                prevUmpireFilter,
                setPrevUmpireFilter,
                activeSelected,
                setActiveSelected,
            }}
        >
            {children}
        </UmpireGuestsSideMenuContext.Provider>
    );
};

const useUmpireGuestsSideMenu = (): UmpireGuestsSideMenuContext => {
    const umpiresSideMenuContext = useContext<UmpireGuestsSideMenuContext>(
        UmpireGuestsSideMenuContext,
    );
    if (umpiresSideMenuContext === undefined) {
        throw new Error(`useVolunteersSideMenu must be used within a UmpiresSideMenuProvider`);
    }
    return umpiresSideMenuContext;
};

export {
    UmpireGuestsSideMenuContext as UmpiresSideMenuContext,
    UmpireGuestsSideMenuContextProvider as UmpiresSideMenuContextProvider,
    UmpireGuestsMenuOptionType as UmpiresMenuOptionType,
    useUmpireGuestsSideMenu as useUmpiresSideMenu,
};
