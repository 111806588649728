import React, { ReactNode, useState, useEffect } from "react";
import { TextInputType, DateInputType, DropdownType, LinkType, RadioType } from "./InputTypes";
import {
    UmpireGuestInfoLayoutRows,
    UMPIRE_GUEST_INFO_LAYOUT_ROWS_AMT,
    UmpireGuestInfoDrVenColRow,
} from "./UmpireGuestInfoLayoutRows";
import styled from "styled-components";
import SSNinput from "../../../SSNinput";
import { useUploadBgcForm, BgcFormActionTypes } from "../../../../../contexts/UploadBgcFormContext";
import { getPersonnelAliasNameList } from "../../../../../api/PersonnelApi";

type UmpireGuestInfoFormLayoutProps = {
    jurisdictionForUpload: string;
    personnelId: number;
    umpireNameOptions: any;
};

const TopRowStyle = styled.div.attrs(() => ({
    className: "d-flex mt-3",
}))``;

const MidRowStyle = styled.div.attrs(() => ({
    className: "d-flex mt-1",
}))``;

const UmpireGuestInfoFormLayout: React.FC<UmpireGuestInfoFormLayoutProps> = ({
    jurisdictionForUpload,
    personnelId,
    umpireNameOptions,
}) => {
    const { bgcFormState, dispatchBgcForm } = useUploadBgcForm();

    const getComponent = (entry: any): ReactNode => {
        if (entry.show(bgcFormState)) {
            switch (entry.type) {
                case "input":
                    return (
                        <TextInputType
                            label={entry.label}
                            name={entry.name}
                            value={bgcFormState[entry.name]}
                            onChange={newVal => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.INPUT,
                                    name: entry.name,
                                    value: newVal,
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                            numsOnly={entry.numsOnly}
                        />
                    );
                case "date":
                    return (
                        <DateInputType
                            label={entry.label}
                            name={entry.name}
                            value={bgcFormState[entry.name]}
                            onChange={newVal => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.DATE,
                                    name: entry.name,
                                    value: newVal,
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                        />
                    );
                case "ssn":
                    const ssnOnChange = (ssnSection: string, value: string) => {
                        switch (ssnSection) {
                            case "partOne":
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.SSN_PART_ONE,
                                    name: entry.name,
                                    value: value,
                                    question: entry,
                                });
                                break;
                            case "partTwo":
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.SSN_PART_TWO,
                                    name: entry.name,
                                    value: value,
                                    question: entry,
                                });
                                break;
                            case "partThree":
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.SSN_PART_THREE,
                                    name: entry.name,
                                    value: value,
                                    question: entry,
                                });
                                break;
                        }
                    };
                    return (
                        <SSNinput
                            ssnValue={{
                                ssnPartOne: bgcFormState[entry.name].partOne,
                                ssnPartTwo: bgcFormState[entry.name].partTwo,
                                ssnPartThree: bgcFormState[entry.name].partThree,
                            }}
                            ssnOnChange={(type, newVal) => {
                                ssnOnChange(type, newVal);
                            }}
                        />
                    );
                case "dropdown":
                    return (
                        <DropdownType
                            label={entry.label}
                            name={entry.name}
                            value={bgcFormState[entry.name]}
                            onChange={newVal => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.DROPDOWN,
                                    name: entry.name,
                                    value: newVal,
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                            options={entry.options}
                        />
                    );
                case "umpire":
                    return (
                        <DropdownType
                            label="Umpire Name"
                            name={entry.name}
                            value={umpireNameOptions.value}
                            onChange={newVal => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.UMPIRE,
                                    name: entry.name,
                                    value: newVal.id.toString(),
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                            options={umpireNameOptions}
                        />
                    );
                case "link":
                    return (
                        <LinkType
                            label={entry.label}
                            name={entry.name}
                            value={bgcFormState[entry.name]}
                            onChange={searchNames => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.ADDITIONAL_SEARCH_NAMES,
                                    name: entry.name,
                                    additionalSearchNames: searchNames,
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                        />
                    );
                case "radio":
                    return (
                        <RadioType
                            label={entry.label}
                            name={entry.name}
                            value={bgcFormState[entry.name]}
                            onChange={newVal => {
                                dispatchBgcForm({
                                    type: BgcFormActionTypes.RADIO,
                                    name: entry.name,
                                    value: newVal,
                                    question: entry,
                                });
                            }}
                            required={entry.required(bgcFormState)}
                        />
                    );
                default:
                    return <div />;
            }
        } else {
            return null;
        }
    };

    const rows = Array.from(new Array(UMPIRE_GUEST_INFO_LAYOUT_ROWS_AMT), (x, i) => i + 1);

    useEffect(() => {
        if (
            jurisdictionForUpload === "dominicanRepublic" ||
            jurisdictionForUpload === "venezuela" ||
            jurisdictionForUpload === "colombia"
        ) {
            dispatchBgcForm({
                type: BgcFormActionTypes.SET_DR_VEN_COL,
                value: true,
            });
        }
        if (jurisdictionForUpload === "japan") {
            dispatchBgcForm({
                type: BgcFormActionTypes.SET_JAPAN_VALUE,
                value: true,
            });
        }
        if (jurisdictionForUpload === "usPuerto") {
            dispatchBgcForm({
                type: BgcFormActionTypes.SET_US_VALUE,
                value: true,
            });
        }

        getPersonnelAliasNameList(personnelId?.toString()).then(response => {
            dispatchBgcForm({
                type: BgcFormActionTypes.ADDITIONAL_SEARCH_NAMES,
                additionalSearchNames: response,
            });
        });

        return () => {
            dispatchBgcForm({
                type: BgcFormActionTypes.RESET,
            });
        };
    }, [jurisdictionForUpload]);

    return (
        <>
            {rows.map(row => {
                return (
                    <>
                        {row === 1 ? (
                            <>
                                <TopRowStyle className="d-flex mt-3">
                                    {UmpireGuestInfoLayoutRows[row].map((entry: any) =>
                                        getComponent(entry),
                                    )}
                                </TopRowStyle>
                                {bgcFormState.isDrVenCol ? (
                                    <MidRowStyle>
                                        {UmpireGuestInfoDrVenColRow.map((entry: any) =>
                                            getComponent(entry),
                                        )}
                                    </MidRowStyle>
                                ) : null}
                            </>
                        ) : (
                            <MidRowStyle>
                                {UmpireGuestInfoLayoutRows[row].map((entry: any) =>
                                    getComponent(entry),
                                )}
                            </MidRowStyle>
                        )}
                    </>
                );
            })}
        </>
    );
};

export default UmpireGuestInfoFormLayout;
