import React, { Dispatch, ReducerAction, useState } from "react";
import styled from "styled-components";
import { getPersonnelByEmail } from "../../../api/PersonnelApi";
import { addUmpireGuest, NewUmpireGuestDtoType } from "../../../api/UmpireGuestsApi";
import { useAlert } from "../../../contexts/AlertsContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import FormUtil from "../../../util/FormUtil";
import { BigsInput } from "../BigsBcrWrapper";
import EmailFormElement from "../EmailFormElement";
import { BigsRadio, RequiredLabel } from "../FormElements";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { NewUmpireGuestState } from "./GlobalModal";

type CreateNewUmpireGuestModalBodyProps = {
    umpireGuestState: NewUmpireGuestState;
    umpireGuestDispatch: Dispatch<ReducerAction<any>>;
};

const StyledBody = styled.div.attrs(() => ({
    className: "mt-4 ml-4 mr-4",
}))`
    height: 300px;
`;

const RadioLabel = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 14px;
    }
`;

const Container = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))``;

const CreateNewUmpireGuestModalBody: React.FC<CreateNewUmpireGuestModalBodyProps> = ({
    umpireGuestState,
    umpireGuestDispatch,
}) => {
    const { userInfo } = useAuth();
    const showAlert = useAlert();

    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [duplicateEmail, setDuplicateEmail] = useState<boolean>(false);

    const assignEmailState = (value: string) => {
        let validEmailSetter = false;
        if (value && value !== "") {
            if (!FormUtil.isValidEmail(value)) {
                validEmailSetter = false;
                umpireGuestDispatch({
                    type: "email",
                    value: value,
                });
            } else {
                validEmailSetter = true;
                umpireGuestDispatch({
                    type: "email",
                    value: value,
                });
            }
        } else {
            validEmailSetter = true;
            umpireGuestDispatch({
                type: "email",
                value: value,
            });
        }
        setValidEmail(validEmailSetter);
        setDuplicateEmail(false);
        umpireGuestDispatch({
            type: "validEmail",
            value: false,
        });
    };

    const emailOnBlur = (value: string) => {
        if (validEmail) {
            const orgId = userInfo.orgId;
            getPersonnelByEmail(value, orgId)
                .then(response => {
                    setDuplicateEmail(true);
                    showAlert(
                        `${value} is already added as a guest for ${response.data.affiliation.name}`,
                        `danger`,
                    );
                    umpireGuestDispatch({
                        type: "validEmail",
                        value: false,
                    });
                })
                .catch(error => {
                    if (error.response) {
                        // Request made and server responded
                        setDuplicateEmail(false);
                        umpireGuestDispatch({
                            type: "validEmail",
                            value: true,
                        });
                    } else if (error.request) {
                        // The request was made but no response was received
                        setDuplicateEmail(true);
                        showAlert(
                            `Error checking email, Please try again or contact bigssupport@mlb.com`,
                            `danger`,
                        );
                        umpireGuestDispatch({
                            type: "validEmail",
                            value: false,
                        });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        setDuplicateEmail(true);
                        showAlert(
                            `Error checking email, Please try again or contact bigssupport@mlb.com`,
                            `danger`,
                        );
                        umpireGuestDispatch({
                            type: "validEmail",
                            value: false,
                        });
                    }
                });
        } else {
            setDuplicateEmail(false);
            umpireGuestDispatch({
                type: "validEmail",
                value: true,
            });
        }
    };
    return (
        <StyledBody>
            <div className="d-flex justify-space-between">
                <div className="col">
                    <RequiredLabel text="First Name" required />
                    <Container>
                        <BigsInput
                            onChange={event => {
                                umpireGuestDispatch({
                                    type: "firstName",
                                    value: event.target.value,
                                });
                            }}
                            value={umpireGuestState.firstName}
                        />
                    </Container>
                </div>
                <div className="col">
                    <RequiredLabel text="Last Name" required />
                    <Container>
                        <BigsInput
                            onChange={event => {
                                umpireGuestDispatch({
                                    type: "lastName",
                                    value: event.target.value,
                                });
                            }}
                            value={umpireGuestState.lastName}
                        />
                    </Container>
                </div>
            </div>
            <div className="d-flex justify-space-between mt-3">
                <div className="col">
                    <RequiredLabel text="Middle Name" required={false} />
                    <Container>
                        <BigsInput
                            onChange={event => {
                                umpireGuestDispatch({
                                    type: "middleName",
                                    value: event.target.value,
                                });
                            }}
                            value={umpireGuestState.middleName}
                        />
                    </Container>
                </div>
                <div className="col">
                    <RequiredLabel text="Nickname" required={false} />
                    <Container>
                        <BigsInput
                            onChange={event => {
                                umpireGuestDispatch({
                                    type: "nickName",
                                    value: event.target.value,
                                });
                            }}
                            value={umpireGuestState.nickName}
                        />
                    </Container>
                </div>
            </div>
            <div className="d-flex justify-space-between mt-3">
                <div className="col">
                    <RequiredLabel text="Email" required />
                    <Container>
                        <EmailFormElement
                            emailVal={umpireGuestState.email}
                            validEmail={validEmail}
                            duplicateEmail={duplicateEmail}
                            assignEmailState={assignEmailState}
                            emailOnBlur={emailOnBlur}
                            widthPercentage={98}
                        />
                    </Container>
                </div>
                <div className="col">
                    <RequiredLabel text="Relationship" required />
                    <Container>
                        <BigsInput
                            onChange={event => {
                                umpireGuestDispatch({
                                    type: "relationship",
                                    value: event.target.value,
                                });
                            }}
                            value={umpireGuestState.relationship}
                        />
                    </Container>
                </div>
            </div>
            <div className="ml-3 mt-3">
                <RequiredLabel text="Does the guest live in the US?" required />
                <div className="d-flex">
                    <div className="d-flex mr-2 align-items-center">
                        <BigsRadio
                            value="yes"
                            onChange={() => {
                                umpireGuestDispatch({
                                    type: "international",
                                    value: false,
                                });
                            }}
                            checked={!umpireGuestState.international}
                        />
                        <RadioLabel>Yes</RadioLabel>
                    </div>
                    <div className="d-flex align-items-center">
                        <BigsRadio
                            value="no"
                            onChange={() => {
                                umpireGuestDispatch({
                                    type: "international",
                                    value: true,
                                });
                            }}
                            checked={umpireGuestState.international}
                        />
                        <RadioLabel>No</RadioLabel>
                    </div>
                </div>
            </div>
        </StyledBody>
    );
};

type CreateNewUmpireGuestModalFooterProps = {
    umpireGuestState: NewUmpireGuestState;
    createNewUmpireGuestCallback: Function;
};

const CreateNewUmpireGuestModalFooter: React.FC<CreateNewUmpireGuestModalFooterProps> = ({
    umpireGuestState,
    createNewUmpireGuestCallback,
}) => {
    const { userInfo, isAdmin } = useAuth();
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();

    const addGuestFn = () => {
        const umpireOrgIdFromStorage = localStorage.getItem("umpireOrgId");
        const umpireIdFromStorage = localStorage.getItem("umpireId");
        const newUmpireGuestDTO: NewUmpireGuestDtoType = {
            personnel: {
                firstName: umpireGuestState.firstName,
                lastName: umpireGuestState.lastName,
                middleName: umpireGuestState.middleName,
                nickName: umpireGuestState.middleName,
                email: umpireGuestState.email,
                affiliation: {
                    id: isAdmin() ? parseInt(umpireIdFromStorage) : userInfo.affiliateId,
                    orgId: isAdmin() ? parseInt(umpireOrgIdFromStorage) : userInfo.orgId,
                },
            },
            umpireGuestAdditionalInfo: {
                relationship: umpireGuestState.relationship,
                international: umpireGuestState.international,
            },
        };
        addUmpireGuest(newUmpireGuestDTO)
            .then(() => {
                showAlert("Success", "success");
            })
            .catch(() => {
                showAlert("Error adding new umpire guest. Contact bigssupport@mlb.com", "danger");
            });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={127}
                    height={26}
                    disabled={!umpireGuestState.isValid || !umpireGuestState.validEmail}
                    onClick={event => {
                        event.stopPropagation();
                        addGuestFn();
                        setTimeout(() => {
                            closeModal();
                            createNewUmpireGuestCallback();
                        }, [750]);
                    }}
                >
                    Add Guest
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

CreateNewUmpireGuestModalBody.displayName = "BodyContent";
CreateNewUmpireGuestModalFooter.displayName = "FooterContent";

export { CreateNewUmpireGuestModalBody, CreateNewUmpireGuestModalFooter };
