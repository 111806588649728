import React, { useState, useRef, useEffect } from "react";
import { RequiredLabel, BigsRadio } from "../../../FormElements";
import UncontrolledInput from "../../../UncontrolledInput";
import { DatePickerInput } from "best-common-react";
import Moment from "moment";
import { BigsSelect, BigsInput } from "../../../BigsBcrWrapper";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

const StopPropagationWrapper: React.FC = ({ children }) => (
    <div className="mr-1" style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
        {children}
    </div>
);

type TypeProps = {
    name: string;
    label: string;
    value: any;
    onChange(value: any): void;
    required: boolean;
    options?: [];
    numsOnly?: boolean;
};

const TextInputType: React.FC<TypeProps> = ({
    name,
    label,
    value,
    required,
    onChange,
    numsOnly,
}) => {
    const [localVal, setLocalVal] = useState(value);

    const localOnChange = (value: string) => {
        setLocalVal(value);
        onChange(value);
    };

    return (
        <StopPropagationWrapper>
            <RequiredLabel required={required} text={label} />
            <BigsInput
                onChange={event => {
                    localOnChange(
                        numsOnly ? event.target.value.replace(/\D/, "") : event.target.value,
                    );
                }}
                value={localVal}
                height={36}
            />
        </StopPropagationWrapper>
    );
};

const WrappedDateInput = styled(DatePickerInput).attrs(() => ({}))`
    &&& {
        height: 36px;
        font-size: 14px;
    }
`;

const DateInputType: React.FC<TypeProps> = ({ name, label, value, onChange, required }) => {
    return (
        <div className="mr-1" style={{ width: "100%" }}>
            <RequiredLabel required={required} text={label} />
            <div style={{ width: "100%" }}>
                <WrappedDateInput
                    onChange={onChange}
                    value={value}
                    showTimeSelect={false}
                    popperPlacement="bottom-start"
                    minDate={Moment(new Date("1/1/1930").valueOf())}
                    maxDate={Moment(new Date().valueOf())}
                />
            </div>
        </div>
    );
};

const DropdownType: React.FC<TypeProps> = ({ name, label, value, onChange, required, options }) => {
    const [val, setVal] = useState(value);

    const dropdownOnChange = (newVal: any) => {
        setVal(newVal);
        onChange(newVal);
    };
    return (
        <div className="mr-1" style={{ width: "100%" }}>
            <RequiredLabel required={required} text={label} />
            <div style={{ width: "100%" }}>
                <BigsSelect
                    onChange={newVal => {
                        dropdownOnChange(newVal);
                    }}
                    options={options}
                    value={val}
                    styles={{
                        container: {
                            height: "36px",
                            fontSize: "14px",
                        },
                        control: {
                            height: "36px",
                            minHeight: "31px",
                        },
                        valueContainer: {
                            padding: "0 8px 0 8px",
                        },
                    }}
                    portal={document.body}
                />
            </div>
        </div>
    );
};

const RadioLabel = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 14px;
    }
`;

const RadioType: React.FC<TypeProps> = ({ name, label, value, onChange, required }) => {
    const [val, setVal] = useState(false);

    const localOnChange = () => {
        setVal(!val);
        onChange(!val);
    };

    return (
        <div style={{ width: "100%" }} className="d-flex ml-1 align-items-center mb-2">
            <RequiredLabel required={required} text={label} />
            <div className="d-flex mr-2 ml-5 align-items-center">
                <BigsRadio
                    value="yes"
                    onChange={() => {
                        localOnChange();
                    }}
                    checked={val}
                />
                <RadioLabel>Yes</RadioLabel>
            </div>
            <div className="d-flex align-items-center">
                <BigsRadio
                    value="no"
                    onChange={() => {
                        localOnChange();
                    }}
                    checked={!val}
                />
                <RadioLabel>No</RadioLabel>
            </div>
        </div>
    );
};

const LinkLabel = styled.div.attrs(() => ({
    className: "",
}))`
    color: var(--blue);
    cursor: pointer;
    font-size: 14px;
    width: 190px;
`;

const DeleteIcon = styled.i.attrs(() => ({
    className: "fa fa-trash",
}))`
    color: var(--blue);
    font-size: 18px;
    line-height: 1.8;
`;

const LinkType: React.FC<TypeProps> = ({ name, label, value, onChange, required }) => {
    const [aliasNameList, setAliasNameList] = useState([]);

    const nameChangeHandler = (index: number, fieldName: string, value: string) => {
        const newAliasNameList = [...aliasNameList];
        newAliasNameList[index][fieldName] = value;
        setAliasNameList(newAliasNameList);
        onChange(newAliasNameList);
    };

    const addAliasHandler = () => {
        const emptyRow = aliasNameList.filter(
            (a: any) => !a.firstName.trim() && !a.middleName.trim() && !a.lastName.trim(),
        );
        // No need to add new row if there is empty row available.
        if (emptyRow.length > 0) {
            return;
        }
        const newAliasNameList = [...aliasNameList];
        newAliasNameList.push({
            firstName: "",
            middleName: "",
            lastName: "",
        });
        setAliasNameList(newAliasNameList);
        onChange(newAliasNameList);
    };

    const removeAliasRow = (index: number) => {
        const newAliasNameList = [...aliasNameList];
        newAliasNameList.splice(index, 1);
        setAliasNameList(newAliasNameList);
        onChange(newAliasNameList);
    };

    useEffect(() => {
        setAliasNameList(value);
    }, [value]);

    return (
        <div className="ml-1 mt-2 mb-2" style={{ width: "100%" }}>
            <div>
                <LinkLabel onClick={addAliasHandler}>{label}</LinkLabel>
            </div>
            {aliasNameList &&
                aliasNameList.map((name: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Row key={index} className="no-gutters my-1">
                        <Col md={4} className="mr-2">
                            <BigsInput
                                placeholder="first name"
                                onChange={event => {
                                    nameChangeHandler(index, "firstName", event.target.value);
                                }}
                                value={name.firstName}
                            />
                        </Col>
                        <Col md={3} className="mr-2">
                            <BigsInput
                                placeholder="middle"
                                onChange={event => {
                                    nameChangeHandler(index, "middleName", event.target.value);
                                }}
                                value={name.middleName}
                            />
                        </Col>
                        <Col md={4} className="mr-2">
                            <BigsInput
                                placeholder="last name"
                                onChange={event => {
                                    nameChangeHandler(index, "lastName", event.target.value);
                                }}
                                value={name.lastName}
                            />
                        </Col>
                        <Col>
                            <DeleteIcon onClick={() => removeAliasRow(index)} />
                        </Col>
                    </Row>
                ))}
        </div>
    );
};

export { TextInputType, DateInputType, DropdownType, LinkType, RadioType };
