import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/consentform`;

export const getAllConsentFormSections = (): Promise<any> =>
    axios.get(`${API_URL}/`).then(response => response.data);

export const getConsentFormSectionBySectionName = (sectionName: string): Promise<any> =>
    axios.get(`${API_URL}/${sectionName}`).then(response => response.data);

export const updateConsentFormSectionBySectionName = (
    sectionName: string,
    sectionNameHTML: string,
): Promise<any> => {
    const postData = {
        sectionName: sectionName,
        sectionHtml: encodeURIComponent(sectionNameHTML),
    };
    return axios.post(`${API_URL}/${sectionName}/update`, postData).then(response => response.data);
};

export const getDrugTestExemptStatus = (personnelId: number): Promise<any> =>
    axios.get(`${API_URL}/dtexempt/${personnelId}`).then(response => response.data);

export const uploadBgcConsentForm = (
    personnelId: number,
    rosterId: number,
    uploadFileDTO: { fileBase64String: string | ArrayBuffer; fileName: string },
): Promise<any> =>
    axios
        .post(`${API_URL}/uploadBgcConsentForm/${personnelId}/${rosterId}`, uploadFileDTO)
        .then(response => response.data);

export const getDrugTestExemptStatusByPersonnelIdList = (
    personnelIdList: Array<number>,
): Promise<any> =>
    axios
        .get(`${API_URL}/dtexempt?personnelIdList=${personnelIdList}`)
        .then(response => response.data);

export const uploadDtConsentForm = (
    personnelId: number,
    drugTestConsentFormFileDTO: {
        fileBase64String: string | ArrayBuffer;
        fileName: string;
    },
): Promise<any> =>
    axios
        .post(`${API_URL}/uploadDtConsentForm/${personnelId}`, drugTestConsentFormFileDTO)
        .then(response => response.data);

export const getJurisdictionsAvailableForUpload = (personnelId: number): Promise<any> =>
    axios
        .get(`${API_URL}/getJurisdictionsAvailableForUpload/${personnelId}`)
        .then(response => response.data);

export const uploadConsentFormContent = (
    consentFormUploadDTO: any,
    personnelId: number,
    jurisdiction: string,
): Promise<any> =>
    axios
        .post(
            `${API_URL}/uploadConsentFormContent/${personnelId}/${jurisdiction}`,
            consentFormUploadDTO,
        )
        .then(response => response.data);

export const sendAdditionalConsentForm = (
    personnelId: number,
    jurisdiction: string,
): Promise<any> =>
    axios
        .post(`${API_URL}/sendAdditionalConsentForm/${personnelId}?jurisdiction=${jurisdiction}`)
        .then(response => response.data);
