import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/season/`;

export const isNewSeasonProcessCompleted = (): Promise<any> =>
    axios.get(API_URL + "processComplete").then(response => response.data);

export const getUmpireGuestSeason = (): Promise<any> =>
    axios.get(API_URL + "umpireGuest").then(response => response.data);

export const getAllPastUmpireGuestSeasons = (): Promise<any> =>
    axios.get(API_URL + "umpireGuest/past").then(response => response.data);
