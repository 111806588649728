import React, { useState, useEffect, ReducerAction, Dispatch } from "react";
import styled from "styled-components";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import SSNinput from "../SSNinput";
import {
    getPersonnelError,
    updatePersonnelSsnOrDobAndSubmitToBGC,
} from "../../../api/PersonnelApi";
import { useAlert } from "../../../contexts/AlertsContext";
import DobInput from "../DobInput";
import Moment from "moment";

export type PersonnelFormStatusInfo = {
    formId: number;
    personnelId: number;
    ssn: string;
    newSSN: string;
    dob: string;
};

type ReviewErrorsModalBodyProps = {
    personnelFormStatusInfo: PersonnelFormStatusInfo;
    reviewErrorsSSNstate: { ssnPartOne: string; ssnPartTwo: string; ssnPartThree: string };
    reviewErrorsDobState: { dobYear: string; dobMonth: string; dobDay: string };
    reviewErrorsDispatch: Dispatch<ReducerAction<(prevState: any, action: any) => any>>;
    reviewErrorsDobDispatch: Dispatch<ReducerAction<(prevState: any, action: any) => any>>;
    setIsCorrectingSsn: Function;
    setIsCorrectingDob: Function;
};

const ModalBodyStyle = styled.div.attrs(() => ({
    className: "m-4",
}))`
    height: 260px;
`;

const TextStyle = styled.div.attrs(() => ({}))`
    color: var(--slate);
    font-size: 16px;
`;

const ErrorMsgSection = styled.div.attrs(() => ({ className: "mb-4" }))`
    color: var(--slate);
    font-size: 16px;
`;

const ReviewErrorsModalBody: React.FC<ReviewErrorsModalBodyProps> = ({
    personnelFormStatusInfo,
    reviewErrorsSSNstate,
    reviewErrorsDobState,
    reviewErrorsDispatch,
    reviewErrorsDobDispatch,
    setIsCorrectingSsn,
    setIsCorrectingDob,
}) => {
    const [personnelInfo, setPersonnelInfo] = useState(personnelFormStatusInfo);
    const [bgcErrorMsgList, setBgcErrorMsgList] = useState([]);

    useEffect(() => {
        getPersonnelError(personnelInfo.personnelId).then((response: any) => {
            if (response) {
                setPersonnelInfo(response);
                const errorMsgList = response.errorMsg.match(/[^\[\]]+/g);
                setBgcErrorMsgList(errorMsgList);
            }
        });
    }, []);

    const ssnOnChange = (ssnSection: string, value: string) => {
        switch (ssnSection) {
            case "partOne":
                reviewErrorsDispatch({
                    type: "SET_SSN_PART_ONE",
                    ssnPartOne: value,
                });
                break;
            case "partTwo":
                reviewErrorsDispatch({
                    type: "SET_SSN_PART_TWO",
                    ssnPartTwo: value,
                });
                break;
            case "partThree":
                reviewErrorsDispatch({
                    type: "SET_SSN_PART_THREE",
                    ssnPartThree: value,
                });
                break;
        }
    };

    const dobOnChange = (dobSection: string, value: string) => {
        switch (dobSection) {
            case "dobYear":
                reviewErrorsDobDispatch({
                    type: "SET_DOB_YEAR",
                    dobYear: value,
                });
                break;
            case "dobMonth":
                reviewErrorsDobDispatch({
                    type: "SET_DOB_MONTH",
                    dobMonth: value,
                });
                break;
            case "dobDay":
                reviewErrorsDobDispatch({
                    type: "SET_DOB_DAY",
                    dobDay: value,
                });
                break;
        }
    };

    const getErrMsgSection = (errMsg: string) => {
        if (errMsg.toUpperCase() === "AN ORDER IS ALREADY BEING PROCESSED WITH THE SAME SSN") {
            setIsCorrectingSsn(true);
            return (
                <ErrorMsgSection>
                    <TextStyle>
                        An order is already being processed with the same SSN(
                        {personnelInfo.ssn})
                    </TextStyle>
                    <SSNinput ssnValue={reviewErrorsSSNstate} ssnOnChange={ssnOnChange} />
                </ErrorMsgSection>
            );
        } else if (
            errMsg.toUpperCase() === "DOB MUST BE IN THE FORMAT OF YYYY-MM-DD" ||
            errMsg.toUpperCase() === "PACKAGE REQUIRES A VALID DATE OF BIRTH OF CANDIDATE"
        ) {
            setIsCorrectingDob(true);
            return (
                <ErrorMsgSection>
                    <TextStyle>
                        Personnel or Volunteer cannot be processed because of the incorrect date of
                        birth. ({personnelInfo.dob})
                    </TextStyle>
                    <DobInput dobValues={reviewErrorsDobState} dobOnChange={dobOnChange} />
                </ErrorMsgSection>
            );
        } else {
            return (
                <ErrorMsgSection>
                    <TextStyle>{errMsg}</TextStyle>
                </ErrorMsgSection>
            );
        }
    };
    return (
        <ModalBodyStyle>
            {bgcErrorMsgList?.length > 0 &&
                bgcErrorMsgList.map(bgcErrorMsg => {
                    return getErrMsgSection(bgcErrorMsg);
                })}
        </ModalBodyStyle>
    );
};

type ReviewErrorsModalFooterProps = {
    personnelFormStatusInfo: PersonnelFormStatusInfo;
    ssnValue: { ssnPartOne: string; ssnPartTwo: string; ssnPartThree: string };
    dobValue: { dobYear: string; dobMonth: string; dobDay: string };
    setIsCorrectingSsn: Function;
    setIsCorrectingDob: Function;
    isCorrectingSsn: boolean;
    isCorrectingDob: boolean;
};

const ReviewErrorsModalFooter: React.FC<ReviewErrorsModalFooterProps> = ({
    personnelFormStatusInfo,
    ssnValue,
    dobValue,
    setIsCorrectingSsn,
    setIsCorrectingDob,
    isCorrectingSsn,
    isCorrectingDob,
}) => {
    const { closeModal } = useGlobalModal();
    const [newSSN, setNewSSN] = useState("");
    const [newDob, setNewDob] = useState("");
    const showAlert = useAlert();

    const submitToBGC = () => {
        const updatedPersonnelErrorDTO = {
            personnelId: personnelFormStatusInfo.personnelId,
            ssn: newSSN.length === 11 ? newSSN : null,
            dob: newDob.length === 10 ? newDob : null,
            formId: personnelFormStatusInfo.formId,
        };

        updatePersonnelSsnOrDobAndSubmitToBGC(updatedPersonnelErrorDTO)
            .then(() => {
                setIsCorrectingDob(false);
                setIsCorrectingSsn(false);
                showAlert("Submitted", "success");
            })
            .catch(() => {
                setIsCorrectingDob(false);
                setIsCorrectingSsn(false);
                showAlert("Error updating personnel. Please contact BIGS Systems.", "danger");
            });
    };

    useEffect(() => {
        setNewSSN(ssnValue.ssnPartOne + "-" + ssnValue.ssnPartTwo + "-" + ssnValue.ssnPartThree);
    }, [ssnValue]);

    useEffect(() => {
        setNewDob(dobValue.dobMonth + "/" + dobValue.dobDay + "/" + dobValue.dobYear);
    }, [dobValue]);

    const isValidSsnOrDob = () => {
        const isValidSsn = newSSN && newSSN.length === 11;
        const isValidDob =
            newDob && newDob.length === 10 && Moment(newDob, "MM/DD/YYY", true).isValid();

        if (isCorrectingSsn && isCorrectingDob) {
            return isValidSsn && isValidDob;
        } else if (isCorrectingSsn) {
            return isValidSsn;
        } else if (isCorrectingDob) {
            return isValidDob;
        } else {
            return true;
        }
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={134}
                    height={26}
                    disabled={!isValidSsnOrDob()}
                    onClick={event => {
                        submitToBGC();
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    {!isCorrectingSsn && !isCorrectingDob ? "Resubmit to BGC" : "Submit to BGC"}
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    setIsCorrectingDob(false);
                    setIsCorrectingSsn(false);
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

ReviewErrorsModalBody.displayName = "BodyContent";
ReviewErrorsModalFooter.displayName = "FooterContent";

export { ReviewErrorsModalBody, ReviewErrorsModalFooter };
