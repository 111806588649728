import React from "react";
import { useLoading } from "../../contexts/LoadingContext";
import Loader from "react-loader-spinner";

const BigsLoadingBlocker = () => {
    const { isLoading } = useLoading();
    return (
        <div>
            {isLoading && <Loader type="ThreeDots" color="var(--blue)" height={20} width={30} />}
        </div>
    );
};

export default BigsLoadingBlocker;
