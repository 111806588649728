import React from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";

const BodyContent = styled.div`
    text-align: left;
    margin-bottom: 100px;
    padding: 0.5rem;
`;

type AreYouSureModalBodyProps = {
    body: string;
};

const AreYouSureModalBodyContent: React.FC<AreYouSureModalBodyProps> = ({ body }) => {
    const {
        state: { content },
    } = useGlobalModal();
    return <BodyContent>{content || body}</BodyContent>;
};

const AreYouSureModalFooterContent = () => {
    const {
        state: { yesCallback },
        closeModal,
    } = useGlobalModal();

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                width={142}
                height={26}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    yesCallback();
                    closeModal();
                }}
            >
                Yes
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    No
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

AreYouSureModalBodyContent.displayName = "BodyContent";
AreYouSureModalFooterContent.displayName = "FooterContent";

export { AreYouSureModalBodyContent, AreYouSureModalFooterContent };
