import { countryOptions } from "../../../questionnaire/dropdownOptionDefs/CountryOptions";
import { usStatesOptions } from "../../../questionnaire/dropdownOptionDefs/UsStateOptions";
import { QuestionnaireType } from "../../../../contexts/QuestionnaireContext";

export const QuestionDefinitions = [
    {
        id: "countryLiveIn",
        order: 1,
        type: "DropDown",
        displayTextEn: "What country do you live in?",
        required: true,
        options: countryOptions,
        show: (state: QuestionnaireType) => {
            return true;
        },
    },
    {
        id: "otherCountryLiveIn",
        order: 2,
        type: "Text",
        displayTextEn: "What country?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.countryLiveIn.value === "other";
        },
    },
    {
        id: "stateLiveIn",
        order: 3,
        type: "DropDown",
        displayTextEn: "What state do you live in?",
        required: true,
        options: usStatesOptions,
        show: (state: QuestionnaireType) => {
            return state.countryLiveIn.value === "usPuerto";
        },
    },
    {
        id: "countryWorkIn",
        order: 4,
        type: "DropDown",
        displayTextEn: "What country do you work in?",
        required: true,
        options: countryOptions,
        show: (state: QuestionnaireType) => {
            if (!state.countryLiveIn.value) {
                return false;
            } else if (state.countryLiveIn.value === "usPuerto") {
                if (!state.stateLiveIn.value) {
                    return false;
                }
            } else if (state.countryLiveIn.value === "other") {
                if (!state.otherCountryLiveIn) {
                    return false;
                }
            }
            return true;
        },
    },
    {
        id: "otherCountryWorkIn",
        order: 5,
        type: "Text",
        displayTextEn: "What country?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.countryWorkIn.value === "other";
        },
    },
    {
        id: "stateWorkIn",
        order: 6,
        type: "DropDown",
        displayTextEn: "What state do you work in?",
        required: true,
        options: usStatesOptions,
        show: (state: QuestionnaireType) => {
            return state.countryWorkIn.value === "usPuerto";
        },
    },
];
