import styled from "styled-components";

const DisabledButton = styled.button.attrs(() => ({
    name: "disabled-button",
}))`
  border: 1px solid;
  cursor: ${props => (props.disabled ? "disabled" : "pointer")};
  ${props => (props.disabled ? "background-color: #f5f7fa !important;" : "")}
  ${props => (props.disabled ? `color: #dee5ed !important;` : "")}
  ${props => (props.disabled ? "border-color: #dee5ed !important;" : "")}
`;

type PrimaryButtonStyledProps = {
    height: number;
    width: number;
};

export const PrimaryButtonStyled = styled(DisabledButton).attrs(() => ({
    className: "btn btn-primary justify-content-center d-flex",
    type: "submit",
}))<PrimaryButtonStyledProps>`
    width: ${props => `${props.width}px`};
    height: ${props => `${props.height}px`};
    border-radius: 6px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: #4c8cee;
    border-color: #4c8cee;
    color: #4c8cee;
    align-items: center;
    white-space: nowrap;
    /* firefox only */
    @supports (-moz-appearance: none) {
        line-height: ${props => `${props.height - 2}px`};
    }

    &&& {
        color: #ffffff;
        background-color: #4c8cee;
        border-color: #4c8cee;
    }
`;
