import React, { useContext, createContext, useState, SetStateAction, Dispatch } from "react";
import { getAllPersonnelWithFiltering } from "../api/PersonnelApi";
import { useAlert } from "./AlertsContext";
import axios from "axios";
import { getUmpireGuests } from "../api/UmpireGuestsApi";

type UmpirePersonnelContext = {
    umpirePersonnel: Array<any>;
    setUmpirePersonnel: Dispatch<SetStateAction<any[]>>;
    personnelAdvancedSearch: (
        arg1: any,
        arg2: Array<number>,
        arg3: Array<number>,
        arg4: string,
        arg5: string,
        arg6: number,
        cancelSource: any,
    ) => void;
    updatePersonnel: (updatedPersonnel: any) => void;
    personnelLoaded: boolean;
    umpireGuestsSearch: (arg1: number, arg2: number, arg4: any) => void;
};

const UmpirePersonnelContext = createContext<UmpirePersonnelContext>({
    umpirePersonnel: [],
    setUmpirePersonnel: null,
    personnelAdvancedSearch: null,
    updatePersonnel: null,
    personnelLoaded: null,
    umpireGuestsSearch: null,
});

const UmpirePersonnelContextProvider: React.FC = ({ children }) => {
    const showAlert = useAlert();
    const [umpirePersonnel, setUmpirePersonnel] = useState<any[]>([]);
    const [personnelLoaded, setPersonnelLoaded] = useState<boolean>(false);

    const updatePersonnel = (updatedPersonnel: any) => {
        const oldPersonnelIndex = umpirePersonnel.findIndex(
            personnel => personnel.id === updatedPersonnel.id,
        );
        if (oldPersonnelIndex > 0) {
            const newAdminPersonnel = [...umpirePersonnel];
            newAdminPersonnel.splice(oldPersonnelIndex, 0, updatedPersonnel);
            setUmpirePersonnel([...newAdminPersonnel]);
        }
    };

    const personnelAdvancedSearch = (
        searchText: any,
        orgIds: Array<number>,
        statuses: Array<number>,
        personnelType: string,
        bgcScore: string,
        seasonId: number,
        cancelSource: any,
    ) => {
        const personnelSearch = {
            searchText: searchText,
            orgIds: orgIds,
            statuses: statuses,
            personnelType: personnelType,
            bgcScore: bgcScore,
            seasonId: seasonId,
        };
        setPersonnelLoaded(false);
        getAllPersonnelWithFiltering(personnelSearch, cancelSource)
            .then(response => {
                // This is for the BestTable to disable the expand/collapse
                response.forEach((entity: any) => (entity.disabled = entity.locked));
                setUmpirePersonnel(response);
                setPersonnelLoaded(true);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    showAlert(`Error retrieving personnel, Please contact BIGS Systems.`, `danger`);
                }
            });
    };

    const umpireGuestsSearch = (orgId: number, seasonId: number, cancelSource: any) => {
        setPersonnelLoaded(false);
        getUmpireGuests(orgId, seasonId, cancelSource)
            .then(response => {
                setUmpirePersonnel(response);
                setPersonnelLoaded(true);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    showAlert(
                        `Error retrieving umpire guests, Please contact BIGS Systems.`,
                        `danger`,
                    );
                }
            });
    };

    return (
        <UmpirePersonnelContext.Provider
            value={{
                umpirePersonnel,
                setUmpirePersonnel,
                personnelAdvancedSearch,
                updatePersonnel,
                personnelLoaded,
                umpireGuestsSearch,
            }}
        >
            {children}
        </UmpirePersonnelContext.Provider>
    );
};

const useUmpirePersonnel = (): UmpirePersonnelContext => {
    const context = useContext<UmpirePersonnelContext>(UmpirePersonnelContext);
    if (context === undefined) {
        throw new Error(`useUmpirePersonnel must be used within a UmpirePersonnelContextProvider`);
    }
    return context;
};

export { UmpirePersonnelContextProvider, useUmpirePersonnel };
