import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Questions from "./components/Questions";
import { Navbar } from "react-bootstrap";
import { QuestionnaireContextProvider } from "../../contexts/QuestionnaireContext";
import mlbLogo from "../../assets/mlb-logo.png";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { isValidUrlId } from "../../api/QuestionnaireApi";
import InvalidQuestionnaireLink from "./components/InvalidQuestionnaireLink";

const QuestionnaireContent = styled.div.attrs(() => ({}))`
    & .navbar-expand {
        justify-content: flex-end !important;
    }

    & .navbar {
        padding: 0rem !important;
    }
`;

const BottomQuestionsContent = styled.div.attrs(() => ({}))`
    margin-left: calc(100vw - 100%);
    display: flex;
    flex-flow: column nowrap;
    justify-contents: center;
    align-items: center;
`;

interface MatchParams {
    userId: string;
}

interface UrlStatus {
    loaded: boolean;
    valid: boolean;
    reason: string;
}
type MatchProps = RouteComponentProps<MatchParams>;
const QuestionnaireWrapper: React.FC<RouteComponentProps> = ({ match }: MatchProps) => {
    const [urlCheckStatus, setUrlCheckStatus] = useState<UrlStatus>({
        loaded: false,
        valid: true,
        reason: "",
    });

    useEffect(() => {
        require("../../AppQuestionnaire.css");
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const encryptedIdPayload = {
                    encryptedId: match.params.userId,
                };
                const result = await isValidUrlId(encryptedIdPayload);
                if (result) {
                    setUrlCheckStatus({
                        loaded: true,
                        valid: result.status === "SUCCEEDED" ? true : false,
                        reason: result.message,
                    });
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return (
        <QuestionnaireContextProvider>
            <QuestionnaireContent>
                <Navbar sticky="top" style={{ height: "30px", backgroundColor: "var(--MLB-navy)" }}>
                    <img src={mlbLogo} style={{ height: "30px" }} />
                </Navbar>
                {urlCheckStatus.loaded && (
                    <BottomQuestionsContent>
                        {urlCheckStatus.valid ? (
                            <Questions />
                        ) : (
                            <InvalidQuestionnaireLink
                                valid={urlCheckStatus.valid}
                                reason={urlCheckStatus.reason}
                            />
                        )}
                    </BottomQuestionsContent>
                )}
            </QuestionnaireContent>
        </QuestionnaireContextProvider>
    );
};

export default withRouter(QuestionnaireWrapper);
