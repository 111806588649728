import React from "react";
import styled from "styled-components";

const SubSectionStyle = styled.div.attrs(() => ({
    className: "d-flex",
}))`
    height: 24px;
    background-color: #dee5ed;
    border-radius: 4px;
    width: 100%;
`;

const TextStyle = styled.div.attrs(() => ({}))`
    padding-top: 1px;
    padding-left: 12px;
    font-size: 14px;
    color: #041e42;
`;

type SubSectionHeaderProps = {
    title: string;
};

const SubSectionHeader: React.FC<SubSectionHeaderProps> = ({ title }) => {
    return (
        <SubSectionStyle>
            <TextStyle>{title}</TextStyle>
        </SubSectionStyle>
    );
};

export default SubSectionHeader;
