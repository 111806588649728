import React from "react";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import styled from "styled-components";
import { useAlert } from "../../../contexts/AlertsContext";
import { deleteProfileComment } from "../../../api/PersonnelProfileApi";

type DeleteCommentBodyProps = {};

const BodyContent = styled.div.attrs(() => ({
    className: "mt-4 ml-4",
}))`
    text-align: left;
    margin-bottom: 200px;
`;

const TopTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 16px;
        color: var(--slate);
        line-height: 24px;
        text-style: Helvetica;
    }
`;

const BottomTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 12px;
        color: var(--slate);
        text-style: Helvetica-Light;
        font-weight: 300;
    }
`;

const DeleteCommentBody: React.FC<DeleteCommentBodyProps> = ({}) => {
    return (
        <BodyContent>
            <TopTextStyle>
                Are you sure you want to delete the comment? Any file associated with the comment
                will also be deleted.
            </TopTextStyle>
            <BottomTextStyle>This is a permanent action</BottomTextStyle>
        </BodyContent>
    );
};

type DeleteCommentFooterProps = {
    commentId: number;
    getComments: Function;
    closeModal: Function;
};

const DeleteCommentFooter: React.FC<DeleteCommentFooterProps> = ({
    commentId,
    closeModal,
    getComments,
}) => {
    const showAlert = useAlert();
    const deleteComment = () => {
        if (commentId) {
            deleteProfileComment(commentId)
                .then(() => {
                    showAlert("Deleted");
                    getComments();
                })
                .catch(() => {
                    showAlert(
                        "Error deleting profile comment, Please contact BIGS Systems",
                        "danger",
                    );
                });
        }
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                width={77}
                height={26}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                    deleteComment();
                }}
            >
                Delete
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={80}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

DeleteCommentBody.displayName = "BodyContent";
DeleteCommentFooter.displayName = "FooterContent";

export { DeleteCommentBody, DeleteCommentFooter };
