import React from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import ReactHtmlParser from "react-html-parser";

const BodyContent = styled.div`
    height: 250px;
    overflow: auto;
    padding: 0.5rem;
`;

type InfoModalBodyProps = {
    body: string;
};

const InfoModalBodyContent: React.FC<InfoModalBodyProps> = ({ body }) => {
    const {
        state: { content },
    } = useGlobalModal();
    return <BodyContent>{ReactHtmlParser(content || body)}</BodyContent>;
};

const InfoModalFooterContent = () => {
    const { closeModal } = useGlobalModal();

    return (
        <div className="d-flex">
            <OutlinedButtonStyled
                width={142}
                height={26}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Close
            </OutlinedButtonStyled>
        </div>
    );
};

InfoModalBodyContent.displayName = "BodyContent";
InfoModalFooterContent.displayName = "FooterContent";

export { InfoModalBodyContent, InfoModalFooterContent };
