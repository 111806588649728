import React, { useRef, useEffect } from "react";
import { BigsInput } from "./BigsBcrWrapper";

interface UncontrolledInputProps {
    timeout: number;
    postChange: (value: string) => void;
    required: boolean;
    className: string;
    defaultValue: string | any[];
    onBlur?: (arg0: any) => void;
    focusOut?: (arg0: any) => void;
    height?: number;
    ref?: any;
    [x: string]: any;
}

const isEmpty = (arg: string) => Boolean(!arg || !arg.trim());

/**
 * An input component that is uncontrolled. This component allows for
 * an action to be performed after the text in the box has been updated
 * after a specified customizable timeout.
 *
 */
const UncontrolledInput: React.FC<UncontrolledInputProps> = ({
    timeout,
    postChange,
    required,
    className,
    defaultValue,
    ...rest
}) => {
    const inputRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (defaultValue !== undefined) inputRef.current.value = defaultValue;
    }, [defaultValue]);

    const customClassName = className || "";
    const invalidClassName = Boolean(
        inputRef.current && required && isEmpty(inputRef.current.value),
    )
        ? "is-invalid"
        : "";

    const onFormChange = (e: { target: { value: any } }) => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(
            (value: string) => {
                if (postChange) {
                    postChange(value);
                }
            },
            timeout,
            e.target.value,
        );
    };

    return (
        <BigsInput
            ref={inputRef}
            className={`${invalidClassName} ${customClassName}`.trim()}
            onChange={onFormChange}
            {...rest}
        />
    );
};

UncontrolledInput.defaultProps = {
    timeout: 150,
};

export default UncontrolledInput;
