import { QuestionnaireType } from "../../contexts/QuestionnaireContext";
import { countryOptions } from "./dropdownOptionDefs/CountryOptions";
import { usStatesOptions } from "./dropdownOptionDefs/UsStateOptions";

export type DropDownOptions = {
    value: string;
    label: string;
};

export type QuestionDef = {
    id: string;
    order: number;
    type: string;
    displayTextEn?: string;
    displayTextEs?: string;
    required: boolean;
    options?: any;
    show: Function;
};

export const QuestionnaireTextDef = {
    title: {
        en: "Background Check Questionnaire",
        es: "Cuestionario para Verificación de Antecedentes",
    },
    subTitle: {
        en: "The answers to these questions will determine which consent forms you will receive.",
        es:
            "Las respuestas a estas preguntas determinarán qué formularios de consentimiento recibirán. ",
    },
    yes: {
        en: "Yes",
        es: "Sí",
    },
    no: {
        en: "No",
        es: "No",
    },
    submitButton: {
        en: "Submit and Proceed to Forms",
        es: "Enviar y Proceder a los Formularios",
    },
};

const QuestionsDefs = [
    {
        id: "language",
        order: 1,
        type: "DropDown",
        displayTextEn: "Preferred language?/ Idioma preferido?",
        displayTextEs: "Preferred language?/ Idioma preferido?",
        required: false,
        options: [
            { value: "english", label: "English" },
            { value: "spanish", label: "Spanish" },
        ],
        show: (state: QuestionnaireType) => {
            return true;
        },
    },
    {
        id: "over18",
        order: 2,
        type: "RadioYesNo",
        displayTextEn: "Are you over the age of 18 years old?",
        displayTextEs: "¿Eres mayor de 18 años?",
        required: true,
        show: (state: QuestionnaireType) => {
            return !!state.language.value;
        },
    },
    {
        id: "birthday",
        order: 3,
        type: "Date",
        displayTextEn: "Please enter your birthday",
        displayTextEs: "Por favor ingrese el mes y año en que nació.",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.over18 === "over18no";
        },
    },
    {
        id: "countryLiveIn",
        order: 4,
        type: "DropDown",
        displayTextEn: "What country do you live in?",
        displayTextEs: "¿En qué país vive usted? ",
        required: true,
        options: countryOptions,
        show: (state: QuestionnaireType) => {
            return state.over18 === "over18yes";
        },
    },
    {
        id: "otherCountryLiveIn",
        order: 5,
        type: "Text",
        displayTextEn: "What country?",
        displayTextEs: "¿Que pais?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.countryLiveIn.value === "other";
        },
    },
    {
        id: "stateLiveIn",
        order: 6,
        type: "DropDown",
        displayTextEn: "What state do you live in?",
        displayTextEs: "¿En qué estado vives?",
        required: true,
        options: usStatesOptions,
        show: (state: QuestionnaireType) => {
            return state.countryLiveIn.value === "usPuerto";
        },
    },
    {
        id: "liveInPortland",
        order: 7,
        type: "RadioYesNo",
        displayTextEn: "Do you live in Portland?",
        displayTextEs: "¿Usted vive en Portland?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateLiveIn.value === "OR";
        },
    },
    {
        id: "liveInPhiladelphia",
        order: 8,
        type: "RadioYesNo",
        displayTextEn: "Do you live in Philadelphia?",
        displayTextEs: "¿Usted vive en Filadelfia?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateLiveIn.value === "PA";
        },
    },
    {
        id: "liveInSf",
        order: 9,
        type: "RadioYesNo",
        displayTextEn: "Do you live in San Francisco?",
        displayTextEs: "¿Usted vive en San Francisco?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateLiveIn.value === "CA";
        },
    },
    {
        id: "countryWorkIn",
        order: 10,
        type: "DropDown",
        displayTextEn: "What country do you work in?",
        displayTextEs: "En que pais trabajas?",
        required: true,
        options: countryOptions,
        show: (state: QuestionnaireType) => {
            if (!state.countryLiveIn.value) {
                return false;
            } else if (state.countryLiveIn.value === "usPuerto") {
                if (!state.stateLiveIn.value) {
                    return false;
                } else if (state.stateLiveIn.value === "OR" && !state.liveInPortland) {
                    return false;
                } else if (state.stateLiveIn.value === "PA" && !state.liveInPhiladelphia) {
                    return false;
                } else if (state.stateLiveIn.value === "CA" && !state.liveInSf) {
                    return false;
                }
            } else if (state.countryLiveIn.value === "other") {
                if (!state.otherCountryLiveIn) {
                    return false;
                }
            }
            return true;
        },
    },
    {
        id: "otherCountryWorkIn",
        order: 11,
        type: "Text",
        displayTextEn: "What country?",
        displayTextEs: "¿Que pais",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.countryWorkIn.value === "other";
        },
    },
    {
        id: "stateWorkIn",
        order: 12,
        type: "DropDown",
        displayTextEn: "What state do you work in?",
        displayTextEs: "¿Dónde va a estar llevando a cabo su trabajo principalmente?",
        required: true,
        options: usStatesOptions,
        show: (state: QuestionnaireType) => {
            return state.countryWorkIn.value === "usPuerto";
        },
    },
    {
        id: "workInPortland",
        order: 13,
        type: "RadioYesNo",
        displayTextEn: "Do you work in Portland?",
        displayTextEs: "¿Va a estar trabajando en Portland?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateWorkIn.value === "OR";
        },
    },
    {
        id: "workInPhiladelphia",
        order: 14,
        type: "RadioYesNo",
        displayTextEn: "Do you work in Philadelphia?",
        displayTextEs: "¿Va a estar trabajando en Filadelfia?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateWorkIn.value === "PA";
        },
    },
    {
        id: "workInSf",
        order: 15,
        type: "RadioYesNo",
        displayTextEn: "Do you work in San Francisco?",
        displayTextEs: "¿Va a estar trabajando en San Francisco?",
        required: true,
        show: (state: QuestionnaireType) => {
            return state.stateWorkIn.value === "CA";
        },
    },
];

export default QuestionsDefs;
