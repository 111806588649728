import axios from "axios";
import { AcceptPersonnelDtoType } from "./PersonnelApi";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/roster`;

export type ExemptPersonnelDTO = {
    personnelId?: number;
    comment: string;
    drugTesting: boolean;
};

export const getRoster = (): Promise<any> =>
    axios.get(`${API_URL}`).then(response => response.data);

export const getAllRosterStatusCount = (personnelCountRequestDTO: {
    personnelTypeStr: string;
    clubIds: number[];
    seasonId?: number;
}): Promise<any> =>
    axios.post(`${API_URL}/count/status`, personnelCountRequestDTO).then(response => response.data);

export const certifyRoster = (): Promise<any> =>
    axios.post(`${API_URL}/certifyRoster`).then(response => response.data);

export const checkForCertification = (): Promise<any> =>
    axios.get(`${API_URL}/checkForCertification`).then(response => response.data);

export const exemptRoster = (
    rosterId: number,
    exemptPersonnelDTO: ExemptPersonnelDTO,
): Promise<any> =>
    axios.post(`${API_URL}/${rosterId}/exempt`, exemptPersonnelDTO).then(response => response.data);

export const acceptRoster = (
    rosterId: number,
    acceptPersonnelDTO: AcceptPersonnelDtoType,
): Promise<any> =>
    axios.post(`${API_URL}/${rosterId}/accept`, acceptPersonnelDTO).then(response => response.data);

export const getEventMenuOptions = (): Promise<any> =>
    axios.get(`${API_URL}/eventsByStatus`).then(response => response.data);

export const moveStatus = (personnelId: number, statusChangeDTO: any): Promise<any> =>
    axios
        .put(`${API_URL}/${personnelId}/changeStatus`, statusChangeDTO)
        .then(response => response.data);

export const markCompleteRoster = (rosterId: number, markCompleteDTO: any): Promise<any> =>
    axios
        .post(`${API_URL}/${rosterId}/markComplete`, markCompleteDTO)
        .then(response => response.data);

export const markCompleteNonSterling = (
    rosterId: number,
    markCompleteNonSterlingDto: any,
): Promise<any> =>
    axios
        .post(`${API_URL}/${rosterId}/markCompleteNonSterling`, markCompleteNonSterlingDto)
        .then(response => response.data);

export const bulkAccept = (bulkAcceptPersonnelDTO: any): Promise<any> =>
    axios.post(`${API_URL}/bulk/accept`, bulkAcceptPersonnelDTO).then(response => response.data);

export const bulkExempt = (bulkExemptPersonnelDTO: any): Promise<any> =>
    axios.post(`${API_URL}/bulk/exempt`, bulkExemptPersonnelDTO).then(response => response.data);

export const getPersonnelActivityFeed = (rosterId: number): Promise<any> =>
    axios.get(`${API_URL}/personnelActivityFeed/${rosterId}`).then(response => response.data);

export const bulkRolloverInProgress = (bulkPersonnelDTO: any): Promise<any> =>
    axios
        .post(`${API_URL}/bulk/rolloverInProgress`, bulkPersonnelDTO)
        .then(response => response.data);
