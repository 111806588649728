import React from "react";
import styled from "styled-components";

type InvalidQuestionnaireLinkProps = {
    valid: boolean;
    reason: string;
};

const InvalidUrlContent = styled.div.attrs(() => {})`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const Heading = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const MlbHeading = styled.div`
    margin-top: 30px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    color: var(--MLB-navy);
`;

const TitleMessageHeading = styled.div`
    font-family: Helvetica;
    font-weight: lighter;
    line-height: 2.5;
    font-size: 21px;
    color: var(--MLB-red);
`;

const BodyMessageDescription = styled.div`
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--slate);
`;

const SubmissionCompletedTextTop = styled.div`
    margin: 1rem 125px 0px 125px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--slate);
`;

const SubmissionCompletedTextBottom = styled.div`
    margin: 20px 125px 50px 125px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--blue-grey);
`;

const SubmissionSucceed = styled.div`
    color: var(--MLB-blue);
    font-size: 24px;
    text-align: center;
`;

const InvalidQuestionnaireLink: React.FC<InvalidQuestionnaireLinkProps> = ({ valid, reason }) => {
    const messageToRender = (reason: string) => {
        reason = reason.toUpperCase();
        if (reason === "EXPIRED") {
            return (
                <>
                    <MlbHeading>Major League Baseball</MlbHeading>
                    <TitleMessageHeading>
                        The link to the questionnaire has expired.
                    </TitleMessageHeading>
                    <BodyMessageDescription>
                        If you did not complete the questionnaire prior to the expiration, please
                        contact your
                    </BodyMessageDescription>
                    <BodyMessageDescription>
                        Club administrator in order to have a new questionnaire sent to you.
                    </BodyMessageDescription>
                    <BodyMessageDescription>
                        If you are trying to access your background check and/or drug testing
                        consent
                    </BodyMessageDescription>
                    <BodyMessageDescription>
                        form(s), please use the link in the email that was sent to you after you
                        submitted your
                    </BodyMessageDescription>
                    <BodyMessageDescription>questionnaire.</BodyMessageDescription>
                </>
            );
        } else if (reason === "ALREADY_COMPLETED_UNDER_18") {
            return (
                <>
                    <SubmissionCompletedTextTop />
                    <SubmissionSucceed>Thank you for your submission.</SubmissionSucceed>
                    <SubmissionCompletedTextTop>
                        You are currently ineligible to receive and complete a consent form as part
                        of the Background Check Procedures policy.
                    </SubmissionCompletedTextTop>
                    <SubmissionCompletedTextTop>
                        A questionnaire to determine your consent form will be sent to you after
                        your eighteenth birthday.
                    </SubmissionCompletedTextTop>
                    <SubmissionCompletedTextTop>
                        Should you have any questions, please contact your Club administrator.
                    </SubmissionCompletedTextTop>
                </>
            );
        } else if (reason === "ALREADY_COMPLETED") {
            return (
                <>
                    <SubmissionCompletedTextTop>
                        Thank you for completing the MLB Background Check Questionnaire.
                    </SubmissionCompletedTextTop>
                    <SubmissionCompletedTextTop>
                        Your responses to the questionnaire will generate forms specific to where
                        where you live and work.
                    </SubmissionCompletedTextTop>
                    <SubmissionCompletedTextTop>
                        You will receive an email with detailed instructions for completing and
                        submitting the required documents.
                    </SubmissionCompletedTextTop>
                    <SubmissionCompletedTextBottom>
                        Please note that all information collected in this process is transmitted
                        transmitted and maintained via a secure, encrypted system.
                    </SubmissionCompletedTextBottom>
                </>
            );
        } else if (
            reason === "INVALID_NOT_IN_DB" ||
            reason === "INVALID_CANNOT_DECODE" ||
            reason === "UNKNOWN"
        ) {
            return (
                <>
                    <MlbHeading>Major League Baseball</MlbHeading>
                    <TitleMessageHeading>This link is invalid.</TitleMessageHeading>
                    <BodyMessageDescription>
                        Please contact your Club administrator to have a valid questionnaire link
                        sent to you.
                    </BodyMessageDescription>
                    <BodyMessageDescription>
                        If you are trying to access your background check and/or drug testing
                        consent form(s), please
                    </BodyMessageDescription>
                    <BodyMessageDescription>
                        use the link in the email that was sent to you after you submitted your
                        questionnaire.
                    </BodyMessageDescription>
                </>
            );
        }
    };

    return (
        <InvalidUrlContent>
            <Heading>{messageToRender(reason)}</Heading>
        </InvalidUrlContent>
    );
};

export default InvalidQuestionnaireLink;
