import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";
import { getAffiliationsByOrgId, getAllAffiliations } from "../api/AffiliateApi";

type AffiliationContext = {
    affiliationDropdownOptions: Array<AffiliationDropdownOptionType>;
    setAffiliationDropdownOptions: Dispatch<SetStateAction<AffiliationDropdownOptionType[]>>;
    refreshAffiliationDropdownByOrgId: (orgId: number) => void;
    refreshAffiliationsDropdownAllOrgs: () => void;
};

type AffiliationDropdownOptionType = {
    value: number;
    label: string;
    teamId: number;
    orgId: number;
};

const AffiliationContext = createContext<AffiliationContext>({
    affiliationDropdownOptions: [],
    setAffiliationDropdownOptions: null,
    refreshAffiliationDropdownByOrgId: null,
    refreshAffiliationsDropdownAllOrgs: null,
});

const AffiliationContextProvider: React.FC = ({ children }) => {
    const [affiliationDropdownOptions, setAffiliationDropdownOptions] = useState<
        AffiliationDropdownOptionType[]
    >([]);

    const refreshAffiliationDropdownByOrgId = (orgId: number) => {
        getAffiliationsByOrgId(orgId).then(response => {
            setAffiliationDropdownOptions(
                response
                    .map((affiliate: any) => {
                        return {
                            value: affiliate.id,
                            label:
                                affiliate.level === "MLB"
                                    ? affiliate.name + " (Major)"
                                    : affiliate.name + " (" + affiliate.level.trim() + ")",
                            teamId: affiliate.teamId,
                            orgId: affiliate.orgId,
                            ...affiliate,
                        };
                    })
                    .sort((a: any, b: any) => a.label.localeCompare(b.label)),
            );
        });
    };

    const refreshAffiliationsDropdownAllOrgs = () => {
        getAllAffiliations().then(response => {
            if (response) {
                const filteredResponse = response
                    .map((club: { name: any; id: any; orgId: any }) => {
                        return {
                            label: club.name,
                            value: club.id,
                            orgId: club.orgId,
                            ...club,
                        };
                    })
                    .sort((a: any, b: any) => a.name.localeCompare(b.name));
                setAffiliationDropdownOptions(filteredResponse);
            }
        });
    };

    return (
        <AffiliationContext.Provider
            value={{
                affiliationDropdownOptions,
                setAffiliationDropdownOptions,
                refreshAffiliationDropdownByOrgId,
                refreshAffiliationsDropdownAllOrgs,
            }}
        >
            {children}
        </AffiliationContext.Provider>
    );
};

const useAffiliation = (): AffiliationContext => {
    const context = useContext<AffiliationContext>(AffiliationContext);
    if (context === undefined) {
        throw new Error(`useAffiliation must be used within a AffiliationContextProvider`);
    }
    return context;
};

export { AffiliationContextProvider, useAffiliation, AffiliationDropdownOptionType };
