import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import BigsAlerts from "./modules/elements/BigsAlerts";
import GlobalModal from "./modules/elements/modal/GlobalModal";
import QuestionnaireWrapper from "./modules/questionnaire/QuestionnaireWrapper";
import BigsProtectedContents from "./modules/common/BigsProtectedContents";
import { RouteConstants } from "./constants/RouteConstants";

const App: React.FC = () => {
    return (
        <Suspense fallback={<h1>loading...</h1>}>
            <BigsAlerts />
            <GlobalModal />
            <BrowserRouter>
                <Switch>
                    <Route
                        path={RouteConstants.QUESTIONNAIRE}
                        exact
                        component={QuestionnaireWrapper}
                    />
                    <Route component={BigsProtectedContents} />
                </Switch>
            </BrowserRouter>
        </Suspense>
    );
};

export default App;
