import React, { useEffect, useState } from "react";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import styled from "styled-components";
import { useAlert } from "../../../contexts/AlertsContext";
import { updatePersonnelStatus } from "../../../api/PersonnelApi";
import { useAffiliation } from "../../../contexts/AffiliationContext";
import { BigsSelect } from "../BigsBcrWrapper";
import { RequiredLabel } from "../FormElements";

type ReactivatePersonnelBodyProps = {
    personnel: any;
    callback: Function;
    selectedAffiliation: any;
    setSelectedAffiliation: Function;
};

const BodyContent = styled.div.attrs(() => ({
    className: "mt-4 ml-4",
}))`
    text-align: left;
    margin-bottom: 200px;
`;

const TopTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 16px;
        color: var(--slate);
        line-height: 24px;
        text-style: Helvetica;
    }
`;

const BottomTextStyle = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 12px;
        text-style: Helvetica;
        font-weight: 300;
        width: 400px;
    }
`;

const ReactivatePersonnelBody: React.FC<ReactivatePersonnelBodyProps> = ({
    personnel,
    setSelectedAffiliation,
    selectedAffiliation,
}) => {
    const { affiliationDropdownOptions } = useAffiliation();
    useEffect(() => {
        setSelectedAffiliation(affiliationDropdownOptions[0]);
    }, []);
    const affiliationChangeHandler = (affiliationOption: any) => {
        setSelectedAffiliation(affiliationOption);
    };

    return (
        <BodyContent>
            <TopTextStyle>
                Add {personnel.lastName}, {personnel.firstName} to the following Roster.
            </TopTextStyle>
            <div className="mr-1 mt-2">
                <RequiredLabel text="Affiliate" required />
                <BottomTextStyle>
                    <BigsSelect
                        value={selectedAffiliation}
                        isDisabled={false}
                        onChange={(affiliationOpt: any) => {
                            affiliationChangeHandler(affiliationOpt);
                        }}
                        options={affiliationDropdownOptions}
                        placeholder="select..."
                    />
                </BottomTextStyle>
            </div>
        </BodyContent>
    );
};

type ReactivatePersonnelFooterProps = {
    personnel: {
        id: number;
        status: string;
        affiliation: any;
    };
    closeModal: Function;
    callback: Function;
    selectedAffiliation: any;
};

const ReactivatePersonnelFooter: React.FC<ReactivatePersonnelFooterProps> = ({
    personnel,
    closeModal,
    callback,
    selectedAffiliation,
}) => {
    const showAlert = useAlert();
    const addPersonnelHandler = () => {
        updatePersonnelStatus(personnel.id, "ACTIVE", selectedAffiliation.teamId)
            .then(() => {
                if (callback && typeof callback === "function") {
                    callback(selectedAffiliation);
                }
                showAlert("Added to Roster");
            })
            .catch(() => {
                showAlert("Error adding personnel to roster", "danger");
            });
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                height={26}
                width={180}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                    addPersonnelHandler();
                }}
            >
                Add to Roster
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={80}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ReactivatePersonnelBody.displayName = "BodyContent";
ReactivatePersonnelFooter.displayName = "FooterContent";

export { ReactivatePersonnelBody, ReactivatePersonnelFooter };
