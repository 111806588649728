import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { BigsInput } from "../../BigsBcrWrapper";
import { RequiredLabel } from "../../FormElements";
import styled from "styled-components";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../../OutlinedButtonStyled";
import { useGlobalModal } from "../../../../contexts/GlobalModalContext";
import FileBrowser from "../../FileBrowser";
import { FileType, PersonnelInfoDtUploadType } from "../GlobalModal";
import { uploadDtConsentForm } from "../../../../api/ConsentFormApi";
import { useAlert } from "../../../../contexts/AlertsContext";

const ModalBodyStyle = styled.div.attrs(() => ({ className: "mt-4" }))`
    height: 260px;
    margin-left: 85px;
`;

type UploadDtConsentFormModalBodyProps = {
    dtUploadFile: FileType;
    setDtUploadFile: Dispatch<SetStateAction<FileType>>;
};

const UploadDtConsentFormModalBody: React.FC<UploadDtConsentFormModalBodyProps> = ({
    dtUploadFile,
    setDtUploadFile,
}) => {
    const onFileAdd = (file: any) => {
        if (file.length) {
            const fileToAdd: FileType = {
                name: file[0].name,
                fileBase64String: "",
                id: file[0].name + Math.floor(Math.random() * 100),
            };
            const reader = new FileReader();
            reader.onload = () => {
                fileToAdd.fileBase64String = reader.result;
                setDtUploadFile(fileToAdd);
            };
            reader.readAsDataURL(file[0]);
        }
    };

    const onFileDelete = () => {
        setDtUploadFile(null);
    };

    return (
        <ModalBodyStyle>
            <div>
                <RequiredLabel text="Drug Test Consent Form" required />
                <FileBrowser
                    onFileAdd={(file: any) => {
                        onFileAdd(file);
                    }}
                    file={dtUploadFile}
                    onFileDelete={onFileDelete}
                />
            </div>
        </ModalBodyStyle>
    );
};

type UploadDtConsentFormModalFooterProps = {
    personnelId: number;
    dtUploadFile: FileType;
};

const UploadDtConsentFormModalFooter: React.FC<UploadDtConsentFormModalFooterProps> = ({
    personnelId,
    dtUploadFile,
}) => {
    const showAlert = useAlert();
    const { closeModal } = useGlobalModal();
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(true);

    useEffect(() => {
        setUploadDisabled(!dtUploadFile);
    }, [dtUploadFile]);

    const onUploadClicked = () => {
        const drugTestConsentFormFileDTO = {
            fileName: dtUploadFile.name,
            fileBase64String: dtUploadFile.fileBase64String,
        };
        uploadDtConsentForm(personnelId, drugTestConsentFormFileDTO)
            .then(() => {
                showAlert("Uploaded", "success");
            })
            .catch(() => {
                showAlert(
                    "Error while uploading Drug Test Consent Form, Please contact BIGS Systems",
                    "danger",
                );
            });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={82}
                    height={26}
                    disabled={uploadDisabled}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                        onUploadClicked();
                    }}
                >
                    Upload
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

UploadDtConsentFormModalBody.displayName = "BodyContent";
UploadDtConsentFormModalFooter.displayName = "FooterContent";

export { UploadDtConsentFormModalBody, UploadDtConsentFormModalFooter };
