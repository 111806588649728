import React from "react";
import { AuthProvider, oktaAuth } from "./AuthContext";
import { LoadingContextProvider } from "./LoadingContext";
import HttpInterceptor from "../api/HttpInterceptor";
import { ThemeProvider } from "styled-components";
import { Theme } from "best-common-react";
import { SideMenuProvider } from "./SideMenuContext";
import { AffiliationContextProvider } from "./AffiliationContext";
import { AlertsProvider } from "./AlertsContext";
import { GlobalModalContextProvider } from "./GlobalModalContext";
import { SelectedPersonnelContextProvider } from "./SelectedPersonnelContext";
import { SettingsContextProvider } from "./SettingsContext";
import { AdminPersonnelContextProvider } from "./AdminPersonnelContext";
import { VolunteerPersonnelContextProvider } from "./VolunteerPersonnelContext";
import { VolunteersSideMenuContextProvider } from "./VolunteersSideMenuContext";
import { ProfileDisplayContextProvider } from "./ProfileDisplayContext";
import { UpdatedPersonnelContextProvider } from "./UpdatedPersonnelContext";
import { Security } from "@okta/okta-react";
import { ScreenBlockerContextProvider } from "./ScreenBlockerContext";
import { UmpiresSideMenuContextProvider } from "./UmpireGuestsSideMenuContext";
import { UmpirePersonnelContextProvider } from "./UmpirePersonnelContext";

type WrapperProviderProps = {
    children: React.ReactNode;
};

const WrapperProvider: React.FC<WrapperProviderProps> = ({ children }) => {
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        window.location.replace(originalUri);
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <AuthProvider>
                <LoadingContextProvider>
                    <HttpInterceptor>
                        <ThemeProvider theme={Theme}>
                            <ScreenBlockerContextProvider>
                                <UpdatedPersonnelContextProvider>
                                    <ProfileDisplayContextProvider>
                                        <AlertsProvider>
                                            <GlobalModalContextProvider>
                                                <SideMenuProvider>
                                                    <VolunteersSideMenuContextProvider>
                                                        <UmpiresSideMenuContextProvider>
                                                            <SettingsContextProvider>
                                                                <AffiliationContextProvider>
                                                                    <SelectedPersonnelContextProvider>
                                                                        <AdminPersonnelContextProvider>
                                                                            <VolunteerPersonnelContextProvider>
                                                                                <UmpirePersonnelContextProvider>
                                                                                    {children}
                                                                                </UmpirePersonnelContextProvider>
                                                                            </VolunteerPersonnelContextProvider>
                                                                        </AdminPersonnelContextProvider>
                                                                    </SelectedPersonnelContextProvider>
                                                                </AffiliationContextProvider>
                                                            </SettingsContextProvider>
                                                        </UmpiresSideMenuContextProvider>
                                                    </VolunteersSideMenuContextProvider>
                                                </SideMenuProvider>
                                            </GlobalModalContextProvider>
                                        </AlertsProvider>
                                    </ProfileDisplayContextProvider>
                                </UpdatedPersonnelContextProvider>
                            </ScreenBlockerContextProvider>
                        </ThemeProvider>
                    </HttpInterceptor>
                </LoadingContextProvider>
            </AuthProvider>
        </Security>
    );
};

export default WrapperProvider;
