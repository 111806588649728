import { withRouter, RouteComponentProps } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormUtil from "../../../util/FormUtil";
import ReactOverflowTooltip from "react-overflow-tooltip";
import { useProfileDisplay } from "../../../contexts/ProfileDisplayContext";

interface NameFormatterPropsWithStatus extends RouteComponentProps {
    row: any;
}

type RowTextStyleProps = {
    disabled?: boolean;
};

const RowTextStyle = styled.div.attrs(() => ({}))<RowTextStyleProps>`
    &&& {
        font-size: 14px;
        &:hover {
            cursor: ${props => (!props.disabled ? "pointer" : "default")};
        }
        color: ${props => (props.disabled ? "var(--blue-grey)" : "var(--blue)")};
    }
`;

const AdminRosterTableNameFormatterWithStatus: React.FC<NameFormatterPropsWithStatus> = ({
    history,
    row,
}) => {
    const [displayName, setDisplayName] = useState<string>("");
    const { setDisplayProfile, setPersonnelId } = useProfileDisplay();
    const nameOnClick = () => {
        setPersonnelId(row.id.toString());
        setDisplayProfile(true);
    };

    useEffect(() => {
        let name = row.lastName + ", " + row.firstName;

        if (row.middleName) {
            name += " " + row.middleName?.substring(0, 1);
        }

        if (row.suffix) {
            name += "., " + row.suffix;
        }

        setDisplayName(name);
    }, [row]);

    const subStatus = row.status;

    return (
        <>
            {row.isSubRow ? (
                <div>{FormUtil.formatJurisdiction(row.jurisdiction + " - " + subStatus)}</div>
            ) : (
                <>
                    {row.locked ? (
                        <ReactOverflowTooltip title={displayName}>
                            <RowTextStyle disabled>{displayName}</RowTextStyle>
                        </ReactOverflowTooltip>
                    ) : (
                        <ReactOverflowTooltip title={displayName}>
                            <RowTextStyle onClick={nameOnClick}>{displayName}</RowTextStyle>
                        </ReactOverflowTooltip>
                    )}
                </>
            )}
        </>
    );
};

export default withRouter(AdminRosterTableNameFormatterWithStatus);
