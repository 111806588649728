import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/profile`;

export type PersonnelProfileDtoType = {
    profileId: number;
    personnelId: number;
    affiliationId: number;
    firstName: string;
    lastName: string;
    title: string;
    nickName: string;
    email: string;
    language: string;
    middleName: string;
    suffix: string;
    tier: string;
    aliasNameList: string | [];
    role: number;
    gender: number;
    location: number;
};

export const getProfileById = (id: string): Promise<any> =>
    axios.get(`${API_URL}/${id}`).then(response => response.data);

export const updateProfile = (personnelProfileDTO: PersonnelProfileDtoType): Promise<any> =>
    axios.post(`${API_URL}/`, personnelProfileDTO).then(response => response.data);

export const getProfileComments = (personnelId: string): Promise<any> =>
    axios.get(`${API_URL}/comments/${personnelId}`).then(response => response.data);

export const addProfileComment = (personnelId: number, commentDto: any): Promise<any> =>
    axios
        .post(`${API_URL}/comments/${personnelId}`, commentDto, {
            headers: { "content-type": "application/json" },
        })
        .then(response => response.data);

export const deleteProfileComment = (commentId: number): Promise<any> =>
    axios.delete(`${API_URL}/comments/${commentId}`).then(response => response.data);

export const getPersonnelFormStatuses = (personnelId: string): Promise<any> =>
    axios.get(`${API_URL}/personnelFormStatuses/${personnelId}`).then(response => response.data);
