import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from "react";
import { getUserInfo } from "../api/AuthApi";
import OktaConfig from "../config/OktaConfig";
// @ts-ignore
import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

const oktaAuth = new OktaAuth(OktaConfig.oidc);

type UserInfo = {
    accessToken: { accessToken: string };
    userName: string;
    userFullName: string;
    email: string;
    affiliateId: number;
    orgId: number;
    orgName: string;
    orgShortName: string;
    roles: Array<string>;
};

type UserContext = {
    userInfo: UserInfo;
    setUserInfo: Dispatch<SetStateAction<UserInfo>>;
    loggedIn: boolean;
    isAdmin: () => boolean;
    isUser: () => boolean;
    isUmpire: () => boolean;
    login: (data: UserInfo) => void;
    logout: () => void;
    permissionError: boolean;
    loginFunction: SetStateAction<void>;
    setPermissionError: Dispatch<SetStateAction<boolean>>;
    setLoginFunction: Dispatch<SetStateAction<void>>;
    userInfoFailed: boolean;
    setUserInfoFailed: Dispatch<SetStateAction<boolean>>;
    oktaLoginFunc: { loginFunction: Function };
    setOktaLoginFunc: Dispatch<SetStateAction<{ loginFunction: Function }>>;
};

const AuthContext = createContext<UserContext>({
    userInfo: null,
    setUserInfo: null,
    loggedIn: false,
    login: null,
    logout: null,
    isAdmin: null,
    isUser: null,
    isUmpire: null,
    permissionError: false,
    loginFunction: null,
    setPermissionError: null,
    setLoginFunction: null,
    userInfoFailed: false,
    setUserInfoFailed: null,
    oktaLoginFunc: null,
    setOktaLoginFunc: null,
});

const initialState = {
    userInfo: {
        accessToken: { accessToken: "" },
        userName: "",
        userFullName: "",
        email: "",
        affiliateId: 0,
        orgId: 0,
        orgName: "",
        orgShortName: "",
        roles: ["NONE"],
    },
    loggedIn: false,
};

const AuthProvider: React.FC = ({ children }) => {
    const [userInfo, setUserInfo] = useState<UserInfo>(initialState.userInfo);
    const [loggedIn, setLoggedIn] = useState<boolean>(initialState.loggedIn);
    const [permissionError, setPermissionError] = useState<boolean>(false);
    const [loginFunction, setLoginFunction] = useState(null);
    const [userInfoFailed, setUserInfoFailed] = useState<boolean>(false);
    const [oktaLoginFunc, setOktaLoginFunc] = useState(null);
    const { authState } = useOktaAuth();

    const login = async (userData: UserInfo) => {
        const userInfoData = await getUserInfo();
        if (userInfoData) {
            userData = { ...userData, ...userInfoData };
            setUserInfo(userData);
            setLoggedIn(true);
        }
    };

    const logout = () => {
        console.log("logout");
        localStorage.clear();
        setUserInfo(initialState.userInfo);
        setLoggedIn(false);
    };

    const isAdmin = () => {
        return userInfo.roles.includes("ADMIN");
    };

    const isUser = () => {
        return userInfo.roles.includes("CLUB");
    };

    const isUmpire = () => {
        return userInfo.roles.includes("UMPIRE");
    };

    useEffect(() => {
        if (authState?.isAuthenticated) {
            login({
                email: "",
                affiliateId: 0,
                orgId: 0,
                orgName: "",
                orgShortName: "",
                roles: undefined,
                userFullName: "",
                userName: "",
                accessToken: authState.accessToken,
            });
        }
    }, [authState]);

    return (
        <AuthContext.Provider
            value={{
                userInfo,
                setUserInfo,
                loggedIn,
                isAdmin,
                isUser,
                isUmpire,
                login,
                logout,
                permissionError,
                setPermissionError,
                loginFunction,
                setLoginFunction,
                userInfoFailed,
                setUserInfoFailed,
                oktaLoginFunc,
                setOktaLoginFunc,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): UserContext => {
    const authContext = useContext<UserContext>(AuthContext);
    if (authContext === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return authContext;
};

export { AuthContext, AuthProvider, useAuth, oktaAuth };
