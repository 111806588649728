import axios from "axios";
import { BestTable } from "best-common-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactOverflowTooltip from "react-overflow-tooltip";
import styled from "styled-components";
import { getAllPastUmpireGuestSeasons } from "../../../api/SeasonApi";
import { addPastGuests, getPastUmpireGuests } from "../../../api/UmpireGuestsApi";
import { useAlert } from "../../../contexts/AlertsContext";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { DateUtil } from "../../../util/DateUtil";
import { BigsSelect } from "../BigsBcrWrapper";
import { BigsBestTable } from "../BigsBestTable";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import UncontrolledInput from "../UncontrolledInput";
import { FormatterProps } from "./bulkpersonnel/BulkCommon";

type AddPastUmpireGuestModalBodyProps = {
    selectedPastUmpireGuests: any;
    setSelectedPastUmpireGuests: Dispatch<SetStateAction<any[]>>;
    umpireId: number;
    currNumOfGuests: number;
};

interface NameFormatterProps {
    row: any;
}

const AddPastUmpireGuestBodyContainerStyle = styled.div.attrs({
    className: "m-3",
})``;

type RowTextStyleProps = {
    color?: string;
    disabled?: boolean;
};

const RowTextStyle = styled.div.attrs(() => ({}))<RowTextStyleProps>`
    font-size: 14px;
    ${props => (props.color ? "color: " + props.color : "")};
`;

const UmpireGuestNameFormatter: React.FC<NameFormatterProps> = ({ row }) => {
    const [displayName, setDisplayName] = useState<string>("");

    useEffect(() => {
        let name = row.personnel.lastName + ", " + row.personnel.firstName;

        if (row.personnel.middleName) {
            name += " " + row.personnel.middleName?.substring(0, 1);
        }

        if (row.personnel.suffix) {
            name += "., " + row.personnel.suffix;
        }

        setDisplayName(name);
    }, [row]);

    return (
        <>
            <ReactOverflowTooltip title={displayName}>
                <RowTextStyle>{displayName}</RowTextStyle>
            </ReactOverflowTooltip>
        </>
    );
};

const EmailFormatter: React.FC<FormatterProps> = ({ row }) => {
    return (
        <ReactOverflowTooltip title={row.personnel.email}>
            <RowTextStyle>{row.personnel.email}</RowTextStyle>
        </ReactOverflowTooltip>
    );
};

const YearCreatedFormatter: React.FC<FormatterProps> = ({ row }) => {
    return (
        <ReactOverflowTooltip title={DateUtil.formatDate(row.personnel.createdTs, "YYYY")}>
            <RowTextStyle>{DateUtil.formatDate(row.personnel.createdTs, "YYYY")}</RowTextStyle>
        </ReactOverflowTooltip>
    );
};

const YearsOnListFormatter: React.FC<FormatterProps> = ({ row }) => {
    return (
        <ReactOverflowTooltip title={row.yearsOnGuestList}>
            <RowTextStyle color="var(--blue)">{row.yearsOnGuestList}</RowTextStyle>
        </ReactOverflowTooltip>
    );
};

const AddPastGuestTableColumns = [
    {
        key: "",
        name: "",
        width: 25,
    },
    {
        key: "name",
        name: "Name",
        sortable: true,
        width: 200,
        formatter: UmpireGuestNameFormatter,
    },
    {
        key: "email",
        name: "Email",
        width: 200,
        formatter: EmailFormatter,
    },
    {
        key: "yearCreated",
        name: "Year Created",
        width: 120,
        formatter: YearCreatedFormatter,
    },
    {
        key: "yearsOnAList",
        name: "Years on a list",
        width: 75,
        formatter: YearsOnListFormatter,
    },
];

const AddPastUmpireGuestModalBody: React.FC<AddPastUmpireGuestModalBodyProps> = ({
    selectedPastUmpireGuests,
    setSelectedPastUmpireGuests,
    umpireId,
    currNumOfGuests,
}) => {
    const [pastUmpireGuests, setPastUmpireGuests] = useState([]);
    const [selectedUmpireGuestSeason, setSelectedUmpireGuestSeason] = useState<number>(-1);
    const [searchText, setSearchText] = useState<string>("");
    const [umpireGuestSeasonOptions, setUmpireGuestSeasonOptions] = useState<
        { label: string; value: number }[]
    >([]);

    const showAlert = useAlert();

    const source = axios.CancelToken.source();

    const searchPastUmpireGuests = (seasonId: number, searchText: string) => {
        getPastUmpireGuests(umpireId, seasonId, searchText, source).then(response => {
            setPastUmpireGuests(response.pastUmpireGuests);
        });
    };

    useEffect(() => {
        getAllPastUmpireGuestSeasons()
            .then(response => {
                if (response) {
                    const seasonOpts = response.map(
                        (ugSeason: { id: number; seasonId: number }) => {
                            return {
                                label: ugSeason.seasonId + " Guests",
                                value: ugSeason.id,
                            };
                        },
                    );
                    seasonOpts.unshift({ label: "All Past Years", value: -1 });
                    setUmpireGuestSeasonOptions(seasonOpts);
                    setSelectedUmpireGuestSeason(seasonOpts[0]?.value);
                    searchPastUmpireGuests(seasonOpts[0]?.value, "");
                }
            })
            .catch(() => {
                showAlert(
                    "Error fetching past umpire guest season, please contact bigssupport@mlb.com",
                    "danger",
                );
            });
        return () => {
            source.cancel();
        };
    }, []);

    useEffect(() => {
        searchPastUmpireGuests(selectedUmpireGuestSeason, searchText);
    }, [selectedUmpireGuestSeason, searchText]);

    const rowsSelectedToggleHandler = (rows: any[]) => {
        rows.forEach(row => {
            const guest = pastUmpireGuests.find(guest => guest.id === row.id);
            guest.isSelected = !guest.isSelected;
        });
        setPastUmpireGuests([...pastUmpireGuests]);
        setSelectedPastUmpireGuests([...pastUmpireGuests.filter(row => row.isSelected)]);
    };

    const getValue = () => {
        return umpireGuestSeasonOptions.find(opt => opt.value === selectedUmpireGuestSeason);
    };

    return (
        <AddPastUmpireGuestBodyContainerStyle>
            <div className="d-flex justify-content-between mb-2">
                <div style={{ width: "40%" }}>
                    <BigsSelect
                        onChange={opt => {
                            setSelectedUmpireGuestSeason(opt.value);
                        }}
                        options={umpireGuestSeasonOptions}
                        value={getValue()}
                    />
                </div>
                <div className="mr-3" style={{ width: "40%" }}>
                    <UncontrolledInput
                        className="form-control-sm"
                        placeholder="type to search guests"
                        defaultValue={searchText}
                        postChange={value => {
                            setSearchText(value);
                        }}
                        timeout={150}
                        required={false}
                        height={38}
                    />
                </div>
            </div>
            <div>
                <span
                    style={{
                        fontFamily: "Helvetica",
                        fontStyle: "oblique",
                        color: "#92a5ba",
                        fontSize: "0.875rem",
                    }}
                >
                    select up to {6 - currNumOfGuests} guest(s)
                </span>
                {selectedPastUmpireGuests.length > 6 && (
                    <span
                        style={{
                            fontFamily: "Helvetica",
                            fontStyle: "oblique",
                            color: "#bf0d3e",
                            fontSize: "0.875rem",
                        }}
                    >
                        - {selectedPastUmpireGuests.length} selected
                    </span>
                )}
            </div>
            <div className="mb-2">
                {pastUmpireGuests != null && pastUmpireGuests.length > 0 ? (
                    <BigsBestTable>
                        <BestTable
                            data={pastUmpireGuests}
                            columns={AddPastGuestTableColumns}
                            headerHeight={30}
                            maxTableHeight={150}
                            rowHeight={40}
                            rowSelection={{
                                showCheckbox: true,
                                onRowsSelected: (rows: any) => {
                                    rowsSelectedToggleHandler(rows);
                                },
                                onRowsDeselected: (rows: any) => {
                                    rowsSelectedToggleHandler(rows);
                                },
                                selectBy: {
                                    isSelectedKey: "isSelected",
                                },
                            }}
                        />
                    </BigsBestTable>
                ) : (
                    <span
                        style={{
                            fontFamily: "Helvetica",
                            fontStyle: "oblique",
                            color: "#bf0d3e",
                            fontSize: "0.875rem",
                        }}
                    >
                        No past guests to display
                    </span>
                )}
            </div>
        </AddPastUmpireGuestBodyContainerStyle>
    );
};

type AddPastUmpireGuestModalFooterProps = {
    selectedPastUmpireGuests: any;
    umpireId: number;
    closeModalCallback: Function;
};

const AddPastUmpireGuestModalFooter: React.FC<AddPastUmpireGuestModalFooterProps> = ({
    selectedPastUmpireGuests,
    umpireId,
    closeModalCallback,
}) => {
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();

    const addUmpireGuests = () => {
        const pastUmpireGuestsDTO = {
            pastUmpireGuests: selectedPastUmpireGuests,
        };
        addPastGuests(pastUmpireGuestsDTO)
            .then(() => {
                showAlert("Success");
            })
            .catch(() => {
                showAlert("Error adding past guests. Please contant bigssupport@mlb.com", "danger");
            });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={122}
                    height={26}
                    disabled={
                        selectedPastUmpireGuests.length === 0 || selectedPastUmpireGuests.length > 6
                    }
                    onClick={event => {
                        event.stopPropagation();
                        addUmpireGuests();
                        setTimeout(() => {
                            closeModalCallback();
                            closeModal();
                        }, [500]);
                    }}
                >
                    Add Guest(s)
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

AddPastUmpireGuestModalBody.displayName = "BodyContent";
AddPastUmpireGuestModalFooter.displayName = "FooterContent";

export { AddPastUmpireGuestModalBody, AddPastUmpireGuestModalFooter };
