import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { AdminPersonnel } from "./AdminPersonnelContext";

type UpdatedPersonnelContext = {
    personnelToUpdate: AdminPersonnel;
    setPersonnelToUpdate: Dispatch<SetStateAction<AdminPersonnel>>;
};

const UpdatedPersonnelContext = createContext<UpdatedPersonnelContext>({
    personnelToUpdate: null,
    setPersonnelToUpdate: null,
});

const UpdatedPersonnelContextProvider: React.FC = ({ children }) => {
    const [personnelToUpdate, setPersonnelToUpdate] = useState<AdminPersonnel>(null);
    return (
        <UpdatedPersonnelContext.Provider value={{ personnelToUpdate, setPersonnelToUpdate }}>
            {children}
        </UpdatedPersonnelContext.Provider>
    );
};

const useUpdatedPersonnel = (): UpdatedPersonnelContext => {
    const updatedPersonnelContext = useContext(UpdatedPersonnelContext);
    if (updatedPersonnelContext === undefined) {
        throw Error(`useUpdatedPersonnel must be used within a UploadBgcFormContextProvider`);
    }
    return updatedPersonnelContext;
};

export { UpdatedPersonnelContextProvider, useUpdatedPersonnel };
