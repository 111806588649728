import React, { lazy } from "react";
import { useAuth } from "../../contexts/AuthContext";

const AuthenticatedApp = lazy(() => import("../../modules/common/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("../../modules/common/UnauthenticatedApp"));

const BigsProtectedContents: React.FC = () => {
    const { loggedIn } = useAuth();

    return loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default BigsProtectedContents;
