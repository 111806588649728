import axios from "axios";
import { AdminPersonnel } from "../contexts/AdminPersonnelContext";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/personnel`;

type Personnel = {
    firstName: string;
    lastName: string;
    middleName: string;
    nickName: string;
    title: string;
    email: string;
    affiliateId: number;
    createdUser: string; // To be removed
    updatedUser: string; // To be removed
};

type PersonnelToUpload = {
    personnelList: Array<Personnel>;
    personnelType: string;
};

export type AcceptPersonnelDtoType = {
    personnelId: number;
    bgCheckCriminal: boolean;
    bgCheckCredit: boolean;
    bgCheckEducation: boolean;
    drugTesting: boolean;
};

export const getPersonnel = (): Promise<any> =>
    axios.get(`${API_URL}`).then(response => response.data);

export const addPersonnelBulk = (personnelList: PersonnelToUpload): Promise<any> =>
    axios.post(`${API_URL}/bulk`, personnelList).then(response => response.data);

export const getPersonnelAffiliateCounts = (orgId: number): Promise<any> =>
    axios.get(`${API_URL}/count/${orgId}`).then(response => response.data);

export const getPersonnelByAffiliateId = (affiliateId: number): Promise<any> =>
    axios.get(`${API_URL}/affiliation/${affiliateId}`).then(response => response.data);

export const getPersonnelByOrgId = (orgId: number): Promise<any> =>
    axios.get(`${API_URL}/org/${orgId}`).then(response => response.data);

export const getPersonnelByEmail = (emailAddress: string, orgId: number): Promise<any> =>
    axios.get(`${API_URL}/email/${emailAddress}/${orgId}`).then(response => response);

export const getAllPersonnel = (): Promise<any> =>
    axios.get(`${API_URL}/`).then(response => response.data);

export const acceptPersonnel = (acceptPersonnelDTO: AcceptPersonnelDtoType): Promise<any> =>
    axios.post(`${API_URL}/accept`, acceptPersonnelDTO).then(response => response.data);

export const getPersonnelById = (personnelId: string): Promise<any> =>
    axios.get(`${API_URL}/${personnelId}`).then(response => response.data);

export const getJurisdictionAndStatus = (personnelId: string): Promise<any> =>
    axios.get(`${API_URL}/jurisdictionAndStatus/${personnelId}`).then(response => response.data);

export const getPersonnelBgcJurisdictions = (personnelId: number): Promise<any> =>
    axios
        .get(`${API_URL}/getPersonnelBgcJurisdictions/${personnelId}`)
        .then(response => response.data);

export const getPersonnelBgcJurisdictionsByCompletedStatus = (
    personnelId: number,
    completed: boolean,
): Promise<any> =>
    axios
        .get(`${API_URL}/getPersonnelBgcJurisdictionsByCompletedStatus/${completed}/${personnelId}`)
        .then(response => response.data);

export const getPersonnelBgcFindingsReport = (
    personnelId: number,
    jurisdiction: string,
): Promise<any> =>
    axios
        .get(`${API_URL}/getPersonnelBgcFindingsReport/${personnelId}/${jurisdiction}`)
        .then(response => response.data);

export const getNonSterlingFindingsFilenames = (
    personnelId: number,
    jurisdiction: string,
): Promise<any> =>
    axios
        .get(`${API_URL}/getNonSterlingFindingsFilenames/${personnelId}/${jurisdiction}`)
        .then(response => response.data);

export const getPersonnelDisclosureReview = (personnelId: number): Promise<any> =>
    axios.get(`${API_URL}/disclosureReview/${personnelId}`).then(response => response.data);

export const getAllPersonnelWithFiltering = (
    personnelSearch: any,
    cancelSource: any,
): Promise<any> =>
    axios
        .post(`${API_URL}/`, personnelSearch, { cancelToken: cancelSource?.token })
        .then(response => response.data);

export const getPersonnelError = (personnelId: number): Promise<any> =>
    axios.get(`${API_URL}/errors/${personnelId}`).then(response => response.data);

export const updatePersonnelSsnOrDobAndSubmitToBGC = (
    updatedPersonnelErrorDTO: any,
): Promise<any> =>
    axios.post(`${API_URL}/errors`, updatedPersonnelErrorDTO).then(response => response.data);

export const getAllClubPersonnelWithFiltering = (personnelSearch: any, source: any): Promise<any> =>
    axios
        .post(`${API_URL}/search`, personnelSearch, { cancelToken: source.token })
        .then(response => response.data);

export const getPersonnelAliasNameList = (personnelId: string): Promise<any> =>
    axios.get(`${API_URL}/${personnelId}/aliasNameList`).then(response => response.data);

export const updatePersonnelStatus = (
    personnelId: any,
    statusToUpdate: string,
    affiliationId: number,
): Promise<any> =>
    axios
        .post(`${API_URL}/status/${personnelId}/${statusToUpdate}/${affiliationId}`, statusToUpdate)
        .then(response => response.data);

export const getJurisdictionStatusesByPersonnel = (
    personnel: Array<AdminPersonnel>,
): Promise<any> =>
    axios
        .post(`${API_URL}/getPersonnelJurisdictionStatus`, personnel)
        .then(response => response.data);

export const getJurisdictionsForSubmitToBGC = (personnelId: number): Promise<any> =>
    axios
        .get(`${API_URL}/getJurisdictionsForSubmitToBGC/${personnelId}`)
        .then(response => response.data);

export const addAdditionalJurisdiction = (
    personnelId: number,
    jurisdiction: string,
): Promise<any> =>
    axios
        .post(`${API_URL}/addAdditionalJurisdiction/${personnelId}?jurisdiction=${jurisdiction}`)
        .then(response => response.data);
