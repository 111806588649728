import React, { useState, SetStateAction, Dispatch, useEffect, useRef } from "react";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { TextArea, Icon } from "best-common-react";
import { RequiredLabel } from "../FormElements";
import styled from "styled-components";
import FileBrowser from "../FileBrowser";
import { addProfileComment } from "../../../api/PersonnelProfileApi";
import { useAlert } from "../../../contexts/AlertsContext";

type AddCommentModalBodyProps = {
    comment: Array<any> | string;
    setComment: Dispatch<SetStateAction<Array<any> | string>>;
    files: Array<any>;
    setFiles: Dispatch<SetStateAction<any[]>>;
};

const ModalBodyStyle = styled.div.attrs(() => {})`
    height: 270px;
`;

const SectionStyle = styled.div.attrs(() => ({
    className: "m-3",
}))``;

type StyledIconProps = {
    color: string;
    iconName: string;
};

const FileSection = styled.div`
    height: 105px;
    overflow-y: auto;
`;

const StyledIcon = styled(Icon).attrs(() => ({ className: "align-items-center mr-3" }))<
    StyledIconProps
>`
    color: ${props => props.color};
    iconname: ${props => props.iconName};
`;

type FileType = {
    name: string;
    fileBase64String: string | ArrayBuffer;
    id: string;
};

const AddCommentModalBody: React.FC<AddCommentModalBodyProps> = ({
    comment,
    setComment,
    files,
    setFiles,
}) => {
    const textRef = useRef<any>();
    useEffect(() => {
        if (textRef.current !== undefined) {
            textRef.current.style.height = "72px";
        }
    }, []);
    const commentInputOnChange = (commentEvent: {
        target: { value: React.SetStateAction<string>; scrollHeight: number };
    }) => {
        setComment(commentEvent.target.value);
        textRef.current.style.height = "72px";
        const newHeight = commentEvent.target.scrollHeight + 2;
        if (newHeight < 110) {
            textRef.current.style.height = `${newHeight}px`;
        } else {
            textRef.current.style.height = "110px";
        }
    };

    const onFileAdd = (fileList: any) => {
        const updatedFiles: FileType[] = [];
        fileList.forEach((file: any) => {
            const fileToAdd: FileType = {
                name: file.name,
                fileBase64String: "",
                id: file.name,
            };

            const reader = new FileReader();
            reader.onload = () => {
                fileToAdd.fileBase64String = reader.result;
                updatedFiles.push(fileToAdd);
                setFiles([...files, ...updatedFiles]);
            };
            reader.readAsDataURL(file);
        });
    };

    const onFileDelete = (fileIdToDelete: string) => {
        const fileToRemoveIndex = files.findIndex(file => file.id === fileIdToDelete);
        if (fileToRemoveIndex > -1) {
            files.splice(fileToRemoveIndex, 1);
            setFiles([...files]);
        }
    };

    return (
        <ModalBodyStyle>
            <SectionStyle>
                <RequiredLabel text="Comments" required />
                <TextArea
                    placeholder="enter comments..."
                    value={comment}
                    onChange={commentInputOnChange}
                    ref={textRef}
                />
            </SectionStyle>
            <SectionStyle>
                <FileBrowser
                    onFileAdd={(fileList: any) => {
                        onFileAdd(fileList);
                    }}
                />
            </SectionStyle>
            <SectionStyle>
                {files?.length > 0 ? (
                    <FileSection>
                        {files.map((file: FileType) => {
                            return (
                                <div key={file.id} className="d-flex align-items-center">
                                    <StyledIcon
                                        color="var(--blue)"
                                        iconName="fa-trash-alt"
                                        onClick={() => {
                                            onFileDelete(file.id);
                                        }}
                                    />
                                    <div style={{ fontSize: "14px" }}>{file.name}</div>
                                </div>
                            );
                        })}
                    </FileSection>
                ) : null}
            </SectionStyle>
        </ModalBodyStyle>
    );
};

type AddCommentModalFooterProps = {
    comment: Array<any> | string;
    setComment: Dispatch<SetStateAction<Array<any> | string>>;
    files: Array<any>;
    setFiles: Dispatch<SetStateAction<any[]>>;
    personnelId: number;
    callback: Function;
};

const AddCommentModalFooter: React.FC<AddCommentModalFooterProps> = ({
    comment,
    setComment,
    files,
    setFiles,
    personnelId,
    callback,
}) => {
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();

    useEffect(() => {
        if (!comment || comment === "" || Array.isArray(comment)) {
            setSaveDisabled(true);
        } else {
            setSaveDisabled(false);
        }
    }, [comment]);

    const saveOnClick = () => {
        const commentDto = {
            comment: comment,
            files: files,
        };
        addProfileComment(personnelId, commentDto)
            .then(() => {
                showAlert("Saved", "success");
                if (callback && typeof callback === "function") {
                    callback();
                }
            })
            .catch(() => {
                showAlert(
                    "Error while trying to add comment, Please contact BIGS Systems",
                    "danger",
                );
            });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={67}
                    height={26}
                    disabled={saveDisabled}
                    onClick={event => {
                        saveOnClick();
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    Save
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    setComment([]);
                    setFiles([]);
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

AddCommentModalBody.displayName = "BodyContent";
AddCommentModalFooter.displayName = "FooterContent";

export { AddCommentModalBody, AddCommentModalFooter };
