import React, { useEffect, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import DropdownQuestion from "../../../questionnaire/components/DropDownQuestion";
import TextInputQuestion from "../../../questionnaire/components/TextInputQuestion";
import { useQuestionnaire } from "../../../../contexts/QuestionnaireContext";
import { QuestionDefinitions } from "./QuestionDefinitions";
import { BestRadio } from "best-common-react";
import { BigsSelect } from "../../BigsBcrWrapper";
import FormUtil from "../../../../util/FormUtil";
import {
    getDrugTestExemptStatus,
    getJurisdictionsAvailableForUpload,
} from "../../../../api/ConsentFormApi";
import { useAlert } from "../../../../contexts/AlertsContext";

const StyledRadioButtonText = styled.div.attrs(() => ({
    className: "d-flex align-self-center mt-1 mr-3",
}))`
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
`;

const Container = styled.div.attrs(() => ({
    className: "ml-3 mr-3",
}))``;

const DropDownContent = styled.div`
    margin: 20px 0px 0px 0px;
    width: 350px;
`;

type QuestionnaireSectionProps = {
    personnel: any;
    drugTestingState: boolean;
    setDrugTestingState: Dispatch<SetStateAction<boolean>>;
    jurisdictionForUpload: { label: string; value: string };
    setJurisdictionForUpload: Dispatch<SetStateAction<{ label: string; value: string }>>;
    questionnaireCompletedStatus: { completed: boolean; questionnaireAnswers: {} };
    jurisdictionOptions: { label: string; value: string }[];
    setJurisdictionOptions: Dispatch<SetStateAction<{ label: string; value: string }[]>>;
};

const QuestionnaireContainer = styled.div.attrs(() => ({
    className: "d-flex justify-content-between flex-wrap",
}))``;

const QuestionnaireSection: React.FC<QuestionnaireSectionProps> = ({
    personnel,
    drugTestingState,
    setDrugTestingState,
    jurisdictionForUpload,
    setJurisdictionForUpload,
    questionnaireCompletedStatus,
    jurisdictionOptions,
    setJurisdictionOptions,
}) => {
    const [showJurisdictionUpload, setShowJurisdictionUpload] = useState<boolean>(false);
    const [dtExemptOrg, setDtExemptOrg] = useState<boolean>(false);
    const [dtExemptAff, setDtExemptAff] = useState<boolean>(false);
    const [orgExemptText, setOrgExemptText] = useState<string>("");
    const [affExemptText, setAffExemptText] = useState<string>("");
    const {
        questionnaire,
        dispatchQuestionnaire,
        getJurisdictionOptionsFromQuestionnaire,
    } = useQuestionnaire();

    const showAlert = useAlert();

    useEffect(() => {
        QuestionDefinitions.sort((a, b) => a.order - b.order);
        dispatchQuestionnaire({
            type: "setShortQuestionnaire",
        });
    }, []);

    useEffect(() => {
        const options = new Array<{ label: string; value: string; isCompleted: boolean }>();
        // if (jurisdictionOptions.length === 0) {
        getJurisdictionsAvailableForUpload(personnel?.id).then(response => {
            if (!questionnaire.isPreExisting) {
                getJurisdictionOptionsFromQuestionnaire().forEach(jurisdiction => {
                    if (!options.find(opt => opt.value === jurisdiction.value)) {
                        options.push({ ...jurisdiction, isCompleted: false });
                    }
                });
            }
            response.map((jurisdiction: any) => {
                if (!options.find(opt => opt.value === jurisdiction.name)) {
                    options.push({
                        value: jurisdiction.name,
                        label: FormUtil.formatJurisdiction(jurisdiction.name),
                        isCompleted: jurisdiction.completed,
                    });
                }
            });
            setJurisdictionForUpload(null);
            setJurisdictionOptions(Array.from(options));
        });
        // }
    }, [questionnaire, questionnaireCompletedStatus]);

    useEffect(() => {
        setShowJurisdictionUpload(questionnaire?.shortQuestionnaireSubmittable);
    }, [questionnaire]);

    useEffect(() => {
        getDrugTestExemptStatus(personnel?.id)
            .then(response => {
                // depending on the response set org/aff state
                if (response?.orgExempt) {
                    setDtExemptOrg(true);
                    setOrgExemptText(
                        "This user is exempt from Drug Testing because the " +
                            response?.orgName +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                    setDrugTestingState(null);
                } else if (response?.affiliateExempt) {
                    setAffExemptText(
                        "This user is exempt from Drug Testing because the " +
                            personnel?.affiliation.name +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                    setDtExemptAff(true);
                    setDrugTestingState(null);
                }
            })
            .catch(() => {
                showAlert(
                    "Error retrieving personnel drug test exempt status, Please contact BIGS Systems.",
                    "danger",
                );
            });
    }, []);

    return (
        <Container>
            <QuestionnaireContainer>
                {QuestionDefinitions.map(question => {
                    switch (question.type) {
                        case "DropDown":
                            return <DropdownQuestion key={question.id} questionDef={question} />;
                        case "Text":
                            return <TextInputQuestion key={question.id} questionDef={question} />;
                    }
                })}
                <>
                    {showJurisdictionUpload ? (
                        <DropDownContent>
                            <label className="required">
                                What country form do you need to upload?
                            </label>
                            <BigsSelect
                                onChange={option => {
                                    setJurisdictionForUpload(option);
                                }}
                                options={jurisdictionOptions}
                                value={jurisdictionForUpload}
                            />
                        </DropDownContent>
                    ) : null}
                </>
            </QuestionnaireContainer>
            <div className="mt-2">
                <label className="required">Drug Testing</label>
                <>
                    {dtExemptOrg || dtExemptAff ? (
                        <div>
                            <label style={{ color: "red" }}>
                                {dtExemptOrg ? orgExemptText : affExemptText}
                            </label>
                        </div>
                    ) : null}
                    {personnel?.dtConsentFormSent ||
                    personnel?.dtConsentFormOnFile ||
                    (questionnaire.isPreExisting && questionnaire.submittedDrugTestState) ? (
                        <div>
                            {setDrugTestingState(null)}
                            <label style={{ color: "red" }}>
                                This personnel already has Drug Testing option selected.
                            </label>
                        </div>
                    ) : (
                        <>
                            {!dtExemptOrg && !dtExemptAff ? (
                                <div className="d-flex align-items-center">
                                    <BestRadio
                                        onChange={() => {
                                            setDrugTestingState(true);
                                        }}
                                        checked={drugTestingState}
                                        disabled={
                                            personnel?.dtConsentFormSent ||
                                            personnel?.dtConsentFormOnFile
                                        }
                                    />
                                    <StyledRadioButtonText>Yes</StyledRadioButtonText>
                                    <BestRadio
                                        onChange={() => {
                                            setDrugTestingState(false);
                                        }}
                                        checked={!drugTestingState}
                                        disabled={
                                            personnel?.dtConsentFormSent ||
                                            personnel?.dtConsentFormOnFile
                                        }
                                    />
                                    <StyledRadioButtonText>No</StyledRadioButtonText>
                                </div>
                            ) : null}
                        </>
                    )}
                </>
            </div>
        </Container>
    );
};

export default QuestionnaireSection;
