import styled from "styled-components";
import React from "react";
import { Checkbox, TextArea } from "best-common-react";

type LabelProps = {
    required?: boolean;
    text: string;
};

export const LabelStyle = styled.div.attrs(() => ({ className: "d-flex" }))`
    &&& {
        font-size: 12px;
        color: var(--blue-grey);
    }
`;

export const Asterisk = styled.div.attrs(() => ({ className: "ml-2" }))`
    color: red;
`;

export const RequiredLabel: React.FC<LabelProps> = ({ text, required }) => {
    return (
        <LabelStyle>
            {text} {required ? <Asterisk>*</Asterisk> : null}
        </LabelStyle>
    );
};

type BigsCheckBoxProps = {
    id: string;
    label: string;
    checked: boolean;
    onChange(): void;
};

const StyledCheckbox = styled.div.attrs(() => ({
    className: "mt-2 mr-3 mb-2",
}))`
    & label {
        font-family: Helvetica-Light;
        font-weight: normal;
        font-size: 14px;
    }

    #bigscheckbox {
        margin-right: 3px;
        border: solid 1px var(--fog) !important;
        background-color: var(--white) !important;

        & checked {
            width: 12px;
            height: 9px;
            background-color: var(--blue) !important;
        }

        align-items: center;
    }

    input[type="checkbox"] {
        vertical-align: middle;
    }
`;

export const BigsCheckBox: React.FC<BigsCheckBoxProps> = ({ id, label, checked, onChange }) => {
    return (
        <StyledCheckbox>
            <Checkbox label={label} id={id} onChange={onChange} checked={checked} />
        </StyledCheckbox>
    );
};

export const BigsRadio = styled.input.attrs(() => ({
    type: "radio",
}))`
    &&& {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        padding: 5px;
        border: solid 1px var(--fog);
        background-color: var(--white);
    }
`;
