import React from "react";
import { Col, Row } from "react-bootstrap";
import { RequiredLabel } from "../../FormElements";
import { TextArea } from "best-common-react";
import styled from "styled-components";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";

type DisclosureTabProps = {
    currentJurisdiction: any;
    disclosureComment: string;
    setDisclosureComment: Function;
    acceptDisclosure: Function;
    readOnly: boolean;
};

const Disclosure = styled.div.attrs(() => ({
    className: "",
}))`
    & .col-md-7 {
        padding-right: 0px;
    }

    & .btn {
        font-size: unset;
    }
`;
const DisclosureContent = styled.div.attrs(() => ({
    className: "",
}))`
    background-color: white;
    border: 1px solid var(--fog);
    height: 220px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.5rem;
`;

const CommentContent = styled.div.attrs(() => ({
    className: "",
}))`
    background-color: white;
    border: 1px solid var(--fog);
    height: 220px;
    padding: 0.5rem;
`;
const DisclosureTab: React.FC<DisclosureTabProps> = ({
    currentJurisdiction,
    disclosureComment,
    setDisclosureComment,
    acceptDisclosure,
    readOnly,
}) => {
    let itemNum = 1;
    const commentHandler = (event: any) => {
        setDisclosureComment(event.currentTarget.value);
    };

    const acceptDisclosureHandler = (event: any) => {
        acceptDisclosure();
    };
    return (
        <Disclosure>
            <Row>
                <Col md={7}>
                    <DisclosureContent>
                        {currentJurisdiction.disclosures?.map((disclosure: any, index: number) => {
                            if (disclosure.answer) {
                                return (
                                    <div key={index}>
                                        <Row>
                                            <Col md={1}>{itemNum++}.)</Col>
                                            <Col md={11}>{disclosure.question}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={1} />
                                            <Col md={11} className="font-weight-bold text-dark">
                                                {disclosure.answer ? "Yes" : "No"}
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 text-dark">
                                            <Col md={1} />
                                            <Col md={11}>{disclosure.answer}</Col>
                                        </Row>
                                    </div>
                                );
                            } else {
                                return "";
                            }
                        })}
                    </DisclosureContent>
                </Col>
                <Col md={5} className="noPaddingLeft">
                    <CommentContent>
                        <RequiredLabel
                            text="Disclosure Comments"
                            required={
                                !currentJurisdiction.disclosurePending || readOnly ? false : true
                            }
                        />
                        <div>
                            <TextArea
                                placeholder="enter comments..."
                                value={disclosureComment}
                                rows={6}
                                onChange={commentHandler}
                                style={{
                                    resize: "none",
                                    fontSize: "14px",
                                    color: "var(--black)",
                                    fontWeight: "300",
                                    borderColor: "var(--fog)",
                                }}
                                disabled={!currentJurisdiction.disclosurePending || readOnly}
                            />
                        </div>
                        <div className="mt-2">
                            {currentJurisdiction.disclosurePending && (
                                <PrimaryButtonStyled
                                    width={298}
                                    height={26}
                                    disabled={!disclosureComment || readOnly}
                                    onClick={acceptDisclosureHandler}
                                >
                                    Accept Disclosure
                                </PrimaryButtonStyled>
                            )}
                        </div>
                    </CommentContent>
                </Col>
            </Row>
        </Disclosure>
    );
};

export default DisclosureTab;
