export const RouteConstants = {
    LOGIN: "/login",
    LOGIN_CALLBACK: "/login/callback",
    BASE: "/",
    ROSTER: "/roster",
    ADMIN_PERSONNEL: "/adminpersonnel",
    ADD_PERSONNEL: "/addpersonnel",
    PAST_GUESTS: "/pastguests",
    APPLICANTS: "/applicants",
    SETTINGS: "/settings",
    QUESTIONNAIRE: "/questionnaire/:userId",
    PROFILE: "/profile/:personnelId",
    ORG: "/organization",
    ORG_PROFILE: "/organization/:orgId",
    VOLUNTEERS: "/volunteers",
    UMPIRE_GUESTS: "/umpireguests",
    GUEST_LIST: "/guestlist",
    ADMIN_UMPIRE_GUESTS: "/adminumpireguests",
};
