import React, { useState, useEffect } from "react";
import { useSelectedPersonnel } from "../../../../contexts/SelectedPersonnelContext";
import { BigsRadio } from "../../FormElements";
import styled from "styled-components";
import { Icon } from "best-common-react";

type ModalBodyProps = {
    height?: number;
};

export const ModalBody = styled.div.attrs(() => ({
    className: "ml-4 mr-4 mb-2 mt-2",
}))<ModalBodyProps>`
    height: ${props => (props.height ? props.height : 230)}px;
`;

export const BigsBestTable = styled.div`
    .bcr-table {
        border: none;
    }

    .bcr-table-row {
        border-bottom: 1px solid var(--fog) !important;
    }
`;

type RowTextStyleProps = {
    color?: string;
};

const RowTextStyle = styled.div.attrs(() => ({}))<RowTextStyleProps>`
    &&& {
        font-size: 14px;
        color: ${props => (props.color ? props.color : "var(--blue)")};
    }
`;

type StyledIconProps = {
    color: string;
    iconName: string;
};

export const StyledIcon = styled(Icon).attrs(() => ({ className: "align-items-center" }))<
    StyledIconProps
>`
    color: ${props => props.color};
    iconname: ${props => props.iconName};
`;

export type FormatterProps = {
    row: any;
};

export const NameFormatter: React.FC<FormatterProps> = ({ row }) => {
    const [displayName, setDisplayName] = useState<string>("");

    useEffect(() => {
        let name = row.lastName + ", " + row.firstName;

        if (row.middleName) {
            name += " " + row.middleName?.substring(0, 1);
        }

        if (row.suffix) {
            name += "., " + row.suffix;
        }

        setDisplayName(name);
    }, [row]);

    return <RowTextStyle>{displayName}</RowTextStyle>;
};

export const DrugTestFormatter: React.FC<FormatterProps> = ({ row }) => {
    const { drugTestOnChange } = useSelectedPersonnel();
    return (
        <div className="d-flex align-items-center">
            <>
                {row.dtExemptState ? (
                    <RowTextStyle color="red">DT Exempt</RowTextStyle>
                ) : (
                    <>
                        <div className="d-flex mr-2 align-items-center">
                            <BigsRadio
                                value="yes"
                                onChange={() => {
                                    drugTestOnChange(row.id);
                                }}
                                checked={row.drugTest}
                            />
                            <RowTextStyle color="black">Yes</RowTextStyle>
                        </div>
                        <div className="d-flex align-items-center">
                            <BigsRadio
                                value="no"
                                onChange={() => {
                                    drugTestOnChange(row.id);
                                }}
                                checked={!row.drugTest}
                            />
                            <RowTextStyle color="black">No</RowTextStyle>
                        </div>
                    </>
                )}
            </>
        </div>
    );
};

export const DeleteFormatter: React.FC<FormatterProps> = ({ row }) => {
    const { removePersonnel } = useSelectedPersonnel();

    return (
        <div className="ml-1">
            <StyledIcon
                color="var(--blue)"
                iconName="fa-trash-alt"
                onClick={() => {
                    removePersonnel(row.id);
                }}
            />
        </div>
    );
};
