import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

type ScreenBlockerContext = {
    blocking: boolean;
    setBlocking: Dispatch<SetStateAction<boolean>>;
    loadingText?: string;
    setLoadingText: Dispatch<SetStateAction<string>>;
};

const ScreenBlockerContext = createContext<ScreenBlockerContext>({
    blocking: false,
    setBlocking: null,
    loadingText: null,
    setLoadingText: null,
});

const ScreenBlockerContextProvider: React.FC = ({ children }) => {
    const [blocking, setBlocking] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>(null);
    return (
        <ScreenBlockerContext.Provider
            value={{ blocking, setBlocking, loadingText, setLoadingText }}
        >
            {children}
        </ScreenBlockerContext.Provider>
    );
};

const useScreenBlocker = (): ScreenBlockerContext => {
    const profileDisplayContext = useContext<ScreenBlockerContext>(ScreenBlockerContext);
    if (!profileDisplayContext) {
        throw new Error(`useScreenBlocker must be used within a ScreenBlockerContextProvider`);
    }
    return profileDisplayContext;
};

export { ScreenBlockerContextProvider, useScreenBlocker };
