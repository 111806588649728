import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import { BestRadio, BestLabel } from "best-common-react";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { getDrugTestExemptStatus } from "../../../api/ConsentFormApi";
import { useAlert } from "../../../contexts/AlertsContext";

const ModalBody = styled.div.attrs(() => ({
    className: "m-4",
}))`
    height: 230px;
`;

const PersonnelInfoSection = styled.div.attrs(() => ({
    className: "mb-3",
}))``;

const PersonnelNameStyle = styled.div.attrs(() => ({ className: "mb-1" }))`
    font-family: Helvetica;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--slate);
`;

const AffiliateInfoStyle = styled.div.attrs(() => ({}))`
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--slate);
`;

const ExemptCommentTextArea = styled.textarea.attrs(() => ({}))`
    width: 100%;
    resize: none;
    height: 100px;
    border-radius: 4px;
    border: solid 1px var(--fog);
    background-color: var(--white);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    padding: 10px;
`;

const StyledRadioButtonText = styled.div.attrs(() => ({
    className: "d-flex align-self-center mt-1 mr-3",
}))`
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
`;

const DrugTestSectionTitle = styled(BestLabel).attrs(() => ({}))`
    color: #92a5ba;
    font-size: 12px;
`;

const DTexemptTextStyle = styled.div.attrs(() => ({ className: "mt-1 mb-1" }))`
    &&& {
        color: red;
        font-size: 14px;
    }
`;

type ExemptPersonnelBodyProps = {
    personnelInfo: PersonnelInfoType;
    exemptComment: string;
    setExemptComment: Function;
    drugTestingState: boolean;
    setDrugTestingState: Function;
};

type ExemptPersonnelFooterProps = {
    personnelInfo: PersonnelInfoType;
    exemptComment: string;
    setExemptComment: Function;
    exemptPersonnelCallback: (
        rosterId: number,
        exemptComment: string,
        setExemptComment: Function,
        drugTestingState: boolean,
        setDrugTestingState: Function,
    ) => void;
    drugTestingState: boolean;
    setDrugTestingState: Function;
};

type PersonnelInfoType = {
    id: number;
    lastName: string;
    firstName: string;
    title: string;
    affiliation: {
        orgAbbreviation: string;
        name: string;
    };
    roster: {
        id: number;
    };
};

const ExemptPersonnelBody: React.FC<ExemptPersonnelBodyProps> = ({
    personnelInfo,
    exemptComment,
    setExemptComment,
    drugTestingState,
    setDrugTestingState,
}) => {
    const showAlert = useAlert();
    const [dtExemptOrg, setDtExemptOrg] = useState<boolean>(false);
    const [dtExemptAff, setDtExemptAff] = useState<boolean>(false);
    const [orgExemptText, setOrgExemptText] = useState<string>("");
    const [affExemptText, setAffExemptText] = useState<string>("");
    const commentChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setExemptComment(event.currentTarget.value);
    };

    useEffect(() => {
        getDrugTestExemptStatus(personnelInfo.id)
            .then(response => {
                // depending on the response set org/aff state
                if (response?.orgExempt) {
                    setDtExemptOrg(true);
                    setOrgExemptText(
                        "This user is exempt from Drug Testing because the " +
                            response?.orgName +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                    setDrugTestingState(false);
                } else if (response?.affiliateExempt) {
                    setAffExemptText(
                        "This user is exempt from Drug Testing because the " +
                            personnelInfo.affiliation.name +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                    setDtExemptAff(true);
                    setDrugTestingState(false);
                }
            })
            .catch(() => {
                showAlert(
                    "Error retrieving personnel drug test exempt status, Please contact BIGS Systems.",
                    "danger",
                );
            });
    }, []);

    return (
        <ModalBody>
            <PersonnelInfoSection>
                <PersonnelNameStyle>
                    {personnelInfo.lastName}, {personnelInfo.firstName} - {personnelInfo.title}
                </PersonnelNameStyle>
                <AffiliateInfoStyle>
                    {personnelInfo.affiliation.orgAbbreviation} - {personnelInfo.affiliation.name}
                </AffiliateInfoStyle>
            </PersonnelInfoSection>
            {!dtExemptOrg && !dtExemptAff ? (
                <>
                    <DrugTestSectionTitle required>Drug Testing</DrugTestSectionTitle>
                    <div className="d-flex align-items-center">
                        <BestRadio
                            onChange={() => {
                                setDrugTestingState(true);
                            }}
                            checked={drugTestingState}
                        />
                        <StyledRadioButtonText>Yes</StyledRadioButtonText>
                        <BestRadio
                            onChange={() => {
                                setDrugTestingState(false);
                            }}
                            checked={drugTestingState === false}
                        />
                        <StyledRadioButtonText>No</StyledRadioButtonText>
                    </div>
                </>
            ) : null}
            {dtExemptOrg || dtExemptAff ? (
                <DTexemptTextStyle>{dtExemptOrg ? orgExemptText : affExemptText}</DTexemptTextStyle>
            ) : null}
            <div>
                <label className="required">Comments</label>
                <div>
                    <ExemptCommentTextArea value={exemptComment} onChange={commentChangeHandler} />
                </div>
            </div>
        </ModalBody>
    );
};

const ExemptPersonnelFooter: React.FC<ExemptPersonnelFooterProps> = ({
    personnelInfo,
    exemptComment,
    setExemptComment,
    exemptPersonnelCallback,
    drugTestingState,
    setDrugTestingState,
}) => {
    const { closeModal } = useGlobalModal();

    const [markExemptDisabled, setMarkExemptDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (exemptComment && drugTestingState !== null) {
            setMarkExemptDisabled(false);
        } else {
            setMarkExemptDisabled(true);
        }
    }, [exemptComment, drugTestingState]);

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={127}
                    height={26}
                    disabled={markExemptDisabled}
                    onClick={event => {
                        event.stopPropagation();
                        exemptPersonnelCallback(
                            personnelInfo.roster.id,
                            exemptComment,
                            setExemptComment,
                            drugTestingState,
                            setDrugTestingState,
                        );
                        setExemptComment("");
                        setDrugTestingState(null);
                        closeModal();
                    }}
                >
                    Mark Exempt
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    setExemptComment("");
                    setDrugTestingState(null);
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

ExemptPersonnelBody.displayName = "BodyContent";
ExemptPersonnelFooter.displayName = "FooterContent";

export { ExemptPersonnelBody, ExemptPersonnelFooter, PersonnelInfoType };
