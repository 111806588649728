import React from "react";
import { BestSelect, Input, TextArea } from "best-common-react";
import styled from "styled-components";

type BigsSelectProps = {
    onChange: (...args: any[]) => void;
    value?: any;
    values?: any[];
    placeholder?: any;
    portal?: HTMLElement;
    styles?: any;
    options?: any[];
    isMulti?: boolean;
    clearable?: boolean;
    isDisabled?: boolean;
    [x: string]: any;
};

export const BigsSelect: React.FC<BigsSelectProps> = ({
    onChange,
    value,
    values,
    placeholder,
    portal,
    styles,
    options,
    isMulti,
    isDisabled,
    clearable,
}) => {
    const bigsStyles = {
        ...styles,
        valueContainer: { ...styles?.valueContainer, fontSize: "14px" },
        menu: { ...styles?.menu, fontSize: "14px" },
        menuPortal: { ...styles?.menuPortal, fontSize: "14px" },
    };
    return (
        <BestSelect
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            portal={portal}
            values={values}
            isDisabled={isDisabled}
            clearable={clearable}
            styles={bigsStyles}
            isMulti={isMulti}
            options={options}
        />
    );
};

type BigsInputProps = {
    height?: number;
};

export const BigsInput = styled(Input).attrs(() => ({}))<BigsInputProps>`
    &&& {
        font-size: 14px;
        ${props => (props.height ? "height: " + props.height + "px;" : "")};
    }
`;

export const BigsInputDisabled = styled(Input).attrs(() => ({}))<BigsInputProps>`
    &&& {
        font-size: 14px;
        ${props => (props.height ? "height: " + props.height + "px;" : "")};
        color: black;
        border-color: #dee5ed;
        background-color: #f5f7fa;
    }
`;

type BigsTextAreaProps = {
    resize?: string;
};

export const BigsTextArea = styled(TextArea).attrs(() => ({}))<BigsTextAreaProps>`
    &&& {
        ${props => (props.resize ? "resize: " + props.resize + ";" : "")};
    }
`;
