import React, { useEffect } from "react";
import styled from "styled-components";
import UncontrolledInput from "./UncontrolledInput";
import { Icon } from "best-common-react";

type EmailFormElementProps = {
    emailVal: string;
    validEmail: boolean;
    duplicateEmail: boolean;
    assignEmailState: Function;
    emailOnBlur: Function;
    height?: number;
    widthPercentage?: number;
};

const StopPropagationWrapper: React.FC = ({ children }) => (
    <div style={{ width: "100%" }} onClick={e => e.stopPropagation()}>
        {children}
    </div>
);

const FlexBoxStyle = styled.div.attrs(() => ({
    className: "d-flex",
}))`
    &&& {
        width: 100%;
    }
`;

type FlexBoxCenterProps = {
    width?: number;
};

const FlexBoxItemsCenter = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))<FlexBoxCenterProps>`
    ${props => (props.width ? "width: " + props.width + "px" : "")};
`;

type EmailInputHolderProps = {
    widthPercentage?: number;
};

const EmailInputHolder = styled.div.attrs(() => ({
    className: "mr-2",
}))<EmailInputHolderProps>`
    width: ${props => (props.widthPercentage ? props.widthPercentage : 65)}%;
`;

const IconHolder = styled.div.attrs(() => ({}))`
    margin-top: 0.25rem;
`;

type StyledIconProps = {
    color: string;
    iconName: string;
};

const StyledEmailIcon = styled(Icon).attrs(() => ({}))<StyledIconProps>`
    color: ${props => props.color};
    iconname: ${props => props.iconName};
`;

const StyledInvalidEmailText = styled.div`
    font-style: oblique;
    color: red;
    font-size: 0.75rem;
`;

const EmailFormElement: React.FC<EmailFormElementProps> = ({
    emailVal,
    validEmail,
    duplicateEmail,
    assignEmailState,
    emailOnBlur,
    height,
    widthPercentage,
}) => {
    useEffect(() => {
        assignEmailState(emailVal);
    }, []);

    return (
        <StopPropagationWrapper>
            <FlexBoxStyle>
                <EmailInputHolder widthPercentage={widthPercentage}>
                    <UncontrolledInput
                        className="form-control-sm"
                        defaultValue={emailVal}
                        postChange={value => assignEmailState(value)}
                        onBlur={(event: React.FormEvent<HTMLInputElement>) => {
                            emailOnBlur(event.currentTarget.value);
                        }}
                        timeout={150}
                        required={true}
                        height={height}
                    />
                </EmailInputHolder>
                <IconHolder>
                    {emailVal !== "" ? (
                        <div>
                            {validEmail ? (
                                <div>
                                    {duplicateEmail ? (
                                        <FlexBoxItemsCenter>
                                            <StyledEmailIcon
                                                color="red"
                                                iconName="fa-exclamation-circle"
                                            />
                                        </FlexBoxItemsCenter>
                                    ) : (
                                        <FlexBoxItemsCenter>
                                            <StyledEmailIcon
                                                color="green"
                                                iconName="fa-check-circle"
                                            />
                                        </FlexBoxItemsCenter>
                                    )}
                                </div>
                            ) : (
                                <FlexBoxItemsCenter width={92}>
                                    <StyledEmailIcon
                                        className="mr-1"
                                        color="red"
                                        iconName="fa-times-circle"
                                    />
                                    <StyledInvalidEmailText>invalid email</StyledInvalidEmailText>
                                </FlexBoxItemsCenter>
                            )}
                        </div>
                    ) : (
                        <div>
                            <StyledEmailIcon color="gray" iconName="fa-check-circle" />
                        </div>
                    )}
                </IconHolder>
            </FlexBoxStyle>
        </StopPropagationWrapper>
    );
};

export default EmailFormElement;
