import React from "react";
import SubSectionHeader from "../../../SubSectionHeader";
import styled from "styled-components";
import {
    useUploadBgcForm,
    BgcFormActionTypes,
    BgcConsentFormType,
} from "../../../../../contexts/UploadBgcFormContext";
import { BigsRadio, RequiredLabel } from "../../../FormElements";
import FileBrowser from "../../../FileBrowser";
import { useAlert } from "../../../../../contexts/AlertsContext";
import FileUtil from "../../../../../util/FileUtil";

type ConsentFormSectionProps = {};

const RadioLabel = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 14px;
    }
`;

type ConsentFormSubSectionType = {
    formState: BgcConsentFormType;
    dispatchForm: React.Dispatch<any>;
};

const LanguageSelector: React.FC<ConsentFormSubSectionType> = ({ formState, dispatchForm }) => {
    const dispatch = (language: string) => {
        dispatchForm({
            type: BgcFormActionTypes.SET_LANGUAGE,
            language: language,
        });
    };

    return (
        <div>
            <RequiredLabel text="Language" required />
            <div className="d-flex mt-2">
                <div className="d-flex mr-2 align-items-center">
                    <BigsRadio
                        value="yes"
                        onChange={() => {
                            dispatch("English");
                        }}
                        checked={formState.language === "English"}
                    />
                    <RadioLabel>English</RadioLabel>
                </div>
                <div className="d-flex align-items-center">
                    <BigsRadio
                        value="no"
                        onChange={() => {
                            dispatch("Spanish");
                        }}
                        checked={formState.language === "Spanish"}
                    />
                    <RadioLabel>Spanish</RadioLabel>
                </div>
            </div>
        </div>
    );
};

const ConsentFormUpload: React.FC<ConsentFormSubSectionType> = ({ formState, dispatchForm }) => {
    const showAlert = useAlert();
    const dispatch = (file: any) => {
        dispatchForm({
            type: BgcFormActionTypes.SET_CONSENT_FORM_FILE,
            consentFormFile: file,
        });
    };

    const onFileAdd = (file: any) => {
        if (file.length) {
            if (file[0] && FileUtil.fileTooLarge(file[0])) {
                showAlert(
                    "Error file size is too large for upload, file size is " +
                        Math.round(file[0].size / (1024 * 1024)) +
                        "MB and the limit is 3MB. Please upload a smaller file.",
                    "danger",
                );
            } else {
                const fileToAdd: any = {
                    name: file[0].name,
                    fileBase64String: "",
                    id: file[0].name + Math.floor(Math.random() * 100),
                    fileBytes: [],
                    fileName: file[0].name,
                };
                const reader = new FileReader();
                reader.onload = () => {
                    fileToAdd.fileBase64String = reader.result;
                    dispatch(fileToAdd);
                };
                reader.readAsDataURL(file[0]);
            }
        }
    };

    const onFileDelete = () => {
        dispatch(null);
    };

    return (
        <div style={{ width: "300px" }}>
            <RequiredLabel text="Consent Form" required />
            <FileBrowser
                onFileAdd={(file: any) => {
                    onFileAdd(file);
                }}
                file={formState.consentFormFile}
                onFileDelete={onFileDelete}
            />
        </div>
    );
};

const ConsentFormSectionContentStyle = styled.div.attrs(() => ({
    className: "d-flex align-item-center justify-content-between ml-2 mr-2 mb-2 mt-3",
}))``;

const ConsentFormSection: React.FC<ConsentFormSectionProps> = ({}) => {
    const { bgcFormState, dispatchBgcForm } = useUploadBgcForm();
    return (
        <div>
            <SubSectionHeader title="Consent Form" />
            <ConsentFormSectionContentStyle>
                <LanguageSelector formState={bgcFormState} dispatchForm={dispatchBgcForm} />
                <ConsentFormUpload formState={bgcFormState} dispatchForm={dispatchBgcForm} />
            </ConsentFormSectionContentStyle>
        </div>
    );
};

export default ConsentFormSection;
