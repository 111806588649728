const FileUtil = {
    downloadFile(response: any, fileExt: string) {
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const nameRegex = new RegExp(
            "filename[^;=\\n]*=(?:(\\\\?['\"])(.*?)\\1|(?:[^\\s]+'.*?')?([^;\\n]*))",
        );
        const matches = nameRegex.exec(response.headers["content-disposition"]);
        let fileName = `download.${fileExt}`;
        if (matches.length > 0) {
            const temp = matches[0].substring(10);
            fileName = temp.substring(0, temp.length);
        }

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            return;
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setTimeout(function() {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 1000);
    },

    openFileInNewTab(data: any) {
        const fileObjectURL = URL.createObjectURL(data);
        const newWindow = window.open(fileObjectURL, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    },

    fileTooLarge(file: any) {
        return file?.size > 3 * 1024 * 1024;
    },
};

export default FileUtil;
