import React from "react";
import {
    Modal,
    ModalHeader,
    ModalHeaderWithTitleAndClose,
    ModalBody,
    ModalFooter,
} from "best-common-react";
import styled from "styled-components";

const HeaderStyle = styled.div.attrs(() => ({
    className: "d-flex justify-content-between",
}))`
    width: 100%;
`;

const HeaderTextStyle = styled.div`
    font-size: 26px;
    font-weight: bold;
`;

type BigsModalProps = {
    isOpen: boolean;
    closeModal: Function;
    headerText: string;
    headerChild?: JSX.Element;
};

const BigsModal: React.FC<BigsModalProps> = ({
    isOpen,
    closeModal,
    headerText,
    headerChild,
    children,
}) => {
    return (
        <Modal size="lg" show={isOpen}>
            {headerChild ? (
                <ModalHeader
                    styles={{
                        backgroundColor: "#ffffff",
                        borderBottomColor: "#92a5ba",
                        border: "solid 1px var(--blue-grey)",
                        color: "#1e376d",
                    }}
                >
                    <HeaderStyle>
                        <HeaderTextStyle>{headerText}</HeaderTextStyle>
                        {headerChild}
                    </HeaderStyle>
                </ModalHeader>
            ) : (
                <ModalHeaderWithTitleAndClose
                    title={headerText || ""}
                    close={(event: React.FormEvent<HTMLInputElement>) => {
                        event.stopPropagation();
                        closeModal();
                    }}
                    styles={{
                        backgroundColor: "#ffffff",
                        borderBottomColor: "#92a5ba",
                        border: "solid 1px var(--blue-grey)",
                        color: "#1e376d",
                    }}
                />
            )}

            {React.Children.map(children, (child: JSX.Element) => {
                const type = !child || !child.type ? null : child.type.displayName;
                switch (type) {
                    case "BodyContent":
                        return (
                            <ModalBody
                                styles={{
                                    backgroundColor: "var(--snow)",
                                }}
                            >
                                {React.cloneElement(child, {})}
                            </ModalBody>
                        );
                    case "FooterContent":
                        return (
                            <ModalFooter
                                styles={{
                                    backgroundColor: "#f2f3f4",
                                    borderTop: "none",
                                }}
                            >
                                <>{React.cloneElement(child, {})}</>
                            </ModalFooter>
                        );
                    default:
                        return null;
                }
            })}
        </Modal>
    );
};

export default BigsModal;
