import React, {
    useContext,
    createContext,
    useReducer,
    useState,
    SetStateAction,
    Dispatch,
} from "react";
import { PersonnelInfoType } from "../modules/elements/modal/AcceptPersonnelModal";
import { PersonnelFormStatusInfo } from "../modules/elements/modal/ReviewErrorsModal";
import GlobalModal from "../modules/elements/modal/GlobalModal";

const GlobalModalTypes = {
    ARE_YOU_SURE_MODAL: "AreYouSureModal",
    EXPORT_MODAL: "ExportModal",
    INFO_MODAL: "Info_Modal",
    CERTIFY_ROSTER: "CertifyRosterModal",
    ACCEPT_PERSONNEL: "AcceptPersonnelModal",
    EXEMPT_PERSONNEL: "ExemptPersonnelModal",
    ADD_COMMENT: "AddCommentModal",
    SUBMIT_TO_BGC: "SubmitToBgcModal",
    RESEND_DT_CONSENT_FORM: "ResendDtConsentFormModal",
    RESENT_BGC_CONSENT_FORM: "ResendBgcConsentFormModal",
    DELETE_COMMENT: "DeleteCommentModal",
    UPLOAD_BGC_CONSENT_FORM: "UploadBgcConsentFormModal",
    REVIEW_FINDINGS: "ReviewFindingsModal",
    BULK_ACCEPT_MODAL: "BulkAcceptPersonnelModal",
    BULK_EXEMPT_MODAL: "BulkExemptPersonnelModal",
    REVIEW_DISCLOSURE: "ReviewDisclosureModal",
    REVIEW_ERRORS: "ReviewErrorsModal",
    UPLOAD_DT_CONSENT_FORM: "UploadDtConsentFormModal",
    VIEW_COMMENT: "ViewCommentModal",
    REMOVE_PERSONNEL: "RemovePersonnelModal",
    REACTIVATE_PERSONNEL: "ReactivatePersonnelModal",
    MARK_COMPLETE: "MarkCompleteModal",
    BULK_ROLLOVER_INPROGRESS_MODAL: "BulkRolloverInProgressModal",
    CREATE_NEW_UMPIRE_GUEST_MODAL: "CreateNewUmpireGuestModal",
    ADD_PAST_UMPIRE_GUEST_MODAL: "AddPastUmpireGuestModal",
};

type GlobalModal = {
    state: GlobalModalState;
    dispatch: Function;
    closeModal: Function;
};

type GlobalModalState = {
    isOpen: boolean;
    content: string;
    modalType: string;
    yesCallback: () => void;
    headerText: string;
    certifyRosterCallback: () => void;
    bgCheckProceduresText: string;
    personnelInfo: PersonnelInfoType;
    acceptPersonnelCallback: (
        id: number,
        bgChecks: { criminal: boolean; credit: boolean; education: boolean },
        drugTest: boolean,
        rosterId: number,
    ) => void;
    exemptPersonnelCallback: (
        rosterId: number,
        exemptComment: string,
        setExemptComment: Function,
    ) => void;
    personnelId: number;
    jurisdictionStatuses: any[];
    onSubmitToBgcCallback: (
        personnelId: number,
        jurisdiction: string,
        eventName: string,
        successMessage: string,
    ) => void;
    onCancelCallback: () => void;
    modalHeaderText: string;
    selectedSideMenuOpts: any[];
    resendClickHandler: () => void;
    onResendBgcCallback: (
        personnelId: number,
        jurisdiction: string,
        eventName: string,
        successMessage: string,
    ) => void;
    commentId: number;
    getComments: (arg1: any) => void;
    personnel: any;
    personnelAdvancedSearch(arg1: any, arg2: any, arg3: any, arg4: any): void;
    adminSelectedMenuOptions: any;
    selectedMenuOptions: any;
    adminAffiliationFilters: any;
    affiliationFilters: any;
    adminSearchText: any;
    searchText: string;
    inactiveSelected: boolean;
    sortFilters: any;
    personnelFormStatusInfo: PersonnelFormStatusInfo;
    targetJurisdiction: string;
    rosterStatus: any;
    callback: () => void;
    active: string;
    bgcScore: string;
    jurisdiction: string;
    umpireId: number;
    currNumOfGuests: number;
    closeModalCallback: Function;
    createNewUmpireGuestCallback: Function;
};

const GlobalModalContext = createContext<GlobalModal>({
    state: null,
    dispatch: null,
    closeModal: null,
});

const initialState = {
    isOpen: false,
    content: "",
    modalType: "",
    yesCallback: Function,
    headerText: "",
    closeCallback: Function,
    certifyRosterCallback: Function,
    bgCheckProceduresText: "",
    personnelInfo: {
        id: -1,
        lastName: "",
        firstName: "",
        title: "",
        affiliation: {
            orgAbbreviation: "",
            name: "",
            level: "",
            type: "",
        },
        roster: {
            id: -1,
            statuses: [] as any[],
        },
        personnelCheckCategoryOnFile: false,
    },
    acceptPersonnelCallback: () => {},
    exemptPersonnelCallback: () => {},
    personnelId: -1,
    jurisdictionStatuses: [] as any[],
    onSubmitToBgcCallback: () => {},
    onCancelCallback: () => {},
    modalHeaderText: "",
    selectedSideMenuOpts: [] as any[],
    resendClickHandler: () => {},
    onResendBgcCallback: () => {},
    commentId: -1,
    getComments: (arg1: any) => {},
    personnel: {},
    personnelAdvancedSearch: Function,
    adminSelectedMenuOptions: [] as any[],
    selectedMenuOptions: [] as any[],
    adminAffiliationFilters: [] as any[],
    affiliationFilters: [] as any[],
    adminSearchText: [] as any[],
    searchText: "",
    inactiveSelected: false,
    sortFilters: {},
    personnelFormStatusInfo: { formId: -1, personnelId: -1, ssn: "", newSSN: "" },
    targetJurisdiction: "usPuerto",
    rosterStatus: {},
    callback: () => {},
    active: "ACTIVE",
    bgcScore: "",
    jurisdiction: "",
    umpireGuestSeason: -1,
    umpireId: -1,
    currNumOfGuests: -1,
    closeModalCallback: () => {},
    createNewUmpireGuestCallback: () => {},
};

const GlobalModalContextProvider: React.FC = ({ children }) => {
    const reducer = (state: GlobalModalState, action: any) => {
        switch (action.type) {
            case "closeModal":
                return {
                    ...state,
                    isOpen: false,
                    content: "",
                };
            case "openAreYouSureModal":
                return {
                    ...state,
                    isOpen: true,
                    yesCallback: action.yesCallback,
                    content: action.content,
                    headerText: action.headerText || "Are you sure?",
                    modalType: GlobalModalTypes.ARE_YOU_SURE_MODAL,
                };
            case "openExportModal":
                return {
                    ...state,
                    isOpen: true,
                    searchText: action.searchText,
                    affiliationFilters: action.affiliationFilters,
                    inactiveSelected: action.inactiveSelected,
                    selectedMenuOptions: action.selectedMenuOptions,
                    sortFilters: action.sortFilters,
                    headerText: action.headerText || "Export Personnel",
                    modalType: GlobalModalTypes.EXPORT_MODAL,
                };
            case "openInfoModal":
                return {
                    ...state,
                    isOpen: true,
                    content: action.content,
                    headerText: action.headerText || "Information",
                    modalType: GlobalModalTypes.INFO_MODAL,
                };
            case "openCertifyRosterModal":
                return {
                    ...state,
                    isOpen: true,
                    content: action.content,
                    headerText: action.headerText || "Certify Roster",
                    modalType: GlobalModalTypes.CERTIFY_ROSTER,
                    certifyRosterCallback: action.certifyRosterCallback,
                    bgCheckProceduresText: action.bgCheckProceduresText,
                };
            case "openAcceptPersonnelModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Accept Personnel Request",
                    modalType: GlobalModalTypes.ACCEPT_PERSONNEL,
                    personnelInfo: action.personnelInfo,
                    acceptPersonnelCallback: action.acceptPersonnelCallback,
                };
            case "openExemptPersonnelModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Mark Personnel Exempt",
                    modalType: GlobalModalTypes.EXEMPT_PERSONNEL,
                    personnelInfo: action.personnelInfo,
                    exemptPersonnelCallback: action.exemptPersonnelCallback,
                };
            case "openAddCommentModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Add Comment and Files",
                    modalType: GlobalModalTypes.ADD_COMMENT,
                    personnelId: action.personnelId,
                    callback: action.callback,
                };
            case "openSubmitToBgcModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Submit To Background Check",
                    modalType: GlobalModalTypes.SUBMIT_TO_BGC,
                    personnelId: action.personnelId,
                    onSubmitToBgcCallback: action.onSubmitToBgcCallback,
                    onCancelCallback: action.onCancelCallback,
                    modalHeaderText: action?.modalHeaderText,
                };
            case "openResendDtConsentFormModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Resend Drug Test Consent Form",
                    modalType: GlobalModalTypes.RESEND_DT_CONSENT_FORM,
                    personnelId: action.personnelId,
                    resendClickHandler: action.resendClickHandler,
                };
            case "openResendBgcConsentFormModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Resend Consent Form",
                    modalType: GlobalModalTypes.RESENT_BGC_CONSENT_FORM,
                    jurisdictionStatuses: action.jurisdictionStatuses,
                    onResendBgcCallback: action.onResendBgcCallback,
                    personnelId: action.personnelId,
                };
            case "openDeleteCommentModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Delete Comment",
                    modalType: GlobalModalTypes.DELETE_COMMENT,
                    commentId: action.commentId,
                    getComments: action.getComments,
                };
            case "openUploadBgcConsentFormModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Upload Consent Form",
                    modalType: GlobalModalTypes.UPLOAD_BGC_CONSENT_FORM,
                    personnel: action.personnel,
                };
            case "openReviewFindingsModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Review Findings",
                    modalType: GlobalModalTypes.REVIEW_FINDINGS,
                    personnel: action.personnel,
                    active: action.active,
                    jurisdiction: action.jurisdiction,
                    bgcScore: action.bgcScore,
                    callback: action.callback,
                };
            case "openMarkCompleteModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Mark Complete",
                    modalType: GlobalModalTypes.MARK_COMPLETE,
                    personnel: action.personnel,
                };
            case "openBulkAcceptPersonnelModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Accept Personnel",
                    modalType: GlobalModalTypes.BULK_ACCEPT_MODAL,
                    personnelAdvancedSearch: action.personnelAdvancedSearch,
                    adminSelectedMenuOptions: action.selectedMenuOptions,
                    adminAffiliationFilters: action.affiliationFilters,
                    adminSearchText: action.searchText,
                };
            case "openBulkExemptPersonnelModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Exempt Personnel",
                    modalType: GlobalModalTypes.BULK_EXEMPT_MODAL,
                    personnelAdvancedSearch: action.personnelAdvancedSearch,
                    adminSelectedMenuOptions: action.selectedMenuOptions,
                    adminAffiliationFilters: action.affiliationFilters,
                    adminSearchText: action.searchText,
                };
            case "openReviewDisclosureModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Review Disclosure",
                    modalType: GlobalModalTypes.REVIEW_DISCLOSURE,
                    personnel: action.personnel,
                    targetJurisdiction: action.targetJurisdiction,
                    callback: action.callback,
                    active: action.active,
                };
            case "updateModalProperties":
                return {
                    ...state,
                    headerText: action.headerText,
                };
            case "openReviewErrorsModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Review Errors",
                    modalType: GlobalModalTypes.REVIEW_ERRORS,
                    personnelFormStatusInfo: action.personnelFormStatusInfo,
                };
            case "openUploadDtConsentFormModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Upload Drug Test Consent Form",
                    modalType: GlobalModalTypes.UPLOAD_DT_CONSENT_FORM,
                    personnelId: action.personnelId,
                };
            case "openViewCommentModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Comment and Files",
                    modalType: GlobalModalTypes.VIEW_COMMENT,
                    rosterStatus: action.rosterStatus,
                    personnelId: action.personnelId,
                };
            case "openRemovePersonnelModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Remove Personnel From Roster",
                    modalType: GlobalModalTypes.REMOVE_PERSONNEL,
                    personnel: action.personnel,
                    callback: action.callback,
                };
            case "openReactivatePersonnelModel":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Add Personnel to Roster",
                    modalType: GlobalModalTypes.REACTIVATE_PERSONNEL,
                    personnel: action.personnel,
                    callback: action.callback,
                };
            case "openBulkRolloverInProgressModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: action.rolloverInProgressType,
                    modalType: GlobalModalTypes.BULK_ROLLOVER_INPROGRESS_MODAL,
                    personnelAdvancedSearch: action.personnelAdvancedSearch,
                    adminSelectedMenuOptions: action.selectedMenuOptions,
                    adminAffiliationFilters: action.affiliationFilters,
                    adminSearchText: action.searchText,
                };
            case "openAddNewUmpireGuestModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Create New Guest",
                    modalType: GlobalModalTypes.CREATE_NEW_UMPIRE_GUEST_MODAL,
                    createNewUmpireGuestCallback: action.createNewUmpireGuestCallback,
                };
            case "openAddPastUmpireGuestModal":
                return {
                    ...state,
                    isOpen: true,
                    headerText: "Add Past Guest",
                    modalType: GlobalModalTypes.ADD_PAST_UMPIRE_GUEST_MODAL,
                    umpireId: action.umpireId,
                    currNumOfGuests: action.currNumOfGuests,
                    closeModalCallback: action.closeModalCallback,
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const closeModal = () => {
        dispatch({ type: "closeModal" });
    };

    return (
        <GlobalModalContext.Provider
            value={{
                state,
                dispatch,
                closeModal,
            }}
        >
            {children}
        </GlobalModalContext.Provider>
    );
};

const useGlobalModal = (): GlobalModal => {
    const context = useContext(GlobalModalContext);
    if (context === undefined) {
        throw new Error(`useGlobalModal must be used within a GlobalModalContextProvider`);
    }
    return context;
};

export { GlobalModalContext, GlobalModalContextProvider, useGlobalModal, GlobalModalTypes };
