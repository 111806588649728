import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/umpire`;

export type UmpireGuestsSearchType = {
    orgId: number;
    searchText: any;
    statusFilter: string;
};

export const getUmpireGuests = (
    umpireOrgId: number,
    seasonId: number,
    cancelSource: any,
): Promise<any> =>
    axios
        .get(`${API_URL}/umpireGuest?umpireOrgId=${umpireOrgId}&seasonId=${seasonId}`, {
            cancelToken: cancelSource.token,
        })
        .then(response => response.data);

export const deleteUmpireGuest = (umpireGuestId: number): Promise<any> =>
    axios
        .delete(`${API_URL}/umpireGuest?umpireGuestId=${umpireGuestId}`)
        .then(response => response.data);

export type NewUmpireGuestDtoType = {
    personnel: {
        firstName: string;
        lastName: string;
        middleName?: string;
        nickName?: string;
        email: string;
        affiliation: { id: number; orgId: number };
    };
    umpireGuestAdditionalInfo: {
        relationship: string;
        international: boolean;
    };
};

export type BulkNewUmpireGuestDtoType = {
    newUmpireGuestList: NewUmpireGuestDtoType[];
};

export const addUmpireGuest = (newUmpireGuestDTO: NewUmpireGuestDtoType): Promise<any> =>
    axios.post(`${API_URL}/umpireGuest/add`, newUmpireGuestDTO).then(response => response.data);

export const bulkAddUmpireGuest = (
    bulkNewUmpireGuestDTO: BulkNewUmpireGuestDtoType,
): Promise<any> =>
    axios
        .post(`${API_URL}/umpireGuest/bulk`, bulkNewUmpireGuestDTO)
        .then(response => response.data);

export const getPastUmpireGuests = (
    umpireOrgId: number,
    seasonId: number,
    searchText: string,
    cancelSource: any,
): Promise<any> =>
    axios
        .get(
            `${API_URL}/umpireGuest/past?umpireOrgId=${umpireOrgId}&seasonId=${seasonId}&searchText=${searchText}`,
            {
                cancelToken: cancelSource.token,
            },
        )
        .then(response => response.data);

export const addPastGuests = (pastUmpireGuests: any): Promise<any> =>
    axios
        .post(`${API_URL}/umpireGuest/addPastGuests`, pastUmpireGuests)
        .then(response => response.data);

export const checkForSubmission = (umpireOrgId: number): Promise<any> =>
    axios
        .get(`${API_URL}/umpireGuest/checkForSubmission?umpireOrgId=${umpireOrgId}`)
        .then(response => response.data);

export const submitGuestList = (umpireOrgId: number): Promise<any> =>
    axios
        .post(`${API_URL}/umpireGuest/submitUmpireGuestList?umpireOrgId=${umpireOrgId}`)
        .then(response => response.data);

export const getAllUmpires = (): Promise<any> =>
    axios.get(`${API_URL}/umpireGuest/all`).then(response => response.data);

export const usedUmpireGuestPasses = (): Promise<any> =>
    axios.get(`${API_URL}/umpireGuest/usedUmpireGuestPasses`).then(response => response.data);

export const totalUmpireGuestPasses = (): Promise<any> =>
    axios.get(`${API_URL}/umpireGuest/totalUmpireGuestPasses`).then(response => response.data);
