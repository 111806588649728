import React from "react";
import SubSectionHeader from "../../../SubSectionHeader";
import styled from "styled-components";
import { BigsRadio } from "../../../FormElements";
import { useUploadBgcForm } from "../../../../../contexts/UploadBgcFormContext";
import { TextArea } from "best-common-react";
import { DisclosureQuestions, DisclosureQuestionType } from "./DisclosureQuestions";

type DisclosuresSectionProps = {};

const RadioLabel = styled.div.attrs(() => ({}))`
    &&& {
        font-size: 14px;
    }
`;

const ParagraphLabel = styled.p.attrs(() => ({}))`
    font-size: 12px;
    color: var(--blue-grey);
`;

const InlineAsterisk = styled.span.attrs(() => ({ className: "ml-1" }))`
    &&& {
        font-size: 12px;
        color: red;
    }
`;

const DisclosuresSection: React.FC<DisclosuresSectionProps> = ({}) => {
    const { bgcFormState, dispatchBgcForm } = useUploadBgcForm();
    const getDisclosureQuestion = (question: DisclosureQuestionType) => {
        return (
            <>
                {question.showQuestion(bgcFormState) ? (
                    <div className="mb-4">
                        <div className="d-flex ml-2 mt-3 align-items-start">
                            <ParagraphLabel style={{ width: "550px" }}>
                                {question.id + ".   " + question.questionText}
                                <InlineAsterisk>*</InlineAsterisk>
                            </ParagraphLabel>
                            <div className="d-flex mr-2 ml-5 align-items-center">
                                <BigsRadio
                                    value="yes"
                                    onChange={() => {
                                        dispatchBgcForm({
                                            type: question.dispatchType,
                                            [question.radioButton]: true,
                                        });
                                    }}
                                    checked={bgcFormState[question.radioButton]}
                                />
                                <RadioLabel>Yes</RadioLabel>
                            </div>
                            <div className="d-flex align-items-center">
                                <BigsRadio
                                    value="no"
                                    onChange={() => {
                                        dispatchBgcForm({
                                            type: question.dispatchType,
                                            [question.radioButton]: false,
                                        });
                                    }}
                                    checked={!bgcFormState[question.radioButton]}
                                />
                                <RadioLabel>No</RadioLabel>
                            </div>
                        </div>
                        <div>
                            <>
                                {question.showTextField(bgcFormState) ? (
                                    <TextArea
                                        placeholder="include details here…"
                                        value={bgcFormState[question.formField]}
                                        onChange={(event: { currentTarget: { value: any } }) => {
                                            dispatchBgcForm({
                                                type: question.dispatchType,
                                                [question.radioButton]:
                                                    bgcFormState[question.radioButton],
                                                [question.formField]: event.currentTarget.value,
                                            });
                                        }}
                                        style={{ fontSize: "14px" }}
                                    />
                                ) : null}
                            </>
                        </div>
                    </div>
                ) : null}
            </>
        );
    };
    return (
        <div className="mt-4">
            <SubSectionHeader title="Disclosures" />
            {DisclosureQuestions.map(question => getDisclosureQuestion(question))}
        </div>
    );
};

export default DisclosuresSection;
