import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { BestRadio, BestLabel } from "best-common-react";
import { getDrugTestExemptStatus } from "../../../api/ConsentFormApi";
import { useAlert } from "../../../contexts/AlertsContext";
import { BigsCheckBox, RequiredLabel } from "../FormElements";

const BodyStyle = styled.div.attrs(() => ({
    className: "",
}))`
    height: 260px;
`;

const PersonnelInfoSection = styled.div.attrs(() => ({
    className: "mt-3 ml-3 mb-3",
}))``;

const PersonnelNameStyle = styled.div.attrs(() => ({ className: "mb-1" }))`
    color: var(--slate);
    font-size: 16px;
`;

const AffiliateInfoStyle = styled.div.attrs(() => ({}))`
    color: var(--slate);
    font-size: 12px;
`;

const BackgroundChecksSection = styled.div.attrs(() => ({ className: "d-flex" }))``;

const BackgroundChecksSectionTitle = styled(BestLabel).attrs(() => ({}))`
    color: #92a5ba;
    font-size: 12px;
`;

const StyledRadioButtonText = styled.div.attrs(() => ({
    className: "d-flex align-self-center mt-1 mr-3",
}))`
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
`;

const DTexemptTextStyle = styled.div.attrs(() => ({ className: "ml-3 mt-4" }))`
    &&& {
        color: red;
        font-size: 14px;
    }
`;

type AcceptPersonnelBodyProps = {
    personnelInfo: PersonnelInfoType;
    drugTestingState: boolean | null;
    setDrugTestingState: Dispatch<SetStateAction<boolean>>;
    bgChecks: { criminal: boolean; credit: boolean; education: boolean };
    setBgChecks: Dispatch<SetStateAction<{}>>;
};

type AcceptPersonnelFooterProps = {
    personnelInfo: PersonnelInfoType;
    drugTestingState: boolean | null;
    bgChecks: { criminal: boolean; credit: boolean; education: boolean };
    setDrugTestingState: Dispatch<SetStateAction<boolean>>;
    setBgChecks: Dispatch<SetStateAction<{}>>;
    acceptPersonnelCallback: (
        id: number,
        bgChecks: { criminal: boolean; credit: boolean; education: boolean },
        drugTest: boolean,
        rosterId: number,
    ) => void;
};

type PersonnelInfoType = {
    id: number;
    lastName: string;
    firstName: string;
    title: string;
    affiliation: {
        orgAbbreviation: string;
        name: string;
        type: string;
        level: string;
    };
    roster: {
        id: number;
        statuses: Array<any>;
    };
    personnelCheckCategoryOnFile: boolean;
};

const AcceptPersonnelBody: React.FC<AcceptPersonnelBodyProps> = ({
    personnelInfo,
    drugTestingState,
    setDrugTestingState,
    bgChecks,
    setBgChecks,
}) => {
    const showAlert = useAlert();
    const [dtExemptOrg, setDtExemptOrg] = useState<boolean>(false);
    const [dtExemptAff, setDtExemptAff] = useState<boolean>(false);
    const [orgExemptText, setOrgExemptText] = useState<string>("");
    const [affExemptText, setAffExemptText] = useState<string>("");

    useEffect(() => {
        getDrugTestExemptStatus(personnelInfo.id)
            .then(response => {
                // depending on the response set org/aff state
                if (response?.orgExempt) {
                    setDtExemptOrg(true);
                    setOrgExemptText(
                        "This user is exempt from Drug Testing because the " +
                            response?.orgName +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                } else if (response?.affiliateExempt) {
                    setAffExemptText(
                        "This user is exempt from Drug Testing because the " +
                            personnelInfo.affiliation.name +
                            " are located in " +
                            response?.exemptState +
                            ".",
                    );
                    setDtExemptAff(true);
                }
            })
            .catch(() => {
                showAlert(
                    "Error retrieving personnel drug test exempt status, Please contact BIGS Systems.",
                    "danger",
                );
            });
    }, []);

    return (
        <BodyStyle>
            <PersonnelInfoSection>
                <PersonnelNameStyle>
                    {personnelInfo.lastName}, {personnelInfo.firstName} - {personnelInfo.title}
                </PersonnelNameStyle>
                <AffiliateInfoStyle>
                    {personnelInfo.affiliation.type !== "Umpire"
                        ? personnelInfo.affiliation.orgAbbreviation
                        : personnelInfo.affiliation.level}
                    - {personnelInfo.affiliation.name}
                </AffiliateInfoStyle>
            </PersonnelInfoSection>
            <BackgroundChecksSection>
                <div className="col-6">
                    <BackgroundChecksSectionTitle>Background Checks</BackgroundChecksSectionTitle>
                    <div className="d-flex">
                        {!personnelInfo?.personnelCheckCategoryOnFile ? (
                            <>
                                <StyledRadioButtonText>Yes</StyledRadioButtonText>
                                {/* <BigsCheckBox
                                    label="Criminal"
                                    id="criminalCheckBox"
                                    onChange={() => {
                                        setBgChecks({ ...bgChecks, criminal: !bgChecks.criminal });  This is commented out because of story BIGS2-371
                                    }}
                                    checked={bgChecks.criminal}
                                />
                                <BigsCheckBox
                                    label="Credit"
                                    id="creditCheckBox"
                                    onChange={() => {
                                        setBgChecks({
                                            ...bgChecks,
                                            credit: !bgChecks.credit,
                                        });
                                    }}
                                    checked={bgChecks.credit}
                                />
                                <BigsCheckBox
                                    label="Education"
                                    id="educationCheckBox"
                                    onChange={() => {
                                        setBgChecks({
                                            ...bgChecks,
                                            education: !bgChecks.education,
                                        });
                                    }}
                                    checked={bgChecks.education}
                                /> */}
                            </>
                        ) : (
                            <RequiredLabel text="Personnel has their Background Check options set." />
                        )}
                    </div>
                </div>
                <>
                    {!dtExemptOrg && !dtExemptAff ? (
                        <div className="col-6">
                            <BackgroundChecksSectionTitle required>
                                Drug Testing
                            </BackgroundChecksSectionTitle>
                            <div className="d-flex align-items-center">
                                <BestRadio
                                    onChange={() => {
                                        setDrugTestingState(true);
                                    }}
                                    checked={drugTestingState}
                                />
                                <StyledRadioButtonText>Yes</StyledRadioButtonText>
                                <BestRadio
                                    onChange={() => {
                                        setDrugTestingState(false);
                                    }}
                                    checked={drugTestingState === false}
                                />
                                <StyledRadioButtonText>No</StyledRadioButtonText>
                            </div>
                        </div>
                    ) : null}
                </>
            </BackgroundChecksSection>
            {dtExemptOrg || dtExemptAff ? (
                <DTexemptTextStyle>{dtExemptOrg ? orgExemptText : affExemptText}</DTexemptTextStyle>
            ) : null}
        </BodyStyle>
    );
};

const AcceptPersonnelFooter: React.FC<AcceptPersonnelFooterProps> = ({
    personnelInfo,
    drugTestingState,
    setDrugTestingState,
    bgChecks,
    setBgChecks,
    acceptPersonnelCallback,
}) => {
    const { closeModal } = useGlobalModal();

    useEffect(() => {
        getDrugTestExemptStatus(personnelInfo.id)
            .then(response => {
                // depending on the response set org/aff state
                if (response?.orgExempt) {
                    setDrugTestingState(false);
                } else if (response?.affiliateExempt) {
                    setDrugTestingState(false);
                }
            })
            .catch(() => {});
    }, []);

    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={127}
                    height={26}
                    disabled={drugTestingState === null}
                    onClick={event => {
                        event.stopPropagation();
                        acceptPersonnelCallback(
                            personnelInfo.id,
                            bgChecks,
                            drugTestingState,
                            personnelInfo.roster.id,
                        );
                        setBgChecks({ criminal: true, credit: false, education: false });
                        setDrugTestingState(null);
                        closeModal();
                    }}
                >
                    Accept Request
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={80}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                    setDrugTestingState(null);
                    setBgChecks({ criminal: true, credit: false, education: false });
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

AcceptPersonnelBody.displayName = "BodyContent";
AcceptPersonnelFooter.displayName = "FooterContent";

export { AcceptPersonnelBody, AcceptPersonnelFooter, PersonnelInfoType };
