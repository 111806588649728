import React, { Dispatch, SetStateAction, useState } from "react";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import styled from "styled-components";
import { Checkbox } from "best-common-react";
import Moment from "moment";
import ReactHtmlParser from "react-html-parser";

const BodyContent = styled.div.attrs(() => ({}))`
    height: 220px;
`;

const BackgroundCheckProceduresSection = styled.div.attrs(() => ({ className: "mt-4" }))`
    width: 750px;
    height: 138px;
    overflow: auto;
    border: solid 1px var(--fog);
    background-color: var(--almost-white);
    color: var(--slate);
    font-size: 16px;
    margin: auto;
`;

const BgCheckTitleText = styled.div.attrs(() => ({
    className: "mb-3",
}))`
    font-weight: bold;
`;

const BgCheckInfoText = styled.div.attrs(() => ({
    className: "mb-2",
}))``;

const CertifyCheckBox = styled.div.attrs(() => ({
    className: "d-flex align-self-center mt-3",
}))`
    margin-left: 1.5rem;

    & div {
        display: flex;
        align-items: baseline;
    }

    & label {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 14;
        width: 720px;
    }
`;

const BackgroundCheckProceduresTitleText = "2020 Background Check Procedures";

type CertifyRosterBodyContentProps = {
    certifyRosterCheckboxVal: boolean;
    setCertifyRosterCheckboxVal: Dispatch<SetStateAction<boolean>>;
    bgCheckProceduresText: string;
};

type CertifyRosterFooterContentProps = {
    certifyRosterCheckboxVal: boolean;
    certifyRosterCallback: () => void;
};

const CertifyRosterBodyContent: React.FC<CertifyRosterBodyContentProps> = ({
    certifyRosterCheckboxVal,
    setCertifyRosterCheckboxVal,
    bgCheckProceduresText,
}) => {
    const currentDate = new Date().getTime();
    const formattedDate = Moment(currentDate).format("MM/DD/YYYY");
    const currentYear = Moment(currentDate).format("YYYY");
    const checkBoxText =
        "I certify that as of this date, " +
        formattedDate +
        ", the enclosed list is a complete record" +
        " of all Club personnel that are subject to the " +
        currentYear +
        " Background Check Procedures.";
    return (
        <BodyContent>
            <BackgroundCheckProceduresSection>
                <BgCheckTitleText>{BackgroundCheckProceduresTitleText}</BgCheckTitleText>
                <BgCheckInfoText>{ReactHtmlParser(bgCheckProceduresText || "")}</BgCheckInfoText>
            </BackgroundCheckProceduresSection>
            <CertifyCheckBox>
                <Checkbox
                    label={checkBoxText}
                    onChange={() => setCertifyRosterCheckboxVal(!certifyRosterCheckboxVal)}
                    checked={certifyRosterCheckboxVal}
                />
            </CertifyCheckBox>
        </BodyContent>
    );
};

const CertifyRosterFooterContent: React.FC<CertifyRosterFooterContentProps> = ({
    certifyRosterCheckboxVal,
    certifyRosterCallback,
}) => {
    const { closeModal } = useGlobalModal();
    return (
        <div className="d-flex">
            <div className="mr-2">
                <PrimaryButtonStyled
                    width={127}
                    height={26}
                    disabled={!certifyRosterCheckboxVal}
                    onClick={event => {
                        event.stopPropagation();
                        certifyRosterCallback();
                        closeModal();
                    }}
                >
                    Certify Roster
                </PrimaryButtonStyled>
            </div>

            <OutlinedButtonStyled
                width={142}
                height={26}
                disabled={false}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Cancel
            </OutlinedButtonStyled>
        </div>
    );
};

CertifyRosterBodyContent.displayName = "BodyContent";
CertifyRosterFooterContent.displayName = "FooterContent";

export { CertifyRosterBodyContent, CertifyRosterFooterContent };
