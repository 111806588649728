import styled from "styled-components";

type BigsBestTableProps = {
    headerTextColor?: string;
    headerTextSize?: number;
    headerTextPaddingBtm?: number;
};

export const BigsBestTable = styled.div.attrs(() => ({}))<BigsBestTableProps>`
    .bcr-table {
        border: none;
    }

    .bcr-table-row {
        border-bottom: 1px solid var(--fog) !important;
    }

    .bcr-table-body-cell {
        & > * {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bcr-table-header {
    }

    .bcr-table-header-cell {
        color: ${props =>
            props.headerTextColor ? props.headerTextColor : "var(--blue)"} !important;
        font-size: ${props => (props.headerTextSize ? props.headerTextSize : "14")}px !important;
        padding-bottom: ${props =>
            props.headerTextPaddingBtm ? props.headerTextPaddingBtm : "0"}px !important;
    }

    .ReactVirtualized__Grid.ReactVirtualized__List {
        overflow-y: scroll !important;
    }

    .bcr-table-header-cell {
        .bcr-checkbox-wrapper {
            width: 35px !important;
        }
    }
`;
