import React, { useEffect } from "react";
import styled from "styled-components";
import { BigsInput } from "../elements/BigsBcrWrapper";
import { RequiredLabel } from "./FormElements";

type DobInputProps = {
    dobValues: {
        dobYear: string;
        dobMonth: string;
        dobDay: string;
    };
    dobOnChange(dobSection: string, value: string): void;
};

const Container = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))``;

type InputContainerProps = {
    width?: number;
};

const InputContainer = styled.div.attrs(() => ({ className: "mr-1" }))<InputContainerProps>`
    width: ${props => (props.width ? props.width : 33) + "%"};
`;

const DashStyle = styled.div.attrs(() => ({ className: "mr-1" }))`
    color: var(--blue-grey);
`;

const DobInput: React.FC<DobInputProps> = ({ dobValues, dobOnChange }) => {
    useEffect(() => {
        return () => {
            dobOnChange("dobYear", "");
            dobOnChange("dobMonth", "");
            dobOnChange("dobDay", "");
        };
    }, []);
    return (
        <div style={{ width: "100%" }}>
            <RequiredLabel text="Date of Birth" required />
            <Container>
                <InputContainer width={15}>
                    <BigsInput
                        placeholder="Month (MM)"
                        maxLength="2"
                        onChange={event => {
                            dobOnChange("dobMonth", event.target.value.replace(/\D/, ""));
                        }}
                        value={dobValues.dobMonth}
                        height={36}
                    />
                </InputContainer>
                <DashStyle>/</DashStyle>
                <InputContainer width={15}>
                    <BigsInput
                        placeholder="Day (DD)"
                        maxLength="2"
                        onChange={event => {
                            dobOnChange("dobDay", event.target.value.replace(/\D/, ""));
                        }}
                        value={dobValues.dobDay}
                        height={36}
                    />
                </InputContainer>
                <DashStyle>/</DashStyle>
                <InputContainer width={15}>
                    <BigsInput
                        placeholder="Year (YYYY)"
                        maxLength="4"
                        onChange={event => {
                            dobOnChange("dobYear", event.target.value.replace(/\D/, ""));
                        }}
                        value={dobValues.dobYear}
                        height={36}
                    />
                </InputContainer>
            </Container>
        </div>
    );
};

export default DobInput;
