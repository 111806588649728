import React, { useState } from "react";
import styled from "styled-components";
import DropdownQuestion from "./DropDownQuestion";
import RadioYesNoQuestion from "./RadioYesNoQuestion";
import { useQuestionnaire } from "../../../contexts/QuestionnaireContext";
import DateInputQuestion from "./DateInputQuestion";
import { PrimaryButton } from "best-common-react";
import QuestionsDefs, { QuestionnaireTextDef } from "../QuestionDefs";
import TextInputQuestion from "./TextInputQuestion";
import { submitQuestionnaire } from "../../../api/QuestionnaireApi";
import { withRouter, RouteComponentProps } from "react-router-dom";

const QuestionsContent = styled.div.attrs(() => {})`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const Heading = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
`;

const MlbHeading = styled.div`
    margin-top: 30px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    color: var(--MLB-navy);
`;

const QuestionHeading = styled.div`
    font-family: Helvetica;
    font-weight: lighter;
    line-height: 2.5;
    font-size: 21px;
    color: var(--MLB-navy);
`;

const QuestionDescription = styled.div`
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--slate);
`;

const SubmissionCompletedTextTop = styled.div`
    margin: 1rem 125px 0px 125px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--slate);
`;
const SubmissionCompletedTextBottom = styled.div`
    margin: 20px 125px 50px 125px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--blue-grey);
`;

const SubmissionHeading = styled.div`
    font-family: Helvetica;
    font-weight: lighter;
    line-height: 2.5;
    font-size: 21px;
`;

const SubmissionSucceed = styled.div`
    color: var(--MLB-blue);
    font-size: 24px;
    text-align: center;
`;

const SubmissionFailed = styled.div`
    color: var(--MLB-red);
`;

const Footer = styled.div`
    margin: 40px;
`;

interface MatchParams {
    userId: string;
}

type MatchProps = RouteComponentProps<MatchParams>;

const Questions: React.FC<RouteComponentProps> = ({ match }: MatchProps) => {
    const { questionnaire } = useQuestionnaire();
    const [submitStatus, setSubmitStatus] = useState<any>({ submitted: false, result: "" });

    QuestionsDefs.sort((a, b) => a.order - b.order);

    const prepareAnswersPayload = () => {
        const answersPayload = {};

        for (const prop in questionnaire) {
            const question = QuestionsDefs.find(q => q.id === prop);
            if (!question) {
                continue;
            }

            switch (question.type) {
                case "DropDown":
                    answersPayload[prop] = questionnaire[prop].value;
                    break;
                case "RadioYesNo":
                    answersPayload[prop] = questionnaire[prop] === prop + "yes" ? "yes" : "no";
                    break;
                case "Text":
                    answersPayload[prop] = questionnaire[prop].trim();
                    break;
                case "Date":
                    answersPayload[prop] = questionnaire[prop];
                    break;
            }
        }
        return answersPayload;
    };

    const submitQuestionnaireHandler = async () => {
        try {
            const questionnaireAnswers = prepareAnswersPayload();
            const result = await submitQuestionnaire(match.params.userId, questionnaireAnswers);
            if (result) {
                setSubmitStatus({ ...submitStatus, submitted: true, result: result.message });
            } else {
                setSubmitStatus({ ...submitStatus, submitted: true, result: "FAILED" });
            }
        } catch (e) {
            setSubmitStatus({ ...submitStatus, submitted: true, result: "FAILED" });
        }
    };

    const renderSubmittedMessage = (result: string, language: string) => {
        switch (result) {
            case "SAVED":
                if (language !== "spanish") {
                    return (
                        <>
                            <SubmissionCompletedTextTop>
                                Thank you for completing the MLB Background Check Questionnaire.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextTop>
                                Your responses to the questionnaire will generate forms specific to
                                where you live and work.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextTop>
                                You will receive an email with detailed instructions for completing
                                and submitting the required documents.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextBottom>
                                Please note that all information collected in this process is
                                transmitted and maintained via a secure, encrypted system.
                            </SubmissionCompletedTextBottom>
                        </>
                    );
                } else {
                    return (
                        <>
                            <SubmissionCompletedTextTop>
                                Gracias por completar el Cuestionario de verificación de
                                antecedentes de MLB.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextTop>
                                Sus respuestas al cuestionario generarán formularios específicos
                                para el lugar donde vive y trabaja.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextTop>
                                Recibirá un correo electrónico con instrucciones detalladas para
                                completar y enviar los documentos requeridos.
                            </SubmissionCompletedTextTop>
                            <SubmissionCompletedTextBottom>
                                Tenga en cuenta que toda la información recopilada en este proceso
                                se transmite y mantiene a través de un sistema seguro y encriptado.
                            </SubmissionCompletedTextBottom>
                        </>
                    );
                }
            case "SAVED_UNDER_18":
                return (
                    <>
                        <SubmissionCompletedTextTop />
                        <SubmissionSucceed>Thank you for your submission.</SubmissionSucceed>
                        <SubmissionCompletedTextTop>
                            You are currently ineligible to receive and complete a consent form as
                            part part of the Background Check Procedures policy.
                        </SubmissionCompletedTextTop>
                        <SubmissionCompletedTextTop>
                            A questionnaire to determine your consent form will be sent to you after
                            your eighteenth birthday.
                        </SubmissionCompletedTextTop>
                        <SubmissionCompletedTextTop>
                            Should you have any questions, please contact your Club administrator.
                        </SubmissionCompletedTextTop>
                    </>
                );
            default:
                return <SubmissionFailed>The questionnaire submission failed.</SubmissionFailed>;
        }
    };

    return (
        <QuestionsContent>
            <Heading>
                {!submitStatus.submitted && (
                    <>
                        <MlbHeading>Major League Baseball</MlbHeading>
                        <QuestionHeading>
                            {questionnaire.language.value &&
                            questionnaire.language.value === "spanish"
                                ? QuestionnaireTextDef.title.es
                                : QuestionnaireTextDef.title.en}
                        </QuestionHeading>
                        <QuestionDescription>
                            {questionnaire.language.value &&
                            questionnaire.language.value === "spanish"
                                ? QuestionnaireTextDef.subTitle.es
                                : QuestionnaireTextDef.subTitle.en}
                        </QuestionDescription>
                    </>
                )}
                {submitStatus.submitted && (
                    <SubmissionHeading>
                        {renderSubmittedMessage(submitStatus.result, questionnaire.language?.value)}
                    </SubmissionHeading>
                )}
            </Heading>
            {!submitStatus.submitted &&
                QuestionsDefs.map(question => {
                    switch (question.type) {
                        case "DropDown":
                            return <DropdownQuestion key={question.id} questionDef={question} />;
                        case "RadioYesNo":
                            return <RadioYesNoQuestion key={question.id} questionDef={question} />;
                        case "Text":
                            return <TextInputQuestion key={question.id} questionDef={question} />;
                        case "Date":
                            return <DateInputQuestion key={question.id} questionDef={question} />;
                    }
                })}
            <Footer>
                {!submitStatus.submitted && (
                    <PrimaryButton
                        disabled={!questionnaire.submittable}
                        onClick={submitQuestionnaireHandler}
                    >
                        {questionnaire.language.value && questionnaire.language.value === "spanish"
                            ? QuestionnaireTextDef.submitButton.es
                            : QuestionnaireTextDef.submitButton.en}
                    </PrimaryButton>
                )}
            </Footer>
        </QuestionsContent>
    );
};

export default withRouter(Questions);
