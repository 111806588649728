import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { markCompleteNonSterling } from "../../../api/RosterApi";
import { useAlert } from "../../../contexts/AlertsContext";
import { getJurisdictionStatusesByPersonnel } from "../../../api/PersonnelApi";
import { RequiredLabel } from "../FormElements";
import { BestLabel, BestRadio, Icon, TextArea } from "best-common-react";
import FormUtil from "../../../util/FormUtil";
import FileBrowser from "../FileBrowser";
import { FileType } from "./GlobalModal";

type ModalProps = {
    modalHeight: string;
};
const BodyContent = styled.div.attrs(() => ({}))<ModalProps>`
    text-align: left;
    margin: 0.7rem;
    padding: 0.5rem;
    height: ${props => props.modalHeight};
    color: var(--slate);
    font-size: 14px;
`;

const CommentContent = styled.div`
    margin-top: 0.3rem;
`;

const StyledRadioButtonText = styled.label.attrs(() => ({
    className: "d-flex align-self-center mt-1 mr-3",
}))`
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
`;

const FileSection = styled.div`
    height: 105px;
    overflow-y: auto;
`;

const FieldLabel = styled(BestLabel).attrs(() => ({
    htmlFor: "",
}))`
    color: #92a5ba;
    font-size: 12px;
`;

type StyledIconProps = {
    color: string;
    iconName: string;
};

const StyledIcon = styled(Icon).attrs(() => ({ className: "align-items-center mr-3" }))<
    StyledIconProps
>`
    color: ${props => props.color};
    iconname: ${props => props.iconName};
`;

type MarkCompleteModalBodyProps = {
    personnel: any;
    markCompleteJurisdiction: string;
    setMarkCompleteJurisdiction: Function;
    markCompleteFindings: boolean;
    setMarkCompleteFindings: Function;
    markCompleteFindingsComment: string;
    setMarkCompleteFindingsComment: Function;
    files: Array<any>;
    setFiles: Dispatch<SetStateAction<any[]>>;
};

const MarkCompleteModalBody: React.FC<MarkCompleteModalBodyProps> = ({
    personnel,
    markCompleteJurisdiction,
    setMarkCompleteJurisdiction,
    markCompleteFindings,
    setMarkCompleteFindings,
    markCompleteFindingsComment,
    setMarkCompleteFindingsComment,
    files,
    setFiles,
}) => {
    const [jurisList, setJurisList] = useState([]);
    useEffect(() => {
        getJurisdictionStatusesByPersonnel([personnel]).then(response => {
            let jurisList = [];
            if (response && response.length > 0) {
                const rosterId = response[0].personnel.roster.id;
                jurisList = response[0].jurisdictionStatusList;
                jurisList = jurisList
                    .filter((juris: any) => juris.status.toUpperCase() === "BGC PENDING")
                    .map((juris: any) => {
                        return {
                            name: juris.jurisdiction,
                            displayName: FormUtil.formatJurisdiction(juris.jurisdiction),
                            status: juris.status,
                            rosterId: rosterId,
                        };
                    });
                setJurisList(jurisList);
                setMarkCompleteJurisdiction(jurisList[0].name);
            }
        });
    }, []);

    const commentHandler = (event: any) => {
        setMarkCompleteFindingsComment(event.currentTarget.value);
    };

    const onFileAdd = (fileList: any) => {
        const updatedFiles: FileType[] = [];
        fileList.forEach((file: any) => {
            const fileToAdd: FileType = {
                name: file.name,
                fileBase64String: "",
                id: file.name,
            };

            const reader = new FileReader();
            reader.onload = () => {
                fileToAdd.fileBase64String = reader.result;
                updatedFiles.push(fileToAdd);
                setFiles([...files, ...updatedFiles]);
            };
            reader.readAsDataURL(file);
        });
    };

    const onFileDelete = (fileIdToDelete: string) => {
        const fileToRemoveIndex = files.findIndex(file => file.id === fileIdToDelete);
        if (fileToRemoveIndex > -1) {
            files.splice(fileToRemoveIndex, 1);
            setFiles([...files]);
        }
    };

    return (
        <BodyContent modalHeight={markCompleteFindings === true ? "390px" : "200px"}>
            {jurisList.length > 0 && (
                <div>
                    <div>
                        <FieldLabel required>Jurisdiction</FieldLabel>
                    </div>
                    <div className="d-flex align-items-center">
                        {jurisList.map((jurisdiction: any, index) => (
                            <div
                                key={jurisdiction.name + index} // eslint-disable-line
                                className="d-flex align-items-center"
                            >
                                <div>
                                    <BestRadio
                                        onChange={() => {
                                            setMarkCompleteJurisdiction(jurisdiction.name);
                                        }}
                                        checked={markCompleteJurisdiction === jurisdiction.name}
                                        id={jurisdiction.name}
                                    />
                                </div>
                                <div>
                                    <StyledRadioButtonText htmlFor={jurisdiction.name}>
                                        {jurisdiction.displayName}
                                    </StyledRadioButtonText>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className="mt-2">
                <div>
                    <FieldLabel required>Did the BGC produce findings?</FieldLabel>
                </div>

                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <div>
                            <BestRadio
                                onChange={() => {
                                    setMarkCompleteFindings(true);
                                }}
                                checked={markCompleteFindings === true}
                                id="findingsYes"
                            />
                        </div>

                        <StyledRadioButtonText htmlFor="findingsYes">Yes</StyledRadioButtonText>
                    </div>
                    <div className="d-flex align-items-center">
                        <div>
                            <BestRadio
                                onChange={() => {
                                    setMarkCompleteFindings(false);
                                }}
                                checked={markCompleteFindings === false}
                                id="findingsNo"
                            />
                        </div>

                        <StyledRadioButtonText htmlFor="findingsNo">No</StyledRadioButtonText>
                    </div>
                </div>
            </div>
            {markCompleteFindings === true && (
                <div className="mt-2">
                    <CommentContent>
                        <RequiredLabel text="Findings Comments" required={true} />
                        <TextArea
                            disabled={false}
                            placeholder="enter comments..."
                            value={markCompleteFindingsComment}
                            onChange={commentHandler}
                            style={{
                                resize: "none",
                                fontSize: "14px",
                                color: "var(--black)",
                                fontWeight: "300",
                                borderColor: "var(--fog)",
                            }}
                        />
                    </CommentContent>
                </div>
            )}
            {markCompleteFindings === true && (
                <div className="mt-2">
                    <div>
                        <FieldLabel>Upload the BGC Document</FieldLabel>
                    </div>

                    <div>
                        <FileBrowser
                            onFileAdd={(fileList: any) => {
                                onFileAdd(fileList);
                            }}
                        />
                        {files?.length > 0 ? (
                            <FileSection>
                                {files.map((file: FileType) => {
                                    return (
                                        <div key={file.id} className="d-flex align-items-center">
                                            <StyledIcon
                                                color="var(--blue)"
                                                iconName="fa-trash-alt"
                                                onClick={() => {
                                                    onFileDelete(file.id);
                                                }}
                                            />
                                            <div style={{ fontSize: "14px" }}>{file.name}</div>
                                        </div>
                                    );
                                })}
                            </FileSection>
                        ) : null}
                    </div>
                </div>
            )}
        </BodyContent>
    );
};

const MarkCompleteModalFooter: React.FC<MarkCompleteModalBodyProps> = ({
    personnel,
    markCompleteJurisdiction,
    markCompleteFindings,
    setMarkCompleteFindings,
    markCompleteFindingsComment,
    setMarkCompleteFindingsComment,
    files,
    setFiles,
}) => {
    const [markCompleteDisabled, setMarkCompleteDisabled] = useState<boolean>(true);
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();
    useEffect(() => {
        if (markCompleteFindings === false || markCompleteFindingsComment) {
            setMarkCompleteDisabled(false);
        } else {
            setMarkCompleteDisabled(true);
        }
    }, [markCompleteFindings, markCompleteFindingsComment]);

    const markComplete = () => {
        const markCompleteNonSterlingDto = {
            jurisdiction: markCompleteJurisdiction,
            finding: markCompleteFindings,
            comment: markCompleteFindingsComment,
            files: files,
        };

        markCompleteNonSterling(personnel.roster.id, markCompleteNonSterlingDto)
            .then(result => {
                if (result && result.status === "SUCCEEDED") {
                    showAlert("Complete");
                } else {
                    showAlert("Mark Complete failed", "danger");
                }
            })
            .catch(() => {
                showAlert(`Mark Complete failed`, `danger`);
            });
    };

    return (
        <div className="d-flex">
            <PrimaryButtonStyled
                width={142}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    markComplete();
                    setMarkCompleteFindingsComment("");
                    setMarkCompleteFindings(null);
                    setFiles([]);
                    closeModal();
                }}
                disabled={markCompleteDisabled}
            >
                Mark Complete
            </PrimaryButtonStyled>
            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        setMarkCompleteFindingsComment("");
                        setMarkCompleteFindings(null);
                        setFiles([]);
                        closeModal();
                    }}
                >
                    Cancel
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

MarkCompleteModalBody.displayName = "BodyContent";
MarkCompleteModalFooter.displayName = "FooterContent";

export { MarkCompleteModalBody, MarkCompleteModalFooter };
