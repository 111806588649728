import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Moment from "moment";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { Icon } from "best-common-react";
import { getFile } from "../../../api/FileApi";
import FileDownload from "js-file-download";
import FileUtil from "../../../util/FileUtil";

const BodyContent = styled.div`
    text-align: left;
    margin: 0.7rem;
    padding: 0.5rem;
    height: 250px;
    color: var(--slate);
`;

const FileDownloadName = styled.div`
    color: var(--blue);
    margin-left: 0.3rem;
    font-size: 14px;
    cursor: pointer;
`;

const StyledIcon = styled(Icon).attrs({})`
    &&& {
        color: ${props => (props.color ? props.color : "#002c73")};
    }
`;

const UserDateSection = styled.div.attrs(() => ({
    className: "mt-1 mb-1",
}))`
    font-size: 14px;
    color: var(--slate);
`;

const CommentSection = styled.div.attrs(() => ({
    className: "mt-1 p-2",
}))`
    font-size: 14px;
    color: var(--slate);
    overflow: auto;
    height: 100px;
    background-color: var(--almost-white);
    border: 1px solid var(--fog);
`;

type ViewCommentModalBodyProps = {
    rosterStatus: any;
    personnelId: number;
};
const ViewCommentModalBody: React.FC<ViewCommentModalBodyProps> = ({
    rosterStatus,
    personnelId,
}) => {
    const downloadFileHandler = () => {
        getFile(personnelId, rosterStatus.filename).then(data => {
            FileUtil.openFileInNewTab(data);
        });
    };
    return (
        <BodyContent>
            <Row>
                <Col>
                    <UserDateSection>
                        <span className="mr-3">
                            {Moment(rosterStatus.date)
                                .format("MM/DD/YYYY - hh:mma")
                                .toString()}
                        </span>
                        <span>{rosterStatus.user}</span>
                    </UserDateSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CommentSection>{rosterStatus.comment}</CommentSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    {rosterStatus.filename ? (
                        <div
                            className="d-flex align-items-center mt-2"
                            onClick={downloadFileHandler}
                        >
                            <StyledIcon color="var(--blue)" iconName="fa-download" />
                            <FileDownloadName>{rosterStatus.filename}</FileDownloadName>
                        </div>
                    ) : null}
                </Col>
            </Row>
        </BodyContent>
    );
};

const ViewCommentModalFooter: React.FC<ViewCommentModalBodyProps> = ({ rosterStatus }) => {
    const { closeModal } = useGlobalModal();
    return (
        <div className="d-flex">
            <OutlinedButtonStyled
                width={142}
                height={26}
                onClick={event => {
                    event.stopPropagation();
                    closeModal();
                }}
            >
                Close
            </OutlinedButtonStyled>
        </div>
    );
};

ViewCommentModalBody.displayName = "BodyContent";
ViewCommentModalFooter.displayName = "FooterContent";

export { ViewCommentModalBody, ViewCommentModalFooter };
