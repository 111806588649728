import React, { useContext, createContext, useState, SetStateAction, Dispatch } from "react";
import { getAllPersonnelWithFiltering } from "../api/PersonnelApi";
import { useAlert } from "./AlertsContext";
import axios from "axios";

type AdminPersonnel = {
    personnel: any;
    parentStatus: string;
    affiliateNameLevel: string;
    firstName: string;
    lastName: string;
    middleName: string;
    nickName: string;
    title: string;
    email: string;
    affiliateId: number;
    id: number;
    isSelected: boolean;
    affiliation: {
        level: string;
        name: string;
        id: number;
        orgId: number;
        orgAbbreviation: string;
    };
    roster: {
        statuses: any[];
    };
    isSubRow: boolean;
    country: string;
    status: string;
    sentDate: string;
    subRows: any[];
    personnelProfile: {
        id: number;
        language: string;
    };
    jurisdictionStatusList: any[];
    createdDate: string;
    jurisdiction: string;
    suffix: string;
    locked: boolean;
    dtExemptState?: boolean;
    expirationDate?: string;
    tier: string;
    expanded?: boolean;
};

type AdminPersonnelContext = {
    adminPersonnel: Array<AdminPersonnel>;
    setAdminPersonnel: Dispatch<SetStateAction<AdminPersonnel[]>>;
    personnelAdvancedSearch: (
        arg1: any,
        arg2: Array<number>,
        arg3: Array<number>,
        arg4: string,
        arg5: any,
    ) => void;
    updatePersonnel: (
        updatedPersonnel: AdminPersonnel,
        currentPersonnelList: Array<AdminPersonnel>,
    ) => void;
    personnelLoaded: boolean;
};

const AdminPersonnelContext = createContext<AdminPersonnelContext>({
    adminPersonnel: [],
    setAdminPersonnel: null,
    personnelAdvancedSearch: null,
    updatePersonnel: null,
    personnelLoaded: null,
});

const AdminPersonnelContextProvider: React.FC = ({ children }) => {
    const showAlert = useAlert();
    const [adminPersonnel, setAdminPersonnel] = useState<AdminPersonnel[]>([]);
    const [personnelLoaded, setPersonnelLoaded] = useState<boolean>(false);

    const updatePersonnel = (
        updatedPersonnel: AdminPersonnel,
        currentPersonnelList: Array<AdminPersonnel>,
    ) => {
        const oldPersonnelIndex = currentPersonnelList.findIndex(
            personnel => personnel.id === updatedPersonnel.id,
        );
        if (oldPersonnelIndex >= 0) {
            const newAdminPersonnel = [...currentPersonnelList];
            newAdminPersonnel.splice(oldPersonnelIndex, 1, updatedPersonnel);
            setAdminPersonnel([...newAdminPersonnel]);
        }
    };

    const personnelAdvancedSearch = (
        searchText: any,
        orgIds: Array<number>,
        statuses: Array<number>,
        personnelType: string,
        cancelSource: any,
    ) => {
        const personnelSearch = {
            searchText: searchText,
            orgIds: orgIds,
            statuses: statuses,
            personnelType: personnelType,
        };
        setPersonnelLoaded(false);
        getAllPersonnelWithFiltering(personnelSearch, cancelSource)
            .then(response => {
                // This is for the BestTable to disable the expand/collapse
                response.forEach((entity: any) => (entity.disabled = entity.locked));
                setAdminPersonnel(response);
                setPersonnelLoaded(true);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    showAlert(`Error retrieving personnel, Please contact BIGS Systems.`, `danger`);
                }
            });
    };

    return (
        <AdminPersonnelContext.Provider
            value={{
                adminPersonnel,
                setAdminPersonnel,
                personnelAdvancedSearch,
                updatePersonnel,
                personnelLoaded,
            }}
        >
            {children}
        </AdminPersonnelContext.Provider>
    );
};

const useAdminPersonnel = (): AdminPersonnelContext => {
    const context = useContext<AdminPersonnelContext>(AdminPersonnelContext);
    if (context === undefined) {
        throw new Error(`useAdminPersonnel must be used within a AdminPersonnelContextProvider`);
    }
    return context;
};

export { AdminPersonnelContextProvider, useAdminPersonnel, AdminPersonnel };
