import React from "react";
import SubSectionHeader from "../../SubSectionHeader";
import styled from "styled-components";
import { useAuth } from "../../../../contexts/AuthContext";
import PersonnelInfoFormLayout from "./personnelinfo/PersonnelInfoFormLayout";
import UmpireGuestInfoFormLayout from "./personnelinfo/UmpireGuestInfoFormLayout";
import DisclosuresSection from "./disclosures/DisclosuresSection";
import ConsentFormSection from "./consentform/ConsentFormSection";

const PersonnelInfoContainer = styled.div.attrs(() => ({
    className: "m-2",
}))`
    &&& {
        border: solid 1px var(--fog);
    }
`;

type UploadBgcSections = {
    jurisdictionForUpload: { label: string; value: string; isCompleted: boolean };
    personnelId: number;
    personnelType: string;
    umpireNameOptions: any;
};

const UploadBgcSections: React.FC<UploadBgcSections> = ({
    jurisdictionForUpload,
    personnelId,
    personnelType,
    umpireNameOptions,
}) => {
    const { isAdmin } = useAuth();

    if (isAdmin() && personnelType == "UMPIRE") {
        return (
            <PersonnelInfoContainer>
                <div className="m-2">
                    <SubSectionHeader title="Personnel Info" />
                    <UmpireGuestInfoFormLayout
                        jurisdictionForUpload={jurisdictionForUpload?.value}
                        personnelId={personnelId}
                        umpireNameOptions={umpireNameOptions}
                    />
                    <DisclosuresSection />
                    <ConsentFormSection />
                </div>
            </PersonnelInfoContainer>
        );
    }
    return (
        <PersonnelInfoContainer>
            <div className="m-2">
                <SubSectionHeader title="Personnel Info" />
                <PersonnelInfoFormLayout
                    jurisdictionForUpload={jurisdictionForUpload?.value}
                    personnelId={personnelId}
                />
                <DisclosuresSection />
                <ConsentFormSection />
            </div>
        </PersonnelInfoContainer>
    );
};

export default UploadBgcSections;
