import React, { useEffect } from "react";
import styled from "styled-components";
import { BigsInput } from "../elements/BigsBcrWrapper";
import { RequiredLabel } from "./FormElements";

type SSNinputProps = {
    ssnValue: { ssnPartOne: string; ssnPartTwo: string; ssnPartThree: string };
    ssnOnChange(ssnSection: string, value: string): void;
};

const Container = styled.div.attrs(() => ({
    className: "d-flex align-items-center",
}))``;

type InputContainerProps = {
    width?: number;
};

const InputContainer = styled.div.attrs(() => ({ className: "mr-1" }))<InputContainerProps>`
    width: ${props => (props.width ? props.width : 33) + "%"};
`;

const DashStyle = styled.div.attrs(() => ({ className: "mr-1" }))`
    color: var(--blue-grey);
`;

const SSNinput: React.FC<SSNinputProps> = ({ ssnValue, ssnOnChange }) => {
    useEffect(() => {
        return () => {
            ssnOnChange("partOne", "");
            ssnOnChange("partTwo", "");
            ssnOnChange("partThree", "");
        };
    }, []);
    return (
        <div style={{ width: "100%" }}>
            <RequiredLabel text="SSN" required />
            <Container>
                <InputContainer width={33}>
                    <BigsInput
                        placeholder="***"
                        maxLength="3"
                        onChange={event => {
                            ssnOnChange("partOne", event.target.value.replace(/\D/, ""));
                        }}
                        value={ssnValue.ssnPartOne}
                        height={36}
                    />
                </InputContainer>
                <DashStyle>-</DashStyle>
                <InputContainer width={22}>
                    <BigsInput
                        placeholder="**"
                        maxLength="2"
                        onChange={event => {
                            ssnOnChange("partTwo", event.target.value.replace(/\D/, ""));
                        }}
                        value={ssnValue.ssnPartTwo}
                        height={36}
                    />
                </InputContainer>
                <DashStyle>-</DashStyle>
                <InputContainer width={45}>
                    <BigsInput
                        placeholder="****"
                        maxLength="4"
                        onChange={event => {
                            ssnOnChange("partThree", event.target.value.replace(/\D/, ""));
                        }}
                        value={ssnValue.ssnPartThree}
                        height={36}
                    />
                </InputContainer>
            </Container>
        </div>
    );
};

export default SSNinput;
