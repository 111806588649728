import axios from "axios";

const SERVICES_URL = process.env.SERVICES_URL;
const API_URL = `${SERVICES_URL}/file`;

export const downloadFile = (filename: string): Promise<any> =>
    axios
        .get(`${API_URL}/download/${filename}`, {
            responseType: "blob",
        })
        .then(response => response.data);

export const getFile = (personnelId: number, filename: string): Promise<any> =>
    axios
        .get(`${API_URL}/file/${personnelId}/${filename}`, {
            responseType: "blob",
        })
        .then(response => response.data);
