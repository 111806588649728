import React, { useContext, createContext, useState, SetStateAction, Dispatch } from "react";
import { getAllPersonnelWithFiltering } from "../api/PersonnelApi";
import { useAlert } from "./AlertsContext";
import axios from "axios";

type VolunteerPersonnelContext = {
    volunteerPersonnel: Array<any>;
    setVolunteerPersonnel: Dispatch<SetStateAction<any[]>>;
    personnelAdvancedSearch: (
        arg1: any,
        arg2: Array<number>,
        arg3: Array<number>,
        arg4: string,
        arg5: string,
        arg6: string,
        cancelSource: any,
    ) => void;
    updatePersonnel: (updatedPersonnel: any) => void;
    personnelLoaded: boolean;
};

const VolunteerPersonnelContext = createContext<VolunteerPersonnelContext>({
    volunteerPersonnel: [],
    setVolunteerPersonnel: null,
    personnelAdvancedSearch: null,
    updatePersonnel: null,
    personnelLoaded: null,
});

const VolunteerPersonnelContextProvider: React.FC = ({ children }) => {
    const showAlert = useAlert();
    const [volunteerPersonnel, setVolunteerPersonnel] = useState<any[]>([]);
    const [personnelLoaded, setPersonnelLoaded] = useState<boolean>(false);

    const updatePersonnel = (updatedPersonnel: any) => {
        const oldPersonnelIndex = volunteerPersonnel.findIndex(
            personnel => personnel.id === updatedPersonnel.id,
        );
        if (oldPersonnelIndex > 0) {
            const newAdminPersonnel = [...volunteerPersonnel];
            newAdminPersonnel.splice(oldPersonnelIndex, 0, updatedPersonnel);
            setVolunteerPersonnel([...newAdminPersonnel]);
        }
    };

    const personnelAdvancedSearch = (
        searchText: any,
        orgIds: Array<number>,
        statuses: Array<number>,
        personnelType: string,
        volBgcScore: string,
        event: string,
        cancelSource: any,
    ) => {
        const personnelSearch = {
            searchText: searchText,
            orgIds: orgIds,
            statuses: statuses,
            personnelType: personnelType,
            bgcScore: volBgcScore,
            event: event,
        };
        setPersonnelLoaded(false);
        getAllPersonnelWithFiltering(personnelSearch, cancelSource)
            .then(response => {
                // This is for the BestTable to disable the expand/collapse
                response.forEach((entity: any) => (entity.disabled = entity.locked));
                setVolunteerPersonnel(response);
                setPersonnelLoaded(true);
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    showAlert(`Error retrieving personnel, Please contact BIGS Systems.`, `danger`);
                }
            });
    };

    return (
        <VolunteerPersonnelContext.Provider
            value={{
                volunteerPersonnel,
                setVolunteerPersonnel,
                personnelAdvancedSearch,
                updatePersonnel,
                personnelLoaded,
            }}
        >
            {children}
        </VolunteerPersonnelContext.Provider>
    );
};

const useVolunteerPersonnel = (): VolunteerPersonnelContext => {
    const context = useContext<VolunteerPersonnelContext>(VolunteerPersonnelContext);
    if (context === undefined) {
        throw new Error(
            `useVolunteerPersonnel must be used within a VolunteerPersonnelContextProvider`,
        );
    }
    return context;
};

export { VolunteerPersonnelContextProvider, useVolunteerPersonnel };
