import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

type ProfileDisplayContext = {
    displayProfile: boolean;
    setDisplayProfile: Dispatch<SetStateAction<boolean>>;
    personnelId: string;
    setPersonnelId: Dispatch<SetStateAction<string>>;
};

const ProfileDisplayContext = createContext<ProfileDisplayContext>({
    displayProfile: null,
    setDisplayProfile: null,
    personnelId: null,
    setPersonnelId: null,
});

const ProfileDisplayContextProvider: React.FC = ({ children }) => {
    const [displayProfile, setDisplayProfile] = useState(false);
    const [personnelId, setPersonnelId] = useState(null);
    return (
        <ProfileDisplayContext.Provider
            value={{ displayProfile, setDisplayProfile, personnelId, setPersonnelId }}
        >
            {children}
        </ProfileDisplayContext.Provider>
    );
};

const useProfileDisplay = (): ProfileDisplayContext => {
    const profileDisplayContext = useContext<ProfileDisplayContext>(ProfileDisplayContext);
    if (!profileDisplayContext) {
        throw new Error(`useProfileDisplay must be used within a ProfileDisplayContextProvider`);
    }
    return profileDisplayContext;
};

export { ProfileDisplayContextProvider, useProfileDisplay };
