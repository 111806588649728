import React from "react";
import styled from "styled-components";
import { useQuestionnaire } from "../../../contexts/QuestionnaireContext";
import { QuestionDef } from "../QuestionDefs";
import { BigsInput } from "../../elements/BigsBcrWrapper";

type TextInputQuestionProps = {
    questionDef: QuestionDef;
};

const TextInputContent = styled.div`
    margin: 20px 0px 0px 0px;
    width: 350px;
`;

const TextInputQuestion: React.FC<TextInputQuestionProps> = ({ questionDef }) => {
    const { questionnaire, dispatchQuestionnaire } = useQuestionnaire();
    const textChangeHandler = (event: any) => {
        dispatchQuestionnaire({
            type: questionDef.type,
            id: questionDef.id,
            value: event.currentTarget.value,
        });
    };

    return (
        questionDef.show(questionnaire) && (
            <TextInputContent>
                <label className={questionDef.required ? "required" : ""}>
                    {questionnaire.language.value && questionnaire.language.value === "spanish"
                        ? questionDef.displayTextEs
                        : questionDef.displayTextEn}
                </label>
                <BigsInput
                    onChange={textChangeHandler}
                    value={questionnaire[questionDef.id]}
                    disabled={questionnaire.isPreExisting}
                />
            </TextInputContent>
        )
    );
};

export default TextInputQuestion;
